// src/components/Sales Target/ClosedWonChart.js

import React from 'react';
import { Box } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import LineFirst from "../LineFirst";
import { formatCurrencyValue } from '../../../utils/formatters';

const getQuarterMonths = (quarter) => {
  const startMonth = (quarter - 1) * 3;
  return Array.from({ length: 3 }, (_, i) => startMonth + i);
};

const ClosedWonChart = ({ 
  deals = { currentPeriod: [], prevPeriod: [] }, 
  selectedYear, 
  selectedQuarter,
  salesTargets,
  selectedSegment
}) => {
  const processMonthlyData = () => {
    // Ensure deals and currentPeriod exist and are arrays
    const currentPeriod = Array.isArray(deals?.currentPeriod) ? deals.currentPeriod : [];
    const prevPeriod = Array.isArray(deals?.prevPeriod) ? deals.prevPeriod : [];
    
    // Filter deals by segment
    const filterDealsBySegment = (dealsArray) => {
      if (!selectedSegment || selectedSegment === "All") return dealsArray;
      
      return dealsArray.filter(deal => {
        const locations = deal.extra_fields?.No_of_Locations__c;
        if (locations === null || locations === undefined) return false;
        
        switch (selectedSegment) {
          case "SMB":
            return locations >= 1 && locations <= 20;
          case "Mid-Market":
            return locations > 20 && locations <= 200;
          case "Enterprise":
            return locations > 200;
          default:
            return true;
        }
      });
    };

    const filteredCurrentPeriod = filterDealsBySegment(currentPeriod);
    const filteredPrevPeriod = filterDealsBySegment(prevPeriod);
    
    // Determine which months to process based on quarter selection
    const monthsToProcess = selectedQuarter 
      ? getQuarterMonths(selectedQuarter)
      : Array.from({ length: 12 }, (_, i) => i);

    // Initialize arrays for current year, previous year, and targets
    const monthlyData = monthsToProcess.map(() => 0);
    const prevYearData = monthsToProcess.map(() => 0);
    const monthlyTargets = monthsToProcess.map((monthIndex) => salesTargets[monthIndex + 1] || 0);

    // Process current period deals
    filteredCurrentPeriod.forEach(deal => {
      if (!deal || !deal.is_closed || !deal.is_won) return;
      
      try {
        const dealDate = new Date(deal.close_date);
        if (!isNaN(dealDate)) {
          const dealYear = dealDate.getFullYear();
          const dealMonth = dealDate.getMonth();
          
          // Only process if the deal is in the selected year and matches the quarter's months
          if (dealYear === selectedYear && monthsToProcess.includes(dealMonth)) {
            const monthIndex = monthsToProcess.indexOf(dealMonth);
            if (monthIndex !== -1) {
              monthlyData[monthIndex] += Number(deal.amount) || 0;
            }
          }
        }
      } catch (error) {
        console.error('Error processing current period deal:', error, deal);
      }
    });

    // Process previous period deals for year-over-year comparison
    filteredPrevPeriod.forEach(deal => {
      if (!deal || !deal.is_closed || !deal.is_won) return;
      
      try {
        const dealDate = new Date(deal.close_date);
        if (!isNaN(dealDate)) {
          const dealYear = dealDate.getFullYear();
          const dealMonth = dealDate.getMonth();
          
          // For quarterly view, we want the same quarter from the previous year
          // For yearly view, we want all months from the previous year
          const isRelevantDeal = selectedQuarter
            ? dealYear === selectedYear - 1 && monthsToProcess.includes(dealMonth)
            : dealYear === selectedYear - 1;
          
          if (isRelevantDeal) {
            // For quarterly view, map the month to the correct index in our 3-month array
            // For yearly view, use the month directly
            const monthIndex = selectedQuarter
              ? monthsToProcess.indexOf(dealMonth)
              : dealMonth;
              
            if (monthIndex !== -1) {
              prevYearData[monthIndex] += Number(deal.amount) || 0;
            }
          }
        }
      } catch (error) {
        console.error('Error processing previous period deal:', error, deal);
      }
    });

    // Calculate percentages based on the monthly targets
    const percentages = monthlyData.map((value, index) => {
      const target = monthlyTargets[index];
      if (!target) return '0% of Goal';
      return `${((value / target) * 100).toFixed(0)}% of Goal`;
    });

    return {
      data: [monthlyData, monthlyTargets, prevYearData],
      percentages
    };
  };

  const { data, percentages } = processMonthlyData();

  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  // Get the appropriate months based on quarter selection
  const chartMonths = selectedQuarter
    ? getQuarterMonths(selectedQuarter).map(monthIndex => months[monthIndex])
    : months;

  const chartData = {
    title: `Closed Won vs Target ${selectedQuarter ? `Q${selectedQuarter} ` : ''}${selectedYear}`,
    labels: [`Closed Won ${selectedYear}`, `Closed Won ${selectedYear - 1}`, "Target"],
    options: chartMonths.map(month => `${month} ${selectedYear}`),
    data: [
      data[0],  // Current year
      data[2],  // Previous year
      data[1]   // Target
    ]
  };

  const maxValue = Math.max(
    ...data[0].map(v => v || 0),  // Current year
    ...data[2].map(v => v || 0),  // Previous year
    ...data[1].map(v => v || 0)   // Target
  ) || 0;

  const customOptions = {
    yaxis: {
      labels: {
        formatter: (value) => {
          return formatCurrencyValue(value).replace(',000', 'k');
        }
      },
      min: 0,
      max: maxValue * 1.1,
      tickAmount: 4
    },
    annotations: {
      yaxis: [{
        y: 0,
        borderColor: '#999',
        borderWidth: 1,
        opacity: 0.1
      }]
    },
    grid: {
      borderColor: '#f1f1f1',
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: true,
          opacity: 0.1
        }
      }
    },
    markers: {
      size: 4
    },
    tooltip: {
      y: {
        formatter: (value) => formatCurrencyValue(value)
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, { seriesIndex, dataPointIndex }) {
        return seriesIndex === 0 ? percentages[dataPointIndex] : '';
      },
      offsetY: -10,
      style: {
        fontSize: '12px',
        colors: ['#1A5BF6']
      }
    },
    legend: {
      show: true
    }
  };

  return (
    <Card p="20px" mb={4}>
      <Box height="450px">
        <LineFirst 
          key={`chart-${JSON.stringify(salesTargets)}-${selectedYear}-${selectedQuarter}-${selectedSegment}`}
          lineData={chartData}
          customOptions={customOptions}
          hideChartTitle={true}
          selectedYear={selectedYear}
          timeFilter={selectedQuarter ? `Q${selectedQuarter}` : "all_year"}
          hideToggle={true}
          selectedSegment={selectedSegment}
        />
      </Box>
    </Card>
  );
};

export default ClosedWonChart;