import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Box, 
  Center, 
  Text,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { useAuthData } from 'auth-context';
import AccountDetail from './AccountDetail';
import GoBackHeader from '../Shared/GoBackHeader';
import useFetchAccountById from '../hooks/useFetchAccountById';
import PageHeader from "../Shared/PageHeader";
import LoadingSkeleton from '../Shared/LoadingSkeleton';
import EditAccountModal from './EditAccountModal';

const AccountDetailsPage = () => {
  const { accountId } = useParams();
  const navigate = useNavigate();
  const userData = useAuthData();
  const accountID = userData?.account_ID || '';
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  // Use the hook with both IDs
  const { data: account, loading, error } = useFetchAccountById(accountID, accountId);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleOpenEditModal = () => {
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
  };

  const noop = () => undefined;

  const renderContent = () => {
    if (loading) {
      return <LoadingSkeleton />;
    }

    if (error) {
      return (
        <Center mt={8}>
          <Alert status="error" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" height="200px">
            <AlertIcon boxSize="40px" mr={0} />
            <Text mt={4} mb={1} fontSize="lg">
              Error Loading Account
            </Text>
            <Text fontSize="sm">
              {typeof error === 'string' ? error : error.message || 'Failed to load account details. Please try again later.'}
            </Text>
          </Alert>
        </Center>
      );
    }

    if (!account) {
      return (
        <Center mt={8}>
          <Alert status="warning" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" height="200px">
            <AlertIcon boxSize="40px" mr={0} />
            <Text mt={4} mb={1} fontSize="lg">
              Account Not Found
            </Text>
            <Text fontSize="sm">
              The requested account could not be found. It may have been deleted or you may not have permission to view it.
            </Text>
          </Alert>
        </Center>
      );
    }

    return <AccountDetail account={account} />;
  };

  return (
    <Box px={4}>
      {/* Main Header with Avatar */}
      <PageHeader
        title="Accounts"
        segmentOptions={[]}
        selectedSegment=""
        onSegmentChange={noop}
        onSearchChange={noop}
        showSearch={false}
        isDetailView
      />

      {/* Go Back Header with Account Title */}
      <Box mb={2}>
        <GoBackHeader
          onGoBack={handleGoBack}
          title={account?.name || "Account Details"}
          account={account}
          onEdit={handleOpenEditModal}
        />
      </Box>

      {/* Edit Account Modal */}
      {account && (
        <EditAccountModal
          isOpen={isEditModalOpen}
          onClose={handleCloseEditModal}
          account={account}
        />
      )}

      {/* Render the content */}
      {renderContent()}
    </Box>
  );
};

export default AccountDetailsPage;