import { useFetchDeals } from './useFetchDeals';
import { useFetchDealsHistory } from './useFetchDealsHistory';
import { useDashboardUsers } from './useDashboardUsers';

export function useDashboardData(accountID, selectedYear, selectedQuarter = null) {
  // Get date ranges based on selected period
  const getDateRange = (year, quarter = null) => {
    if (!year) return { startDate: null, endDate: null };
    
    if (quarter) {
      const quarterMap = {
        1: { start: '01-01', end: '03-31' },
        2: { start: '04-01', end: '06-30' },
        3: { start: '07-01', end: '09-30' },
        4: { start: '10-01', end: '12-31' }
      };
      return {
        startDate: `${year}-${quarterMap[quarter].start}`,
        endDate: `${year}-${quarterMap[quarter].end}`
      };
    }
    return {
      startDate: `${year}-01-01`,
      endDate: `${year}-12-31`
    };
  };

  // Get current period dates
  const currentPeriod = getDateRange(selectedYear, selectedQuarter);

  // Get previous period dates (always use previous year)
  const prevYear = selectedYear - 1;
  // For year-over-year comparison, use the same quarter
  const prevQuarter = selectedQuarter;

  const prevPeriod = getDateRange(prevYear, prevQuarter);

  const { 
    deals: currentPeriodDeals = [], 
    isLoading: currentDealsLoading, 
    isError: currentDealsError 
  } = useFetchDeals(accountID, selectedYear, selectedQuarter);

  const {
    deals: prevPeriodDeals = [],
    isLoading: prevDealsLoading,
    isError: prevDealsError
  } = useFetchDeals(accountID, prevYear, prevQuarter);

  const { 
    dealsHistory: currentPeriodHistory = [], 
    pipelineAnalysis: currentPeriodPipeline = [],
    isLoading: currentHistoryLoading, 
    error: currentHistoryError 
  } = useFetchDealsHistory(accountID, selectedYear, selectedQuarter);

  const {
    dealsHistory: prevPeriodHistory = [],
    isLoading: prevHistoryLoading,
    error: prevHistoryError
  } = useFetchDealsHistory(accountID, prevYear, prevQuarter);

  const { 
    users = [], 
    isLoading: usersLoading, 
    error: usersError 
  } = useDashboardUsers(accountID);

  const isLoading = currentDealsLoading || prevDealsLoading || currentHistoryLoading || prevHistoryLoading || usersLoading;
  const error = {
    currentDeals: currentDealsError,
    prevDeals: prevDealsError,
    currentHistory: currentHistoryError,
    prevHistory: prevHistoryError,
    users: usersError
  };

  // Ensure arrays and add safety checks
  const ensureArray = (data) => Array.isArray(data) ? data : [];
  
  // Combine current and previous period data with safety checks
  const deals = {
    currentPeriod: ensureArray(currentPeriodDeals),
    prevPeriod: ensureArray(prevPeriodDeals),
    periodType: selectedQuarter ? 'quarter' : 'year'
  };

  const dealsHistory = {
    currentPeriod: ensureArray(currentPeriodHistory),
    prevPeriod: ensureArray(prevPeriodHistory),
    periodType: selectedQuarter ? 'quarter' : 'year'
  };

  return {
    deals,
    dealsHistory,
    pipelineAnalysis: ensureArray(currentPeriodPipeline),
    users: ensureArray(users),
    isLoading,
    error: Object.values(error).some(e => e) ? error : null,
    currentPeriodRange: currentPeriod,
    prevPeriodRange: prevPeriod
  };
} 