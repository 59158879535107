import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { Trash2 } from 'lucide-react';
import {
  MdEmail,
  MdUpdate,
  MdSearch,
  MdSend,
  MdMessage,
  MdTask,
} from 'react-icons/md';

const actionIcons = {
  'Email Send': MdEmail,
  'Lysto Entity Update': MdUpdate,
  'Lysto Entity Enrichment': MdSearch,
  'Send POST': MdSend,
  'Send Slack Message': MdMessage,
  'Create Linear Task': MdTask,
};

const actionColors = {
  'Email Send': 'green',
  'Lysto Entity Update': 'blue',
  'Lysto Entity Enrichment': 'purple',
  'Send POST': 'orange',
  'Send Slack Message': 'teal',
  'Create Linear Task': 'gray',
};

const ActionCard = ({ action, onDelete }) => {
  const cardBg = useColorModeValue('gray.50', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const iconColor = useColorModeValue(
    `${actionColors[action.type]}.500`,
    `${actionColors[action.type]}.300`
  );
  const textColor = useColorModeValue('gray.600', 'gray.300');

  const ActionIcon = actionIcons[action.type] || MdSend;

  const renderConfig = (config) => {
    if (action.type === 'Email Send') {
      const parts = [];

      // From account
      if (config.fromAccount === 'last_sender') {
        parts.push('From: Last Lysto Sender');
      } else {
        parts.push(`From: ${config.fromAccount}`);
      }

      // To accounts
      if (config.toAccounts === 'last_recipient') {
        parts.push('To: Last External Recipient');
      } else if (config.customRecipients) {
        parts.push(`To: ${config.customRecipients}`);
      }

      // Context
      const contextMap = {
        deal_context: 'Lysto Deal Context',
        contact_context: 'Contact Context',
        custom_context: 'Custom Context',
      };
      parts.push(`Context: ${contextMap[config.messageContext]}`);

      // Send preference
      const sendMap = {
        inbox: 'Review in Inbox',
        direct: 'Send Directly',
      };
      parts.push(sendMap[config.sendPreference]);

      return parts.join(' • ');
    }
    
    return JSON.stringify(config);
  };

  return (
    <Box
      bg={cardBg}
      borderWidth="1px"
      borderColor={borderColor}
      borderRadius="md"
      p={4}
    >
      <Flex justify="space-between" align="center">
        <HStack spacing={3}>
          <Icon
            as={ActionIcon}
            boxSize={5}
            color={iconColor}
          />
          <Box>
            <Text fontWeight="500">
              {action.type}
            </Text>
            <Text fontSize="sm" color={textColor}>
              {renderConfig(action.config)}
            </Text>
          </Box>
        </HStack>
        
        <IconButton
          icon={<Trash2 size={16} />}
          variant="ghost"
          colorScheme="red"
          size="sm"
          onClick={onDelete}
          aria-label="Delete action"
        />
      </Flex>
    </Box>
  );
};

ActionCard.propTypes = {
  action: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    config: PropTypes.object.isRequired,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ActionCard; 