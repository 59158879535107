// src/components/Shared/PaginationControls.jsx

import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  HStack,
  Text,
  IconButton,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { useSidebar } from "contexts/SidebarContext";
import { STYLE_CONSTANTS } from "components/sidebar/sidebarConstants";
import { useLocation } from "react-router-dom";

/**
 * PaginationControls Component
 * Provides Previous and Next buttons along with current page information.
 *
 * @param {Object} props - Component props
 * @param {number} props.currentPage - The current active page (1-based index)
 * @param {number} props.totalPages - Total number of pages
 * @param {Function} props.onPrevious - Function to call when Previous is clicked
 * @param {Function} props.onNext - Function to call when Next is clicked
 * @param {bool} props.disablePrevious - Whether Previous button should be disabled
 * @param {bool} props.disableNext - Whether Next button should be disabled
 * @param {Function} props.onPageSelect - Function to call when a page number is clicked
 * @returns {JSX.Element}
 */
const PaginationControls = ({
  currentPage,
  totalPages,
  onPrevious,
  onNext,
  disablePrevious,
  disableNext,
  onPageSelect,
}) => {
  const { isExpanded } = useSidebar();
  const location = useLocation();
  
  // Check if we're in a detail view by looking for an ID in the URL
  const isDetailView = /\/\d+$/.test(location.pathname);
  
  // Only hide in detail views
  if (isDetailView) {
    return null;
  }
  
  // Helper function to generate page numbers to display
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5; // Show up to 5 page numbers
    
    if (totalPages <= maxPagesToShow) {
      // If total pages is less than max, show all pages
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // Always include first page
      pageNumbers.push(1);
      
      // Calculate range around current page
      let start = Math.max(2, currentPage - 1);
      let end = Math.min(totalPages - 1, currentPage + 1);
      
      // Add ellipsis after first page if needed
      if (start > 2) {
        pageNumbers.push('...');
      }
      
      // Add pages around current page
      for (let i = start; i <= end; i++) {
        pageNumbers.push(i);
      }
      
      // Add ellipsis before last page if needed
      if (end < totalPages - 1) {
        pageNumbers.push('...');
      }
      
      // Always include last page
      pageNumbers.push(totalPages);
    }
    
    return pageNumbers;
  };

  // Calculate the left offset based on sidebar state
  const sidebarWidth = isExpanded ? STYLE_CONSTANTS.EXPANDED_WIDTH : STYLE_CONSTANTS.COLLAPSED_WIDTH;
  const leftOffset = `calc(50% + ${parseInt(sidebarWidth) / 2}px)`;

  // Determine if we should show navigation buttons
  const showNavButtons = totalPages > 3;

  return (
    <Box
      bg="white"
      p={2}
      borderRadius="md"
      boxShadow="lg"
      borderWidth="1px"
      borderColor="gray.200"
      position="fixed"
      bottom="6"
      left={leftOffset}
      transform="translateX(-50%)"
      zIndex={10}
      width={showNavButtons ? "400px" : "auto"}
      minW="fit-content"
      transition={STYLE_CONSTANTS.TRANSITION}
    >
      <HStack spacing={1} justify="space-between" align="center">
        {showNavButtons && (
          <Box width="40px">
            <IconButton
              icon={<ChevronLeftIcon boxSize={5} />}
              onClick={onPrevious}
              isDisabled={disablePrevious}
              variant="ghost"
              aria-label="Previous page"
              colorScheme="gray"
              size="md"
              height="40px"
              borderRadius="md"
            />
          </Box>
        )}

        <HStack 
          spacing={1} 
          justify="center" 
          flex={showNavButtons ? 1 : undefined}
          maxW={showNavButtons ? "280px" : undefined} 
          overflow="hidden"
        >
          {getPageNumbers().map((pageNum, index) => (
            pageNum === '...' ? (
              <Text key={`ellipsis-${index}`} color="gray.500" px={2}>
                {pageNum}
              </Text>
            ) : (
              <Button
                key={pageNum}
                onClick={() => onPageSelect(pageNum)}
                variant={currentPage === pageNum ? 'solid' : 'ghost'}
                colorScheme={currentPage === pageNum ? 'blue' : 'gray'}
                size="md"
                height="40px"
                minW="40px"
                borderRadius="md"
              >
                {pageNum}
              </Button>
            )
          ))}
        </HStack>

        {showNavButtons && (
          <Box width="40px">
            <IconButton
              icon={<ChevronRightIcon boxSize={5} />}
              onClick={onNext}
              isDisabled={disableNext}
              variant="ghost"
              aria-label="Next page"
              colorScheme="gray"
              size="md"
              height="40px"
              borderRadius="md"
            />
          </Box>
        )}
      </HStack>
    </Box>
  );
};

// Define PropTypes for type checking
PaginationControls.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  disablePrevious: PropTypes.bool,
  disableNext: PropTypes.bool,
  onPageSelect: PropTypes.func,
};

PaginationControls.defaultProps = {
  disablePrevious: false,
  disableNext: false,
  // No-op function for optional page selection handling
  onPageSelect: () => {
    // Intentionally empty - default no-op handler for optional page selection
    return undefined;
  },
};

export default PaginationControls;
