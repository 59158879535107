// src/components/Contacts/hooks/useFetchContactById.js

import { useState, useEffect } from "react";
import axios from "axios";
import { useAuthData } from "auth-context";

/**
 * Custom hook to fetch contact based on the account ID.
 * @param {string} accountID - The account ID to fetch contact for.
 * @param {string} contactID - The contact ID to fetch.
 * @returns {Object} - Contains contact data, loading state, and error state.
 */
export function useFetchContactById(accountID, contactID) {
  const [contact, setContact] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessToken } = useAuthData();

  useEffect(() => {
    const fetchContact = async () => {
      // Reset states at the start of each fetch attempt
      setLoading(true);
      setError(null);

      if (!accountID || !contactID) {
        return; // Just return without setting error, maintaining loading state
      }

      try {
        const accessToken = await getAccessToken();

        const target =
          process.env.REACT_APP_API_BASE_URL ||
          "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

        const response = await axios.get(
          `${target}/accounts/${accountID}/contacts/${contactID}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (!response.data) {
          throw new Error(`Contact with ID ${contactID} not found`);
        }

        setContact(response.data);
        setError(null);
      } catch (error) {
        console.error("Error fetching contact:", error);
        setContact(null);
        setError(error.response?.data?.message || error.message || "Failed to fetch contact details");
      } finally {
        setLoading(false);
      }
    };

    fetchContact();
  }, [accountID, contactID, getAccessToken]);

  return { data: contact, loading, error };
}

export default useFetchContactById;
