import React, { forwardRef, useImperativeHandle } from "react";
import {
  Box,
  SimpleGrid,
  Spinner,
  Text,
  useDisclosure,
  VStack,
  Heading,
} from "@chakra-ui/react";
import { useIntegrations } from "../hooks/useIntegrations";
import { IntegrationsModal } from "./IntegrationsModal";
import ConnectionCard from './ConnectionCard';
import { useAuthData } from "auth-context";

const IntegrationsSection = forwardRef((props, ref) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isLoading, error, sortedIntegrations, initiateConnection } = useIntegrations();
  const { user_ID: currentUserId } = useAuthData();

  // Expose the onOpen function to parent
  useImperativeHandle(ref, () => ({
    openModal: () => {
      onOpen();
    }
  }));

  if (isLoading) {
    return (
      <Box textAlign="center" py={10}>
        <Spinner size="lg" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box textAlign="center" py={10} color="red.500">
        <Text>Error loading integrations: {error}</Text>
      </Box>
    );
  }

  const myIntegrations = sortedIntegrations.filter(item => item.connection.user_id === currentUserId);
  const teamIntegrations = sortedIntegrations.filter(item => item.connection.user_id !== currentUserId);

  return (
    <VStack spacing={8} align="stretch">
      {myIntegrations.length > 0 && (
        <Box>
          <Heading size="md" mb={4}>My Integrations</Heading>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
            {myIntegrations.map((item) => (
              <ConnectionCard
                key={`${item.type}-${item.connection.email}`}
                connection={item.connection}
                type={item.type}
              />
            ))}
          </SimpleGrid>
        </Box>
      )}

      {teamIntegrations.length > 0 && (
        <Box>
          <Heading size="md" mb={4}>Team Integrations</Heading>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
            {teamIntegrations.map((item) => (
              <ConnectionCard
                key={`${item.type}-${item.connection.email}`}
                connection={item.connection}
                type={item.type}
              />
            ))}
          </SimpleGrid>
        </Box>
      )}

      <IntegrationsModal
        isOpen={isOpen}
        onClose={onClose}
        onInitiateConnection={initiateConnection}
      />
    </VStack>
  );
});

// Add display name to fix the linter error
IntegrationsSection.displayName = 'IntegrationsSection';

export default IntegrationsSection;
