import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Text,
  FormControl,
  FormLabel,
  Progress,
  HStack,
  Icon,
  Box,
  useToast,
  SimpleGrid,
} from "@chakra-ui/react";
import { FaEnvelope, FaCalendarAlt, FaUser, FaUsers } from "react-icons/fa";

const STEPS = [
  { id: "service", title: "Choose Service" },
  { id: "permissions", title: "Data Permissions" },
  { id: "account", title: "Google Account" },
];

const ServiceButton = ({ icon, title, isSelected, onClick, color }) => (
  <Button
    height="120px"
    width="100%"
    onClick={onClick}
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    gap={3}
    bg={isSelected ? `${color}.50` : "white"}
    border="1px solid"
    borderColor={isSelected ? `${color}.200` : "gray.200"}
    _hover={{
      bg: isSelected ? `${color}.50` : "gray.50",
      borderColor: isSelected ? `${color}.300` : "gray.300",
    }}
    transition="all 0.2s"
    rounded="lg"
  >
    <Icon
      as={icon}
      boxSize={8}
      color={isSelected ? `${color}.500` : "gray.400"}
    />
    <Text
      color={isSelected ? `${color}.700` : "gray.600"}
      fontWeight={isSelected ? "medium" : "normal"}
    >
      {title}
    </Text>
  </Button>
);

export const IntegrationsModal = ({
  isOpen,
  onClose,
  onInitiateConnection,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [integrationData, setIntegrationData] = useState({
    service: "",
    permissionType: "",
  });
  const toast = useToast();

  // Reset modal state when it closes
  const resetModal = () => {
    setCurrentStep(0);
    setIntegrationData({
      service: "",
      permissionType: "",
    });
  };

  // Handle modal close and reset
  const handleClose = () => {
    onClose();
    resetModal();
  };

  // Reset modal when it's closed (either by user or system)
  useEffect(() => {
    if (!isOpen) {
      resetModal();
    }
  }, [isOpen]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setIntegrationData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateStep = () => {
    const requiredFields = {
      service: ["service"],
      permissions: ["permissionType"],
      account: [],
    };

    const currentFields = requiredFields[STEPS[currentStep].id];
    const missingFields = currentFields.filter(
      (field) => !integrationData[field]
    );

    if (missingFields.length > 0) {
      toast({
        title: "Required Fields Missing",
        description: `Please make a selection before proceeding.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return false;
    }
    return true;
  };

  const handleNext = () => {
    if (validateStep()) {
      if (currentStep === STEPS.length - 1) {
        onInitiateConnection(
          integrationData.service,
          integrationData.permissionType
        );
        handleClose();
      } else {
        setCurrentStep((prev) => prev + 1);
      }
    }
  };

  const handleBack = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const renderServiceSelection = () => (
    <VStack spacing={6} align="stretch">
      <Text fontSize="sm" color="gray.600">
        Choose which Google service you&apos;d like to integrate with Lysto.
      </Text>

      <FormControl isRequired>
        <FormLabel mb={4}>Service</FormLabel>
        <SimpleGrid columns={2} spacing={4}>
          <ServiceButton
            icon={FaEnvelope}
            title="Gmail"
            isSelected={integrationData.service === "gmail"}
            onClick={() =>
              handleInputChange({ target: { name: "service", value: "gmail" } })
            }
            color="blue"
          />
          <ServiceButton
            icon={FaCalendarAlt}
            title="Google Calendar"
            isSelected={integrationData.service === "calendar"}
            onClick={() =>
              handleInputChange({
                target: { name: "service", value: "calendar" },
              })
            }
            color="purple"
          />
        </SimpleGrid>
      </FormControl>
    </VStack>
  );

  const renderPermissionsSelection = () => (
    <VStack spacing={6} align="stretch">
      <Text fontSize="sm" color="gray.600">
        Choose how you want to use this integration.
      </Text>

      <FormControl isRequired>
        <FormLabel mb={4}>Permission Type</FormLabel>
        <SimpleGrid columns={2} spacing={4}>
          <ServiceButton
            icon={FaUser}
            title="Personal Account"
            subtitle="Only access my own data"
            isSelected={integrationData.permissionType === "personal"}
            onClick={() =>
              handleInputChange({
                target: { name: "permissionType", value: "personal" },
              })
            }
            color="blue"
          />
          <ServiceButton
            icon={FaUsers}
            title="Shared Account"
            subtitle="Access shared team data"
            isSelected={integrationData.permissionType === "shared"}
            onClick={() =>
              handleInputChange({
                target: { name: "permissionType", value: "shared" },
              })
            }
            color="blue"
          />
        </SimpleGrid>
      </FormControl>
    </VStack>
  );

  const renderAccountSelection = () => (
    <VStack spacing={4} align="stretch">
      <Text fontSize="sm" color="gray.600" mb={2}>
        Click continue to select your Google account.
      </Text>

      <Text>
        You&apos;ll be prompted to select and authorize your Google account in
        the next step.
      </Text>
    </VStack>
  );

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return renderServiceSelection();
      case 1:
        return renderPermissionsSelection();
      case 2:
        return renderAccountSelection();
      default:
        return null;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl">
      <ModalOverlay />
      <ModalContent maxH="90vh">
        <ModalHeader p={0}>
          <Box px={6} pt={6} pb={4}>
            <Text mb={4}>{STEPS[currentStep].title}</Text>
            <Progress
              value={(currentStep + 1) * (100 / STEPS.length)}
              size="sm"
              colorScheme="blue"
              borderRadius="full"
              width="100%"
            />
          </Box>
        </ModalHeader>

        <ModalBody overflowY="auto">{renderStepContent()}</ModalBody>

        <ModalFooter>
          <HStack spacing={4}>
            <Button variant="ghost" onClick={handleClose}>
              Cancel
            </Button>
            {currentStep > 0 && <Button onClick={handleBack}>Back</Button>}
            <Button colorScheme="blue" onClick={handleNext}>
              {currentStep === STEPS.length - 1 ? "Continue" : "Next"}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
