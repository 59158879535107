import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Text,
  FormControl,
  FormLabel,
  SimpleGrid,
  Icon,
  Box,
  useToast,
} from "@chakra-ui/react";
import { FaBan, FaArchive } from "react-icons/fa";
import { useAuthData } from "auth-context";
import useIgnoreSpark from "../hooks/useIgnoreSpark";

const ServiceButton = ({ icon, title, description, isSelected, onClick, color }) => (
  <Button
    height="auto"
    minH="120px"
    width="100%"
    onClick={onClick}
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    gap={3}
    py={5}
    px={4}
    bg={isSelected ? `${color}.50` : "white"}
    border="1px solid"
    borderColor={isSelected ? `${color}.200` : "gray.200"}
    _hover={{
      bg: isSelected ? `${color}.50` : "gray.50",
      borderColor: isSelected ? `${color}.300` : "gray.300",
    }}
    transition="all 0.2s"
    rounded="lg"
    whiteSpace="normal"
  >
    <Icon as={icon} boxSize={5} color={isSelected ? `${color}.500` : "gray.400"} flexShrink={0} />
    <Box textAlign="center" width="100%">
      <Text
        color={isSelected ? `${color}.700` : "gray.700"}
        fontWeight="medium"
        fontSize="sm"
        mb={2}
      >
        {title}
      </Text>
      <Text
        color={isSelected ? `${color}.600` : "gray.500"}
        fontSize="xs"
        lineHeight="1.4"
        whiteSpace="normal"
        wordBreak="break-word"
      >
        {description}
      </Text>
    </Box>
  </Button>
);

ServiceButton.propTypes = {
  icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
};

const IgnoreActionModal = ({ isOpen, onClose, data, isLoading: isLoadingProp }) => {
  const [selectedAction, setSelectedAction] = useState("");
  const userData = useAuthData();
  const ignoreMutation = useIgnoreSpark();
  const toast = useToast();
  const isLoading = isLoadingProp || ignoreMutation.isLoading;

  const handleConfirm = async () => {
    try {
      if (!data?.crm_account_id) {
        throw new Error("No CRM Account ID found in spark data");
      }

      await ignoreMutation.mutateAsync({
        accountId: userData.account_ID,
        userId: userData.user_ID,
        crmAccountId: data.crm_account_id,
        action: selectedAction,
      });

      onClose();
    } catch (error) {
      console.error("Ignore action error:", error);
      toast({
        title: "Error",
        description: error.message || "Failed to process action. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Manage Potential Deal</ModalHeader>
        <ModalBody>
          <VStack spacing={6} align="stretch">
            <Text fontSize="sm" color="gray.600">
              Choose how to handle this potential deal in your Spark feed.
            </Text>

            <FormControl isRequired>
              <FormLabel mb={4}>Select an action</FormLabel>
              <SimpleGrid columns={2} spacing={4}>
                <ServiceButton
                  icon={FaBan}
                  title="Block"
                  description="Permanently hide this potential deal from Spark"
                  isSelected={selectedAction === "block"}
                  onClick={() => setSelectedAction("block")}
                  color="red"
                />
                <ServiceButton
                  icon={FaArchive}
                  title="Archive"
                  description="Hide temporarily. Will reappear if new activity is detected"
                  isSelected={selectedAction === "archive"}
                  onClick={() => setSelectedAction("archive")}
                  color="blue"
                />
              </SimpleGrid>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme={selectedAction === "block" ? "red" : "blue"}
            onClick={handleConfirm}
            isDisabled={!selectedAction}
            isLoading={isLoading}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

IgnoreActionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    crm_account_id: PropTypes.number.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool,
};

export default IgnoreActionModal; 