// src/views/lysto/Deals/DealsTable.jsx

import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { MdTrackChanges } from "react-icons/md";
import { FaExpandAlt } from "react-icons/fa";
import PropTypes from "prop-types";
import DataTable from "../Shared/DataTable";
import HighlightText from "../Shared/HighlightText";

// Stage color mapping
const stageColorMap = {
  "MQL": "yellow.500",
  "SQL": "green.400",
  "Contract": "purple.500",
  "Onboarding": "blue.500",
  "Retention": "teal.500",
  "Demo": "cyan.500",
  "Discovery": "orange.500",
  "Negotiation": "blue.400",
  "Nurturing": "cyan.500",
  "Closed Lost": "red.500",
  "Lead": "orange.500",
  "Marketing Qualified Lead": "yellow.500",
  "Sales Qualified Lead": "green.400",
  "Discovery - BANT": "teal.500",
  "Meeting Scheduled": "blue.400",
  "Has Proposal": "cyan.500",
  "Contract Sent": "purple.500",
  "Deal Adjudication": "pink.500",
  "Nurture": "cyan.500",
  "On Hold": "gray.500",
  "Maybe Coaching? Circle Back": "blue.300",
  "Only Investment": "pink.500",
  "Closed Won": "green.600",
  "Closed-Paid": "green.500",
  "Client (Closed-Paid)": "green.500",
  "Lost": "red.500",
  "Qualified Out": "red.400"
};

/**
 * Formats the stage name by mapping known stages or formatting unknown ones.
 */
const formatStage = (stage) => {
  const stageMap = {
    "Lead": "Lead",
    "MQL": "MQL",
    "SQL": "SQL",
    "Contract": "Contract",
    "Closed": "Closed",
    "Closed Lost": "Closed Lost",
    "Closed-Paid": "Closed-Paid",
    "Discovery": "Discovery",
    "Demo": "Demo",
  };
  return stageMap[stage] || stage?.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()) || "—";
};

import { formatDistanceToNow } from "date-fns";

const DealsTable = ({ deals, onViewDetails, onSort, sortConfig, searchQuery }) => {
  // Define the columns configuration
  const columns = [
    {
      key: "deal_name",
      label: "Name",
      width: "200px",
      sortable: true,
      isFirst: true,
    },
    {
      key: "amount",
      label: "Amount",
      width: "120px",
      sortable: true,
      format: (value) => value ? `$${parseFloat(value).toLocaleString()}` : "—",
    },
    {
      key: "stage_name",
      label: "Stage",
      width: "150px",
      sortable: true,
      hasDot: true,
      render: (row) => (
        <Box display="flex" alignItems="center" fontSize="md">
          <Box
            height="10px"
            width="10px"
            borderRadius="full"
            bg={stageColorMap[row.stage_name] || "gray.400"}
            mr={2}
          />
          <Text color="black" isTruncated maxWidth="100%">
            <HighlightText
              text={formatStage(row.stage_name)}
              query={searchQuery}
            />
          </Text>
        </Box>
      ),
    },
    {
      key: "priority",
      label: "Priority",
      width: "120px",
      sortable: true,
      render: (row) => (
        <Text color="black" isTruncated maxWidth="100%">
          <HighlightText
            text={row.priority || "—"}
            query={searchQuery}
          />
        </Text>
      ),
    },
    {
      key: "close_date",
      label: "Close Date",
      width: "150px",
      sortable: true,
      format: (value) => value ? new Date(value).toLocaleDateString(undefined, {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric',
      }) : "—",
    },
    {
      key: "pipeline_name",
      label: "Pipeline",
      width: "200px",
      sortable: true,
    },
    {
      key: "lead_source",
      label: "Source",
      width: "200px",
      sortable: true,
    },
    {
      key: "last_activity_date",
      label: "Last Activity",
      width: "200px",
      sortable: true,
      format: (value) => value ? formatDistanceToNow(new Date(value), { addSuffix: true }) : "—",
    },
  ];

  // Get color for stage indicators
  const getRowColor = (row, columnKey) => {
    if (columnKey === "stage_name") {
      return stageColorMap[row.stage_name] || "gray.400";
    }
    return "gray.400";
  };

  // Function to get row icon component
  const getRowIcon = () => MdTrackChanges;

  return (
    <DataTable
      data={deals}
      columns={columns}
      onSort={onSort}
      sortConfig={sortConfig}
      searchQuery={searchQuery}
      onRowClick={onViewDetails}
      rowIcon={getRowIcon}
      hoverIcon={FaExpandAlt}
      rowTooltip="View Deal Details"
      getRowColor={getRowColor}
    />
  );
};

DealsTable.propTypes = {
  deals: PropTypes.arrayOf(PropTypes.object).isRequired,
  onViewDetails: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  sortConfig: PropTypes.shape({
    key: PropTypes.string,
    direction: PropTypes.oneOf(["ascending", "descending"]),
  }).isRequired,
  searchQuery: PropTypes.string,
};

DealsTable.defaultProps = {
  searchQuery: "",
};

export default DealsTable;