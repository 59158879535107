import React from "react";
import PropTypes from "prop-types";
import {
  VStack,
  Box,
  Text,
  Spinner,
  Flex,
  HStack,
  Button,
  IconButton,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { ActivityItem } from "../Activities";

const ITEMS_PER_PAGE = 5;

const SimplePaginationControls = ({ currentPage, totalPages, onPrevious, onNext, onPageSelect }) => {
  // Helper function to generate page numbers to display
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;
    
    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1);
      
      let start = Math.max(2, currentPage - 1);
      let end = Math.min(totalPages - 1, currentPage + 1);
      
      if (start > 2) {
        pageNumbers.push('...');
      }
      
      for (let i = start; i <= end; i++) {
        pageNumbers.push(i);
      }
      
      if (end < totalPages - 1) {
        pageNumbers.push('...');
      }
      
      pageNumbers.push(totalPages);
    }
    
    return pageNumbers;
  };

  return (
    <Box width="100%" py={4}>
      <HStack spacing={1} justify="center" width="300px" margin="0 auto">
        <Box width="40px" textAlign="center">
          <IconButton
            icon={<ChevronLeftIcon />}
            onClick={onPrevious}
            isDisabled={currentPage === 1}
            aria-label="Previous page"
            size="sm"
          />
        </Box>
        
        <Box flex="1" textAlign="center">
          <HStack spacing={1} justify="center">
            {getPageNumbers().map((pageNum, index) => (
              pageNum === '...' ? (
                <Text key={`ellipsis-${index}`} color="gray.500" px={2} minWidth="32px">
                  {pageNum}
                </Text>
              ) : (
                <Button
                  key={pageNum}
                  onClick={() => onPageSelect(pageNum)}
                  variant={currentPage === pageNum ? 'solid' : 'ghost'}
                  colorScheme={currentPage === pageNum ? 'blue' : 'gray'}
                  size="sm"
                  minWidth="32px"
                >
                  {pageNum}
                </Button>
              )
            ))}
          </HStack>
        </Box>

        <Box width="40px" textAlign="center">
          <IconButton
            icon={<ChevronRightIcon />}
            onClick={onNext}
            isDisabled={currentPage === totalPages}
            aria-label="Next page"
            size="sm"
          />
        </Box>
      </HStack>
    </Box>
  );
};

const AccountActivity = ({ account }) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  
  // Sort activities in reverse chronological order
  const sortedActivities = React.useMemo(() => {
    if (!account?.activities) return [];
    
    return [...account.activities].sort((a, b) => {
      // First compare activity dates
      const dateA = new Date(a.activity_date);
      const dateB = new Date(b.activity_date);
      
      if (dateA.getTime() !== dateB.getTime()) {
        return dateB - dateA; // Most recent first
      }
      
      // If activity dates are the same, compare completed_date_time
      const completedA = a.completed_date_time ? new Date(a.completed_date_time) : null;
      const completedB = b.completed_date_time ? new Date(b.completed_date_time) : null;
      
      // Handle cases where completed_date_time might be null
      if (!completedA && !completedB) return 0;
      if (!completedA) return 1;
      if (!completedB) return -1;
      
      return completedB - completedA; // Most recent first
    });
  }, [account?.activities]);

  // Calculate total pages
  const totalPages = React.useMemo(() => {
    return Math.ceil(sortedActivities.length / ITEMS_PER_PAGE) || 1;
  }, [sortedActivities.length]);

  // Get current page's activities
  const currentActivities = React.useMemo(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return sortedActivities.slice(startIndex, endIndex);
  }, [sortedActivities, currentPage]);

  // Pagination handlers
  const handlePreviousPage = () => {
    setCurrentPage(prev => Math.max(1, prev - 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prev => Math.min(totalPages, prev + 1));
  };

  const handlePageSelect = (pageNum) => {
    setCurrentPage(pageNum);
  };

  if (!account) {
    return (
      <Box>
        <Text fontSize="md" fontWeight="bold" mb={4}>Account Activities</Text>
        <Flex justify="center" align="center" minH="200px">
          <Spinner size="lg" />
        </Flex>
      </Box>
    );
  }

  if (!account.activities?.length) {
    return (
      <Box>
        <Text fontSize="md" fontWeight="bold" mb={4}>Account Activities</Text>
        <Text color="gray.500" textAlign="center" py={8}>
          No activities found for this account.
        </Text>
      </Box>
    );
  }

  return (
    <Box>
      <Text fontSize="md" fontWeight="bold" mb={4}>Account Activities</Text>
      
      {/* Activities List */}
      <VStack spacing={4} align="stretch" mb={4}>
        {currentActivities.map((activity) => (
          <ActivityItem key={activity.id} activity={activity} />
        ))}
      </VStack>

      {/* Simple Pagination Controls */}
      {totalPages > 1 && (
        <SimplePaginationControls
          currentPage={currentPage}
          totalPages={totalPages}
          onPrevious={handlePreviousPage}
          onNext={handleNextPage}
          onPageSelect={handlePageSelect}
        />
      )}
    </Box>
  );
};

AccountActivity.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number,
    activities: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      activity_type: PropTypes.string,
      activity_date: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      activity_data: PropTypes.object,
    })),
  }),
};

export default AccountActivity; 