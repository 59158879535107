import React from 'react';
import {
  Box,
  VStack,
  Skeleton,
} from '@chakra-ui/react';

const LoadingSkeleton = () => (
  <VStack spacing={6} align="stretch">
    <Box bg="white" p={6} borderRadius="md" boxShadow="sm" borderWidth="1px" borderColor="gray.200">
      <VStack spacing={6} align="stretch">
        <Skeleton height="24px" width="150px" />
        <Skeleton height="60px" />
        <Skeleton height="100px" />
        <Skeleton height="150px" />
      </VStack>
    </Box>
    <Box bg="white" p={6} borderRadius="md" boxShadow="sm" borderWidth="1px" borderColor="gray.200">
      <VStack spacing={6} align="stretch">
        <Skeleton height="200px" />
      </VStack>
    </Box>
  </VStack>
);

export default LoadingSkeleton; 