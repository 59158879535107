import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  VStack,
  useToast,
  Flex,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Center,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { ReactComponent as LystoLogo } from "assets/img/lysto-logo-round.svg";
import OverviewSection from "./OverviewSection";
import EmailDetailsSection from "./EmailDetailsSection";
import MessageInput from "./MessageInput";
import AnnotationModal from "./AnnotationModal";
import DealActivity from "../Deals/DealActivity";
import { FaPaperPlane } from "react-icons/fa";

const MotionBox = motion(Box);

const LoadingOverlay = ({ status }) => {
  if (status === "sending") {
    return (
      <Center flexDirection="column" color="white">
        <MotionBox
          animate={{
            scale: [1, 1.1, 1],
            opacity: [1, 0.7, 1]
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        >
          <Box 
            as={LystoLogo} 
            width="100px" 
            height="100px" 
            mb={4}
            filter="drop-shadow(0 0 8px rgba(255, 255, 255, 0.5))"
          />
        </MotionBox>
        <Text fontSize="lg" fontWeight="medium">Sending your email...</Text>
      </Center>
    );
  }

  if (status === "success") {
    return (
      <Center flexDirection="column" color="white">
        <MotionBox
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ type: "spring", duration: 0.5 }}
        >
          <Box 
            as={LystoLogo} 
            width="100px" 
            height="100px" 
            mb={4}
            filter="drop-shadow(0 0 8px rgba(255, 255, 255, 0.5))"
          />
        </MotionBox>
        <Text fontSize="lg" fontWeight="medium">Email sent successfully!</Text>
        <Text fontSize="sm" mt={2}>Redirecting to inbox...</Text>
      </Center>
    );
  }

  if (status === "error") {
    return (
      <Center flexDirection="column" color="white">
        <MotionBox
          animate={{ 
            rotate: [-5, 5, -5, 0],
          }}
          transition={{ 
            duration: 0.5,
            ease: "easeInOut",
          }}
        >
          <Box 
            as={LystoLogo} 
            width="100px" 
            height="100px" 
            mb={4}
            opacity={0.7}
            filter="drop-shadow(0 0 8px rgba(255, 0, 0, 0.3))"
          />
        </MotionBox>
        <Text fontSize="lg" fontWeight="medium" color="red.200">Failed to send email</Text>
      </Center>
    );
  }

  return null;
};

const EmailFollowupDetail = ({
  item,
  onSendMessage,
  onAddAnnotation,
}) => {
  const toast = useToast();
  const navigate = useNavigate();

  // Initialize state with content and recipients from the item
  const [message, setMessage] = useState(item?.content || "");
  const [emailSubject, setEmailSubject] = useState(item?.subject || "");
  const [emailRecipients, setEmailRecipients] = useState(item?.email_recipients || []);
  const [isAnnotationModalOpen, setIsAnnotationModalOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);
  const [sendStatus, setSendStatus] = useState(""); // 'sending', 'success', or 'error'

  // Update state when item changes
  useEffect(() => {
    if (item) {
      setMessage(item.content || "");
      setEmailSubject(item.subject || "");
      setEmailRecipients(item.email_recipients || []);
    }
  }, [item]);

  const toggleAnnotationModal = () => {
    setIsAnnotationModalOpen((prev) => !prev);
  };

  const handleSend = async () => {
    if (!message.trim()) {
      toast({
        title: "Empty Message",
        description: "Please enter a message to send.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (emailRecipients.length === 0) {
      toast({
        title: "No Recipients",
        description: "Please add at least one recipient (To, CC, or BCC).",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsSending(true);
    setShowLoadingOverlay(true);
    setSendStatus("sending");
    
    try {
      await onSendMessage({
        subject: emailSubject,
        content: message,
        email_recipients: emailRecipients,
      });
      
      setSendStatus("success");
      
      // Wait for 1.5 seconds to show success message before redirecting
      setTimeout(() => {
        setShowLoadingOverlay(false);
        navigate("/inbox");
      }, 1500);
      
    } catch (error) {
      console.error("Error sending message:", error);
      setSendStatus("error");
      
      setTimeout(() => {
        setShowLoadingOverlay(false);
        setIsSending(false);
      }, 1500);
      
      toast({
        title: "Send Failed",
        description: error.message || "Failed to send the message.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleEmailSubjectChange = (e) => {
    setEmailSubject(e.target.value);
  };

  const handleEmailRecipientsChange = (recipients) => {
    setEmailRecipients(recipients);
  };

  // Early return if no item
  if (!item) {
    return null;
  }

  return (
    <>
      <Box
        bg="transparent"
        p={0}
        borderRadius="md"
        boxShadow="none"
        mb={4}
        height="100%"
      >
        <VStack spacing={6} align="stretch" height="100%">
          {/* Card 1: Overview and Generated Reply */}
          <Box
            bg="white"
            p={6}
            borderRadius="md"
            boxShadow="sm"
            borderWidth="1px"
            borderColor="gray.200"
          >
            <VStack spacing={6} align="stretch">
              {/* Overview Section */}
              <Box>
                <Text fontSize="md" fontWeight="semibold" mb={4}>
                  Overview
                </Text>
                <Box>
                  <OverviewSection
                    account={item.account_name}
                    taskType={
                      item.task_subtype === "email"
                        ? "Email Follow-up"
                        : item.task_subtype
                    }
                    champion={item.champion || "N/A"}
                    lastEngagementDate={item.last_activity_date}
                    dealName={item.deal_name}
                    crmAccountId={item.crm_account_id}
                  />
                </Box>
              </Box>

              {/* Generated Reply Section */}
              <Box>
                <Text fontSize="md" fontWeight="semibold" mb={4}>
                  Generated Reply
                </Text>
                <VStack spacing={6} align="stretch">
                  <EmailDetailsSection
                    emailSubject={emailSubject}
                    onSubjectChange={handleEmailSubjectChange}
                    recipients={emailRecipients}
                    onRecipientsChange={handleEmailRecipientsChange}
                    accountID={item.account_id}
                  />

                  <MessageInput
                    message={message}
                    setMessage={setMessage}
                    isSending={isSending}
                  />
                </VStack>
              </Box>

              {/* Action Buttons */}
              <Flex justify="flex-end">
                <Button
                  leftIcon={<FaPaperPlane />}
                  colorScheme="blue"
                  onClick={handleSend}
                  isLoading={isSending}
                  loadingText="Sending..."
                  isDisabled={!message.trim() || isSending}
                  size="md"
                >
                  Send
                </Button>
              </Flex>
            </VStack>
          </Box>

          {/* Card 2: Related Activities */}
          <Box
            bg="white"
            p={6}
            borderRadius="md"
            boxShadow="sm"
            borderWidth="1px"
            borderColor="gray.200"
          >
            <Box>
              <DealActivity 
                accountID={item.account_id} 
                dealID={item.deal_id}
                title="Related Activities"
              />
            </Box>
          </Box>
        </VStack>
      </Box>

      <Modal 
        isOpen={showLoadingOverlay} 
        closeOnOverlayClick={false} 
        isCentered
      >
        <ModalOverlay backdropFilter="blur(4px)" bg="blackAlpha.700" />
        <ModalContent bg="transparent" boxShadow="none">
          <ModalBody>
            <LoadingOverlay status={sendStatus} />
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Make sure AnnotationModal does not throw error and renders only when needed */}
      {onAddAnnotation && (
        <AnnotationModal
          isOpen={isAnnotationModalOpen}
          onClose={toggleAnnotationModal}
          onSubmit={onAddAnnotation}
        />
      )}
    </>
  );
};

EmailFollowupDetail.propTypes = {
  item: PropTypes.object,
  onSendMessage: PropTypes.func.isRequired,
  onAddAnnotation: PropTypes.func,
};

export default EmailFollowupDetail;