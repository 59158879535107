// /Users/kylearchie/Documents/GitHub/lysto-fe/src/views/lysto/Deals/index.jsx
import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
  Box,
  SimpleGrid,
  Text,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverHeader,
  PopoverFooter,
  Stack,
  FormControl,
  FormLabel,
  Input,
  CheckboxGroup,
  Checkbox,
  Icon,
  HStack,
  VStack,
  useColorModeValue,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  Spinner,
} from "@chakra-ui/react";
import { FiFilter, FiPlus } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useAuthData } from "auth-context";
import DealsTable from "./DealsTable";
import PaginationControls from "../Shared/PaginationControls";
import PageHeader from "../Shared/PageHeader";
import TableSkeleton from "../Shared/TableSkeleton";
import useFetchDeals from "../hooks/useFetchDeals";
import { useDebounce } from "use-debounce";
import { useToast } from "@chakra-ui/react"; // Import useToast for notifications
import { useFilterState } from "../hooks/useFilterState";
import CreateDealModal from "./CreateDealModal";
import { ChevronDown, ChevronUp } from "lucide-react";

const ITEMS_PER_PAGE = 10;

const Deals = () => {
  const userData = useAuthData();
  const accountID = userData?.account_ID || "";
  const navigate = useNavigate();
  const toast = useToast(); // Initialize toast for notifications
  const selectedBg = useColorModeValue("blue.500", "blue.300");
  const selectedTextColor = useColorModeValue("white", "gray.800");
  const unselectedBg = useColorModeValue("white", "gray.600");
  const unselectedTextColor = useColorModeValue("gray.700", "gray.200");
  const buttonHoverBg = useColorModeValue("gray.200", "gray.500");
  const { deals, isLoading, isError } = useFetchDeals(accountID);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);

  // Sorting state
  const [sortConfig, setSortConfig] = useState({ 
    key: 'last_activity_date', 
    direction: 'descending' 
  });

  // Helper function to determine if a deal is active (has activity within last two weeks)
  const isActive = (dateString) => {
    if (!dateString) return false;
    const lastActivityDate = new Date(dateString);
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
    return lastActivityDate >= twoWeeksAgo;
  };

  // Enhance deals with isActive flag
  const enhancedDeals = useMemo(() => {
    return deals.map(deal => ({
      ...deal,
      isActive: isActive(deal.last_activity_date),
    }));
  }, [deals]);

  // Filter states - Use the new useFilterState hook for all filter-related state
  const [currentStatusFilter, setCurrentStatusFilter] = useFilterState("deals-status-filter", "all");
  const [searchQuery, setSearchQuery] = useFilterState("deals-search-query", "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  // Filter reset handler
  const handleResetFilters = () => {
    setDynamicFilters({});
  };

  // Generate search suggestions based on deal names mapped to deal IDs
  const searchSuggestions = useMemo(() => {
    // Filter deals based on search query before mapping to suggestions
    return deals
      .filter(deal => 
        deal.deal_name && 
        deal.deal_name.toLowerCase().includes(debouncedSearchQuery.toLowerCase())
      )
      .map(deal => ({ 
        id: deal.id, 
        label: deal.deal_name 
      }));
  }, [deals, debouncedSearchQuery]); // Add debouncedSearchQuery as dependency

  // Handle sorting
  const handleSort = (key) => {
    setSortConfig((prevState) => {
      if (prevState.key === key) {
        // Toggle direction
        return {
          key,
          direction: prevState.direction === "ascending" ? "descending" : "ascending",
        };
      } else {
        // New sort
        return { key, direction: "ascending" };
      }
    });
  };

  // Get unique stages and sources from the deals data
  // const availableStages = useMemo(() => {
  //   const stages = new Set(deals.map(deal => deal.stage_name).filter(Boolean));
  //   return Array.from(stages).sort();
  // }, [deals]);
  
  // const availableSources = useMemo(() => {
  //   const sources = new Set(deals.map(deal => deal.lead_source).filter(Boolean));
  //   return Array.from(sources).sort();
  // }, [deals]);

  // Inside the Deals component, add the following useMemo to determine available columns
  // const computedColumns = useMemo(() => {
  //   if (!deals || deals.length === 0) return { dateColumns: [], nonDateColumns: [] };
  //   const rep = deals[0];
  //   const dateColumns = [];
  //   const nonDateColumns = [];
  //   const blacklist = [
  //     "crm account id",
  //     "user id",
  //     "user name",
  //     "user last name",
  //     "user email",
  //     "description",
  //     "company email",
  //     "company website"
  //   ];
  //   Object.keys(rep).forEach((key) => {
  //     if (["id", "account_ID", "deal_name", "name"].includes(key)) return;
  //     if (["stage_name", "lead_source", "priority", "company_city"].includes(key)) return;
  //     const normalizedKey = key.toLowerCase().replace(/[_]/g, " ").trim();
  //     if (blacklist.includes(normalizedKey)) return;
  //     if (key.toLowerCase().includes("date")) {
  //       dateColumns.push(key);
  //     } else {
  //       nonDateColumns.push(key);
  //     }
  //   });
  //   return { dateColumns, nonDateColumns };
  // }, [deals]);

  // Helper function to convert strings to Title Case
  const titleCase = (str) => {
    return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  // Add new state for additional non-date filters
  // const [additionalFilters, setAdditionalFilters] = useState({});

  // Add new state variables after the other filter states
  // const [selectedPriorities, setSelectedPriorities] = useState([]);
  // const [selectedCities, setSelectedCities] = useState([]);

  // Add new memoized values for unique priorities and cities after availableSources
  // const availablePriorities = useMemo(() => {
  //   const priorities = new Set(deals.map(deal => deal.priority).filter(Boolean));
  //   return Array.from(priorities).sort();
  // }, [deals]);
  
  // const availableCities = useMemo(() => {
  //   const cities = new Set(deals.map(deal => deal.company_city).filter(Boolean));
  //   return Array.from(cities).sort();
  // }, [deals]);

  // Add these new helper functions after the titleCase function
  const getColumnType = (deals, columnName) => {
    if (!deals || deals.length === 0) return null;
    
    // Special case for amount - always treat as number
    if (columnName === 'amount') return 'number';
    
    // Check first non-null value to determine type
    const sampleValue = deals.find(deal => deal[columnName] != null)?.[columnName];
    
    if (columnName.toLowerCase().includes('date')) return 'date';
    if (typeof sampleValue === 'number' || (typeof sampleValue === 'string' && !isNaN(sampleValue))) return 'number';
    return 'option';
  };

  const getColumnValues = (deals, columnName) => {
    // Create a frequency map of values, including null/empty values
    const frequencyMap = deals.reduce((acc, deal) => {
      const value = deal[columnName];
      if (value) {
        acc[value] = (acc[value] || 0) + 1;
      } else {
        acc["None"] = (acc["None"] || 0) + 1;
      }
      return acc;
    }, {});

    // Get all entries and their counts
    const entries = Object.entries(frequencyMap);
    
    // Get non-"None" entries
    const nonNoneEntries = entries.filter(([value]) => value !== "None");
    
    // Check conditions:
    // 1. Must have at least 2 non-"None" options
    // 2. At least one non-"None" option must appear 3 or more times
    if (nonNoneEntries.length < 2 || !nonNoneEntries.some(([, count]) => count >= 3)) {
      return [];
    }

    // Return all values with their counts, sorted by frequency
    return entries
      .filter(([value, count]) => value === "None" ? count >= 3 : true)
      .sort((a, b) => {
        // First sort by count (descending)
        const countDiff = b[1] - a[1];
        if (countDiff !== 0) return countDiff;
        
        // If counts are equal, put "None" at the end
        if (a[0] === "None") return 1;
        if (b[0] === "None") return -1;
        
        // Otherwise sort alphabetically
        return a[0].localeCompare(b[0]);
      })
      .map(([value, count]) => ({
        value,
        count,
        label: `${value} (${count})`
      }));
  };

  const getColumnRange = (deals, columnName) => {
    const values = deals.map(deal => deal[columnName]).filter(value => typeof value === 'number');
    if (values.length === 0) return null;

    // Get unique values
    const uniqueValues = new Set(values.filter(value => value !== null));
    
    // Need at least 3 different numbers to show the filter
    if (uniqueValues.size < 3) return null;

    return {
      min: Math.min(...values),
      max: Math.max(...values)
    };
  };

  // Add this new state for dynamic filters after other state declarations
  const [dynamicFilters, setDynamicFilters] = useFilterState("deals-dynamic-filters", {});

  // Update the filterableColumns useMemo to use the new logic
  const filterableColumns = useMemo(() => {
    if (!deals || deals.length === 0) return { dates: [], numbers: [], options: [] };
    
    const columns = { date: [], number: [], option: [] };
    const blacklist = [
      "crm account id",
      "user id",
      "user name",
      "user last name",
      "user email",
      "description",
      "company email",
      "company website"
    ];
    
    // Create a combined user name field if both first and last name exist
    const hasUserNames = deals.some(deal => deal.user_first_name && deal.user_last_name);
    if (hasUserNames) {
      const userNameMap = deals.reduce((acc, deal) => {
        if (deal.user_first_name && deal.user_last_name) {
          const fullName = `${deal.user_first_name} ${deal.user_last_name}`;
          acc[fullName] = (acc[fullName] || 0) + 1;
        }
        return acc;
      }, {});

      const frequentUserNames = Object.entries(userNameMap)
        .filter(([, count]) => count >= 3)
        .map(([name]) => name)
        .sort();

      if (frequentUserNames.length > 0) {
        columns.option.push({
          name: 'user_full_name',
          label: 'User Name',
          values: frequentUserNames,
          isVirtual: true // Mark this as a virtual field that combines multiple columns
        });
      }
    }

    Object.keys(deals[0]).forEach(column => {
      // Skip any column that contains 'id' (case insensitive)
      if (column.toLowerCase().includes('id')) return;
      
      // Skip user name fields since we're handling them separately
      if (['user_first_name', 'user_last_name', 'user_name', 'user_email'].includes(column)) return;
      
      if (blacklist.includes(column)) return;
      
      const type = getColumnType(deals, column);
      
      if (type === 'date') {
        const dateFrequencyMap = deals.reduce((acc, deal) => {
          const value = deal[column];
          if (value) {
            const date = new Date(value);
            const monthYear = `${date.getMonth()}-${date.getFullYear()}`;
            acc[monthYear] = (acc[monthYear] || 0) + 1;
          }
          return acc;
        }, {});

        const hasFrequentDates = Object.values(dateFrequencyMap).some(count => count >= 3);
        if (hasFrequentDates) {
          columns.date.push({
            name: column,
            label: titleCase(column.replace(/_/g, " "))
          });
        }
      } else if (type === 'number') {
        const range = getColumnRange(deals, column);
        if (range) {
          columns.number.push({
            name: column,
            label: titleCase(column.replace(/_/g, " ")),
            range
          });
        }
      } else {
        const values = getColumnValues(deals, column);
        if (values.length > 0) {
          let label = titleCase(column.replace(/_/g, " "));
          // Custom label mappings
          if (column === 'crm_account_name') {
            label = 'Account Name';
          } else if (column === 'company_city') {
            label = 'Company Location';
          }
          columns.option.push({
            name: column,
            label: label,
            values
          });
        }
      }
    });

    // Hardcode 'amount' to always show with a min/max slider if there are 3 or more values
    const amountValues = deals.map(deal => parseFloat(deal.amount)).filter(value => !isNaN(value));
    if (amountValues.length >= 3) {
      columns.number.push({
        name: 'amount',
        label: 'Amount',
        range: {
          min: Math.min(...amountValues),
          max: Math.max(...amountValues)
        }
      });
    }
    
    return columns;
  }, [deals]);

  // Update the filteredDeals useMemo to handle "None" instead of "(None)"
  const filteredDeals = useMemo(() => {
    if (!enhancedDeals) return [];
    
    let filtered = enhancedDeals;

    // Apply status filter
    if (currentStatusFilter !== "all") {
      filtered = filtered.filter((deal) => 
        currentStatusFilter === "active" ? deal.isActive : !deal.isActive
      );
    }

    // Apply search filter
    if (debouncedSearchQuery.trim()) {
      const searchLower = debouncedSearchQuery.toLowerCase();
      filtered = filtered.filter((deal) => 
        deal.deal_name?.toLowerCase().includes(searchLower) ||
        deal.company_name?.toLowerCase().includes(searchLower) ||
        deal.stage_name?.toLowerCase().includes(searchLower)
      );
    }

    // Apply dynamic filters
    Object.entries(dynamicFilters).forEach(([column, filter]) => {
      if (!filter) return;

      // Special handling for virtual user_full_name field
      if (column === 'user_full_name') {
        if (filter.values && filter.values.length > 0) {
      filtered = filtered.filter((deal) => {
            const fullName = deal.user_first_name && deal.user_last_name ? 
              `${deal.user_first_name} ${deal.user_last_name}` : '';
            return filter.values.includes(fullName);
          });
        }
        return;
      }

      const type = getColumnType(deals, column);
      
      if (type === 'date' && filter.startDate && filter.endDate) {
        const start = new Date(filter.startDate);
        const end = new Date(filter.endDate);
        filtered = filtered.filter((deal) => {
          const value = deal[column];
          return value ? (new Date(value) >= start && new Date(value) <= end) : false;
        });
      }
      
      if (type === 'number' && (filter.min !== undefined || filter.max !== undefined)) {
        filtered = filtered.filter((deal) => {
          const value = deal[column];
          if (!value) return false;
          if (filter.min !== undefined && value < filter.min) return false;
          if (filter.max !== undefined && value > filter.max) return false;
          return true;
        });
      }
      
      if (type === 'option' && filter.values && filter.values.length > 0) {
        filtered = filtered.filter((deal) => {
          const value = deal[column];
          return filter.values.includes(value || "None");
        });
      }
    });

    return filtered;
  }, [enhancedDeals, currentStatusFilter, debouncedSearchQuery, dynamicFilters, deals]);

  // Sort deals
  const sortedDeals = useMemo(() => {
    if (!sortConfig.key) return filteredDeals;

    const sorted = [...filteredDeals].sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      // Special handling for last_activity_date
      if (sortConfig.key === 'last_activity_date') {
        // Deals with no last_activity_date should be sorted last
        if (!aValue && !bValue) return 0;
        if (!aValue) return 1;
        if (!bValue) return -1;
        return new Date(bValue) - new Date(aValue); // Newest first by default
      }

      // Handle different data types
      if (typeof aValue === "string") aValue = aValue.toLowerCase();
      if (typeof bValue === "string") bValue = bValue.toLowerCase();

      if (typeof aValue === "number" && typeof bValue === "number") {
        return aValue - bValue;
      }
      if (Date.parse(aValue) && Date.parse(bValue)) {
        return new Date(aValue) - new Date(bValue);
      }
      if (typeof aValue === "string" && typeof bValue === "string") {
        return aValue.localeCompare(bValue);
      }
      return 0;
    });

    if (sortConfig.direction === "ascending") {
      sorted.reverse(); // Reverse only if ascending is requested
    }
    return sorted;
  }, [filteredDeals, sortConfig]);

  // Calculate total pages
  const totalPages = useMemo(() => {
    return Math.ceil(sortedDeals.length / ITEMS_PER_PAGE) || 1;
  }, [sortedDeals.length]);

  // Get current page's deals
  const currentDeals = useMemo(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return sortedDeals.slice(startIndex, endIndex);
  }, [currentPage, sortedDeals]);

  /**
   * Handles the navigation to the deal details page.
   * @param {Object} deal - The deal object.
   */
  const handleViewDetails = useCallback(
    (deal) => {
      navigate(`/admin/deals/${deal.id}`);
    },
    [navigate]
  );

  /**
   * Handles suggestion selection by navigating to the selected deal's detail view.
   * @param {number|string} dealId - The ID of the selected deal.
   */
  const handleSuggestionSelect = useCallback(
    (dealId) => {
      const selectedDeal = deals.find(deal => deal.id === dealId);
      if (selectedDeal) {
        navigate(`/admin/deals/${selectedDeal.id}`);
      } else {
        toast({
          title: 'Deal not found.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    },
    [navigate, deals, toast]
  );

  /**
   * Handles navigating to the previous page.
   */
  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  /**
   * Handles navigating to the next page.
   */
  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const handlePageSelect = (pageNum) => {
    setCurrentPage(pageNum);
  };

  // Reset to first page if filtered data, sorting, or search changes
  useEffect(() => {
    setCurrentPage(1);
  }, [filteredDeals, sortConfig, debouncedSearchQuery]);

  const handleStatusChange = (newStatus) => {
    setCurrentStatusFilter(newStatus);
  };
  
  const handleSearchChange = (newQuery) => {
    setSearchQuery(newQuery);
  };

  // Add this new handler
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const hasActiveFilters = useMemo(() => {
    return Object.values(dynamicFilters).some(filter => {
      if (!filter) return false;
      if (filter.startDate || filter.endDate) return true;
      if (filter.min !== undefined || filter.max !== undefined) return true;
      if (filter.values && filter.values.length > 0) return true;
      return false;
    });
  }, [dynamicFilters]);

  // Add new state for loading sections below the expandedSections state
  const [expandedSections, setExpandedSections] = useState(() => {
    const sections = {};
    if (filterableColumns.date) {
      filterableColumns.date.forEach(col => {
        sections[col.name] = false;
      });
    }
    if (filterableColumns.number) {
      filterableColumns.number.forEach(col => {
        sections[col.name] = false;
      });
    }
    if (filterableColumns.option) {
      filterableColumns.option.forEach(col => {
        sections[col.name] = false;
      });
    }
    return sections;
  });

  // NEW: State to track loading status for each filter section
  const [loadingSections, setLoadingSections] = useState({});

  // Update the toggleSection function to handle dynamic sections with loader
  const toggleSection = (sectionName) => {
    setExpandedSections(prev => {
      const newExpanded = { ...prev, [sectionName]: !prev[sectionName] };
      if (newExpanded[sectionName]) {
        // Section is being expanded
        setLoadingSections(prevLoad => ({ ...prevLoad, [sectionName]: true }));
        setTimeout(() => {
          setLoadingSections(prevLoad => ({ ...prevLoad, [sectionName]: false }));
        }, 300);
      } else {
        // If collapsing, reset loading status immediately
        setLoadingSections(prevLoad => ({ ...prevLoad, [sectionName]: false }));
      }
      return newExpanded;
    });
  };

  // Add this new handler for dynamic filters
  const handleDynamicFilterChange = (column, value) => {
    setDynamicFilters(prev => ({
      ...prev,
      [column]: value
    }));
  };

  // Move loading check before any data processing
  if (isLoading) {
    return (
      <Box px={4}>
        <TableSkeleton rowCount={10} />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box p={4} color="red.500">
        <Text>Failed to load deals. Please try again later.</Text>
      </Box>
    );
  }

  // Check for "No deals" only after loading
  if (!isLoading && (!deals || deals.length === 0)) {
    return (
      <Box p={4}>
        <Text>No deals available.</Text>
      </Box>
    );
  }

  return (
    <Box px={4}>
      <PageHeader
        title="Deals"
        segmentOptions={[
          { label: "All", value: "all" },
          { label: "Active", value: "active" },
          { label: "Inactive", value: "inactive" }
        ]}
        selectedSegment={currentStatusFilter}
        onSegmentChange={handleStatusChange}
        searchPlaceholder="Search deals..."
        onSearchChange={handleSearchChange}
        searchSuggestions={searchSuggestions}
        searchQuery={searchQuery}
        onSuggestionSelect={handleSuggestionSelect}
        filteredCount={filteredDeals.length}
        hasActiveFilters={hasActiveFilters}
        additionalControls={
          <Popover 
            placement="bottom-end" 
            isOpen={isFilterOpen}
            onClose={() => setIsFilterOpen(false)}
          >
            <PopoverTrigger>
              <Button
                leftIcon={<Icon as={FiFilter} />}
                rightIcon={<Icon as={isFilterOpen ? ChevronUp : ChevronDown} />}
                variant="outline"
                bg="white"
                borderRadius="md"
                borderColor="gray.300"
                color={useColorModeValue("gray.700", "gray.200")}
                _hover={{
                  bg: "gray.50",
                }}
                onClick={() => setIsFilterOpen(true)}
              >
                Filters {hasActiveFilters && `(${Object.values(dynamicFilters).filter(Boolean).length})`}
              </Button>
            </PopoverTrigger>
            <PopoverContent 
              width="400px" 
              maxH="calc(100vh - var(--chakra-space-48))"
              position="relative"
              boxShadow="lg"
              mt={2}
              display="flex"
              flexDirection="column"
            >
              <PopoverHeader 
                fontWeight="semibold" 
                position="sticky"
                top={0}
                bg="white"
                zIndex={1}
                borderBottomWidth="1px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text>Advanced Filters</Text>
                {isLoading && <Spinner size="sm" ml={2} />}
                <Text fontSize="sm" color="gray.500">
                  {filteredDeals.length} of {deals.length} deals
                </Text>
              </PopoverHeader>
              <PopoverBody 
                flex="1" 
                overflowY="auto"
                py={4}
              >
                <VStack spacing={4} align="stretch">
                  {/* Date Filters */}
                  {filterableColumns.date && filterableColumns.date.length > 0 && (
                    <Box borderWidth="1px" borderRadius="md" p={4} bg="gray.50">
                    <FormControl mb={4}>
                      <FormLabel fontWeight="medium">Filter Date By</FormLabel>
                        <HStack wrap="wrap" spacing={2} mb={Object.keys(dynamicFilters).some(key => filterableColumns.date.map(col => col.name).includes(key)) ? 0 : 4}>
                          {filterableColumns.date.map((column) => (
                          <Button
                              key={column.name}
                            size="sm"
                              bg={dynamicFilters[column.name] ? selectedBg : unselectedBg}
                              color={dynamicFilters[column.name] ? selectedTextColor : unselectedTextColor}
                            _hover={{
                                bg: dynamicFilters[column.name] ? selectedBg : buttonHoverBg,
                                color: dynamicFilters[column.name] ? selectedTextColor : 'inherit',
                              }}
                              borderWidth="1px"
                              borderColor="gray.300"
                              borderRadius="md"
                              onClick={() => {
                                if (dynamicFilters[column.name]) {
                                  const newFilters = { ...dynamicFilters };
                                  delete newFilters[column.name];
                                  setDynamicFilters(newFilters);
                                } else {
                                  handleDynamicFilterChange(column.name, {});
                                }
                              }}
                            >
                              {column.label}
                          </Button>
                        ))}
                      </HStack>
                    </FormControl>

                      <VStack spacing={4} align="stretch">
                        {filterableColumns.date.map((column) => (
                          dynamicFilters[column.name] && (
                            <FormControl key={column.name}>
                              <FormLabel fontWeight="medium">Select Range for {column.label}</FormLabel>
                      <Stack direction="row">
                        <Input
                          type="date"
                                  value={dynamicFilters[column.name]?.startDate || ''}
                                  onChange={(e) => handleDynamicFilterChange(column.name, {
                                    ...dynamicFilters[column.name],
                                    startDate: e.target.value
                                  })}
                          bg="white"
                        />
                        <Input
                          type="date"
                                  value={dynamicFilters[column.name]?.endDate || ''}
                                  onChange={(e) => handleDynamicFilterChange(column.name, {
                                    ...dynamicFilters[column.name],
                                    endDate: e.target.value
                                  })}
                          bg="white"
                        />
                      </Stack>
                    </FormControl>
                          )
                        ))}
                      </VStack>
                      </Box>
                    )}

                  {/* Number Filters */}
                  {filterableColumns.number && filterableColumns.number.length > 0 && (
                    filterableColumns.number.map((column) => (
                      <FormControl key={column.name}>
                    <Button
                      variant="unstyled"
                      width="100%"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                          onClick={() => toggleSection(column.name)}
                      mb={2}
                      _hover={{ bg: "gray.50" }}
                      p={2}
                      borderRadius="md"
                    >
                      <FormLabel fontWeight="medium" mb={0}>
                            {column.label}
                            {dynamicFilters[column.name] && (
                          <Text as="span" ml={2} color="gray.500" fontSize="sm">
                                ({dynamicFilters[column.name].min} - {dynamicFilters[column.name].max})
                          </Text>
                        )}
                      </FormLabel>
                      <Icon
                            as={expandedSections[column.name] ? ChevronUp : ChevronDown}
                        boxSize={5}
                      />
                    </Button>
                        {expandedSections[column.name] && (
                          loadingSections[column.name] ? (
                            <Box p={4} display="flex" justifyContent="center" alignItems="center">
                              <Spinner />
                            </Box>
                          ) : (
                            <Box p={4} borderWidth="1px" borderRadius="md" bg="gray.50">
                              <RangeSlider
                                min={column.range.min}
                                max={column.range.max}
                                step={1}
                                value={[
                                  dynamicFilters[column.name]?.min ?? column.range.min,
                                  dynamicFilters[column.name]?.max ?? column.range.max
                                ]}
                                onChange={([min, max]) => handleDynamicFilterChange(column.name, { min, max })}
                              >
                                <RangeSliderTrack>
                                  <RangeSliderFilledTrack />
                                </RangeSliderTrack>
                                <RangeSliderThumb index={0} />
                                <RangeSliderThumb index={1} />
                              </RangeSlider>
                              <HStack justify="space-between" mt={2}>
                                <Input
                                  type="number"
                                  value={dynamicFilters[column.name]?.min ?? column.range.min}
                                  onChange={(e) => handleDynamicFilterChange(column.name, {
                                    ...dynamicFilters[column.name],
                                    min: parseFloat(e.target.value) || column.range.min
                                  })}
                                  size="sm"
                                  width="70px"
                                  textAlign="center"
                                  bg="white"
                                />
                                <Input
                                  type="number"
                                  value={dynamicFilters[column.name]?.max ?? column.range.max}
                                  onChange={(e) => handleDynamicFilterChange(column.name, {
                                    ...dynamicFilters[column.name],
                                    max: parseFloat(e.target.value) || column.range.max
                                  })}
                                  size="sm"
                                  width="70px"
                                  textAlign="center"
                                  bg="white"
                                />
                              </HStack>
                            </Box>
                          )
                        )}
                  </FormControl>
                    ))
                  )}

                  {/* Option Filters */}
                  {filterableColumns.option && filterableColumns.option.length > 0 && (
                    filterableColumns.option.map((column) => (
                      <FormControl key={column.name}>
                    <Button
                      variant="unstyled"
                      width="100%"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                          onClick={() => toggleSection(column.name)}
                      mb={2}
                      _hover={{ bg: "gray.50" }}
                      p={2}
                      borderRadius="md"
                    >
                      <FormLabel fontWeight="medium" mb={0}>
                            {column.label}
                            {dynamicFilters[column.name]?.values?.length > 0 && (
                          <Text as="span" ml={2} color="gray.500" fontSize="sm">
                                ({dynamicFilters[column.name].values.length} filter{dynamicFilters[column.name].values.length === 1 ? '' : 's'})
                          </Text>
                        )}
                      </FormLabel>
                      <Icon
                            as={expandedSections[column.name] ? ChevronUp : ChevronDown}
                        boxSize={5}
                      />
                    </Button>
                        {expandedSections[column.name] && (
                          loadingSections[column.name] ? (
                            <Box p={4} display="flex" justifyContent="center" alignItems="center">
                              <Spinner />
                            </Box>
                          ) : (
                            <Box 
                              maxH="150px"
                              overflowY="auto"
                              borderWidth="1px"
                              borderColor="gray.200"
                              borderRadius="md"
                              bg="gray.50"
                              _hover={{ borderColor: "gray.300" }}
                              sx={{
                                '&::-webkit-scrollbar': {
                                  width: '8px',
                                  borderRadius: '8px',
                                  backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                  borderRadius: '8px',
                                },
                              }}
                            >
                              <CheckboxGroup 
                                value={dynamicFilters[column.name]?.values || []}
                                onChange={(values) => handleDynamicFilterChange(column.name, { values })}
                              >
                                <Stack spacing={2} p={3}>
                                  {column.values.map((item) => (
                                    <Checkbox key={item.value} value={item.value}>
                                      {item.label}
                                    </Checkbox>
                                  ))}
                                </Stack>
                              </CheckboxGroup>
                            </Box>
                          )
                        )}
                  </FormControl>
                    ))
                  )}
                </VStack>
              </PopoverBody>
              <PopoverFooter
                position="sticky"
                bottom={0}
                bg="white"
                zIndex={1}
                borderTopWidth="1px"
                py={3}
              >
                <HStack justify="flex-end" spacing={2}>
                  <Button size="sm" onClick={handleResetFilters}>Reset</Button>
                  <Button size="sm" colorScheme="blue" onClick={() => setIsFilterOpen(false)}>Apply</Button>
                </HStack>
              </PopoverFooter>
            </PopoverContent>
          </Popover>
        }
        searchRightElement={
          <Box>
            <Button
              leftIcon={<Icon as={FiPlus} />}
              colorScheme="blue"
              borderRadius="md"
              onClick={() => setIsCreateModalOpen(true)}
            >
              New Deal
            </Button>
          </Box>
        }
      />

      {/* Create Deal Modal */}
      <CreateDealModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        existingDeals={deals}
      />

      {/* Deals Table */}
      <SimpleGrid mb="20px" columns={{ sm: 1, md: 1 }} spacing={{ base: "20px", xl: "20px" }}>
        <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4} bg="white">
          {currentDeals.length === 0 ? (
            <Text textAlign="center" py={8} color="gray.500">
              No {currentStatusFilter === 'all' ? '' : currentStatusFilter} deals available.
            </Text>
          ) : (
            <DealsTable
              deals={currentDeals}
              onViewDetails={handleViewDetails}
              onSort={handleSort}
              sortConfig={sortConfig}
              searchQuery={debouncedSearchQuery}
            />
          )}
        </Box>
      </SimpleGrid>

      {/* Pagination Controls - Only show when there are deals */}
      {currentDeals.length > 0 && (
        <PaginationControls
          currentPage={currentPage}
          totalPages={totalPages}
          onPrevious={handlePreviousPage}
          onNext={handleNextPage}
          onPageSelect={handlePageSelect}
        />
      )}
    </Box>
  );
};

export default Deals;