// /Users/kylearchie/Documents/GitHub/lysto-fe/src/views/lysto/Contacts/index.jsx
import React, { useCallback, useState, useMemo } from "react";
import {
  Box,
  SimpleGrid,
  Text,
  Button,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverHeader,
  PopoverFooter,
  Stack,
  FormControl,
  FormLabel,
  Input,
  CheckboxGroup,
  Checkbox,
  HStack,
  VStack,
  useColorModeValue,
  Spinner,
} from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import { useAuthData } from "auth-context";
import { FiPlus, FiFilter } from "react-icons/fi";
import { ChevronDown, ChevronUp } from "lucide-react";
import ContactsTable from "./ContactsTable";
import PaginationControls from "../Shared/PaginationControls";
import PageHeader from "../Shared/PageHeader";
import TableSkeleton from "../Shared/TableSkeleton";
import useFetchContacts from "./hooks/useFetchContacts";
import { useDebounce } from 'use-debounce';
import { useFilterState } from "../hooks/useFilterState";
import CreateContactModal from "./CreateContactModal";

const ITEMS_PER_PAGE = 10;

const Contacts = () => {
  // Helper functions first
  const isActive = (dateString) => {
    if (!dateString) return false;
    const lastActivityDate = new Date(dateString);
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
    return lastActivityDate >= twoWeeksAgo;
  };

  const getColumnType = (contacts, columnName) => {
    if (!contacts || contacts.length === 0) return null;
    
    if (columnName === 'amount') return 'number';
    
    const sampleValue = contacts.find(contact => contact[columnName] != null)?.[columnName];
    
    if (columnName.toLowerCase().includes('date')) return 'date';
    if (typeof sampleValue === 'number' || (typeof sampleValue === 'string' && !isNaN(sampleValue))) return 'number';
    return 'option';
  };

  const getColumnValues = (contacts, columnName) => {
    const frequencyMap = contacts.reduce((acc, contact) => {
      const value = contact[columnName];
      if (value) {
        acc[value] = (acc[value] || 0) + 1;
      } else {
        acc["None"] = (acc["None"] || 0) + 1;
      }
      return acc;
    }, {});

    const entries = Object.entries(frequencyMap);
    const nonNoneEntries = entries.filter(([value]) => value !== "None");
    
    if (nonNoneEntries.length < 2 || !nonNoneEntries.some(([, count]) => count >= 3)) {
      return [];
    }

    return entries
      .filter(([value, count]) => value === "None" ? count >= 3 : true)
      .sort((a, b) => {
        const countDiff = b[1] - a[1];
        if (countDiff !== 0) return countDiff;
        if (a[0] === "None") return 1;
        if (b[0] === "None") return -1;
        return a[0].localeCompare(b[0]);
      })
      .map(([value, count]) => ({
        value,
        count,
        label: `${value} (${count})`
      }));
  };

  const getColumnRange = (contacts, columnName) => {
    const values = contacts.map(contact => contact[columnName]).filter(value => typeof value === 'number');
    if (values.length === 0) return null;

    const uniqueValues = new Set(values);
    if (uniqueValues.size < 3) return null;

    return {
      min: Math.min(...values),
      max: Math.max(...values)
    };
  };

  const titleCase = (str) => {
    return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  // Then state and other hooks
  const userData = useAuthData();
  const accountID = userData?.account_ID || "";
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({ 
    key: 'last_activity_date', 
    direction: 'descending' 
  });
  const [currentStatusFilter, setCurrentStatusFilter] = useFilterState("contacts-status-filter", "all");
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery] = useDebounce(searchQuery, 800);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [dynamicFilters, setDynamicFilters] = useFilterState("contacts-dynamic-filters", {});
  const [expandedSections, setExpandedSections] = useState({});
  const [loadingSections, setLoadingSections] = useState({});

  const selectedBg = useColorModeValue("blue.500", "blue.300");
  const selectedTextColor = useColorModeValue("white", "gray.800");
  const unselectedBg = useColorModeValue("white", "gray.600");
  const unselectedTextColor = useColorModeValue("gray.700", "gray.200");
  const buttonHoverBg = useColorModeValue("gray.200", "gray.500");

  const { contacts, isLoading, isError, totalCount } = useFetchContacts(
    accountID,
    debouncedSearchQuery,
    "Lead",
    currentStatusFilter,
    currentPage
  );

  const enhancedContacts = useMemo(() => {
    if (!contacts) return [];
    return contacts.map(contact => ({
      ...contact,
      isActive: contact.isActive ?? isActive(contact.last_activity_date),
    }));
  }, [contacts]);

  const searchSuggestions = useMemo(() => {
    if (!enhancedContacts) return [];
    return enhancedContacts
      .filter(contact => 
        contact.name && 
        contact.name.toLowerCase().includes(debouncedSearchQuery.toLowerCase())
      )
      .map(contact => ({
        id: contact.id,
        label: contact.name
      }));
  }, [enhancedContacts, debouncedSearchQuery]);

  const filterableColumns = useMemo(() => {
    if (!contacts || contacts.length === 0) return { dates: [], numbers: [], options: [] };
    
    const columns = { date: [], number: [], option: [] };
    const blacklist = [
      "crm account id",
      "user id",
      "user name",
      "user last name",
      "user email",
      "description",
      "company email",
      "company website"
    ];
    
    Object.keys(contacts[0]).forEach(column => {
      if (column.toLowerCase().includes('id')) return;
      if (blacklist.includes(column.toLowerCase())) return;
      
      const type = getColumnType(contacts, column);
      
      if (type === 'date') {
        const dateFrequencyMap = contacts.reduce((acc, contact) => {
          const value = contact[column];
          if (value) {
            const date = new Date(value);
            const monthYear = `${date.getMonth()}-${date.getFullYear()}`;
            acc[monthYear] = (acc[monthYear] || 0) + 1;
          }
          return acc;
        }, {});

        const hasFrequentDates = Object.values(dateFrequencyMap).some(count => count >= 3);
        if (hasFrequentDates) {
          columns.date.push({
            name: column,
            label: titleCase(column.replace(/_/g, " "))
          });
        }
      } else if (type === 'number') {
        const range = getColumnRange(contacts, column);
        if (range) {
          columns.number.push({
            name: column,
            label: titleCase(column.replace(/_/g, " ")),
            range
          });
        }
      } else {
        const values = getColumnValues(contacts, column);
        if (values.length > 0) {
          columns.option.push({
            name: column,
            label: titleCase(column.replace(/_/g, " ")),
            values
          });
        }
      }
    });
    
    return columns;
  }, [contacts]);

  const handleDynamicFilterChange = (column, value) => {
    setDynamicFilters(prev => ({
      ...prev,
      [column]: value
    }));
  };

  const handleResetFilters = () => {
    setDynamicFilters({});
  };

  const toggleSection = (sectionName) => {
    setExpandedSections(prev => {
      const newExpanded = { ...prev, [sectionName]: !prev[sectionName] };
      if (newExpanded[sectionName]) {
        setLoadingSections(prevLoad => ({ ...prevLoad, [sectionName]: true }));
        setTimeout(() => {
          setLoadingSections(prevLoad => ({ ...prevLoad, [sectionName]: false }));
        }, 300);
      } else {
        setLoadingSections(prevLoad => ({ ...prevLoad, [sectionName]: false }));
      }
      return newExpanded;
    });
  };

  const filteredContacts = useMemo(() => {
    if (!enhancedContacts) return [];
    
    let filtered = enhancedContacts;

    if (currentStatusFilter !== "all") {
      filtered = filtered.filter((contact) => 
        currentStatusFilter === "active" ? contact.isActive : !contact.isActive
      );
    }

    if (debouncedSearchQuery.trim()) {
      const searchLower = debouncedSearchQuery.toLowerCase();
      filtered = filtered.filter((contact) => 
        contact.name?.toLowerCase().includes(searchLower) ||
        contact.email?.toLowerCase().includes(searchLower)
      );
    }

    Object.entries(dynamicFilters).forEach(([column, filter]) => {
      if (!filter) return;

      const type = getColumnType(contacts, column);
      
      if (type === 'date' && filter.startDate && filter.endDate) {
        const start = new Date(filter.startDate);
        const end = new Date(filter.endDate);
        filtered = filtered.filter((contact) => {
          const value = contact[column];
          return value ? (new Date(value) >= start && new Date(value) <= end) : false;
        });
      }
      
      if (type === 'number' && (filter.min !== undefined || filter.max !== undefined)) {
        filtered = filtered.filter((contact) => {
          const value = contact[column];
          if (!value) return false;
          if (filter.min !== undefined && value < filter.min) return false;
          if (filter.max !== undefined && value > filter.max) return false;
          return true;
        });
      }
      
      if (type === 'option' && filter.values && filter.values.length > 0) {
        filtered = filtered.filter((contact) => {
          const value = contact[column];
          return filter.values.includes(value || "None");
        });
      }
    });

    return filtered;
  }, [enhancedContacts, currentStatusFilter, debouncedSearchQuery, dynamicFilters, contacts]);

  const hasActiveFilters = useMemo(() => {
    return Object.values(dynamicFilters).some(filter => {
      if (!filter) return false;
      if (filter.startDate || filter.endDate) return true;
      if (filter.min !== undefined || filter.max !== undefined) return true;
      if (filter.values && filter.values.length > 0) return true;
      return false;
    });
  }, [dynamicFilters]);

  const sortedContacts = useMemo(() => {
    if (!filteredContacts) return [];
    if (!sortConfig.key) return filteredContacts;

    const sorted = [...filteredContacts].sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      if (sortConfig.key === 'last_activity_date') {
        if (!aValue && !bValue) return 0;
        if (!aValue) return 1;
        if (!bValue) return -1;
        return new Date(bValue) - new Date(aValue);
      }

      if (typeof aValue === 'string') aValue = aValue.toLowerCase();
      if (typeof bValue === 'string') bValue = bValue.toLowerCase();

      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return aValue - bValue;
      }
      if (Date.parse(aValue) && Date.parse(bValue)) {
        return new Date(aValue) - new Date(bValue);
      }
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return aValue.localeCompare(bValue);
      }
      return 0;
    });

    if (sortConfig.direction === 'ascending') {
      sorted.reverse();
    }
    return sorted;
  }, [filteredContacts, sortConfig]);

  const totalPages = useMemo(() => {
    return Math.ceil(totalCount / ITEMS_PER_PAGE) || 1;
  }, [totalCount]);

  const handleViewDetails = useCallback((contact) => {
    navigate(`/admin/contacts/${contact.id}`);
  }, [navigate]);

  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handlePageSelect = (pageNum) => {
    setCurrentPage(pageNum);
  };

  const handleStatusChange = useCallback((newStatus) => {
    setCurrentStatusFilter(newStatus);
    setCurrentPage(1);
  }, []);

  const handleSearchChange = useCallback((newQuery) => {
    setSearchQuery(newQuery);
    setCurrentPage(1);
  }, []);

  const handleSuggestionSelect = useCallback((itemId) => {
    const selectedContact = contacts?.find(contact => contact.id === itemId);
    if (selectedContact) {
      navigate(`/admin/contacts/${selectedContact.id}`);
    }
  }, [contacts, navigate]);

  const handleSort = useCallback((key) => {
    setSortConfig((prevState) => {
      if (prevState.key === key) {
        return {
          key,
          direction: prevState.direction === 'ascending' ? 'descending' : 'ascending',
        };
      } else {
        return { key, direction: 'ascending' };
      }
    });
  }, []);

  const handleCreateContact = () => {
    setIsCreateModalOpen(true);
  };

  const segmentOptions = [
    { label: "All", value: "all" },
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" }
  ];

  return (
    <Box px={4}>
      <PageHeader
        title="Contacts"
        segmentOptions={segmentOptions}
        selectedSegment={currentStatusFilter}
        onSegmentChange={handleStatusChange}
        searchPlaceholder="Search contacts..."
        onSearchChange={handleSearchChange}
        searchSuggestions={searchSuggestions}
        searchQuery={searchQuery}
        onSuggestionSelect={handleSuggestionSelect}
        additionalControls={
          <Popover 
            placement="bottom-end" 
            isOpen={isFilterOpen}
            onClose={() => setIsFilterOpen(false)}
          >
            <PopoverTrigger>
              <Button
                leftIcon={<Icon as={FiFilter} />}
                rightIcon={<Icon as={isFilterOpen ? ChevronUp : ChevronDown} />}
                variant="outline"
                bg="white"
                borderRadius="md"
                borderColor="gray.300"
                color={useColorModeValue("gray.700", "gray.200")}
                _hover={{
                  bg: "gray.50",
                }}
                onClick={() => setIsFilterOpen(true)}
              >
                Filters {hasActiveFilters && `(${Object.values(dynamicFilters).filter(Boolean).length})`}
              </Button>
            </PopoverTrigger>
            <PopoverContent 
              width="400px" 
              maxH="calc(100vh - var(--chakra-space-48))"
              position="relative"
              boxShadow="lg"
              mt={2}
              display="flex"
              flexDirection="column"
            >
              <PopoverHeader 
                fontWeight="semibold" 
                position="sticky"
                top={0}
                bg="white"
                zIndex={1}
                borderBottomWidth="1px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text>Advanced Filters</Text>
                {isLoading && <Spinner size="sm" ml={2} />}
                <Text fontSize="sm" color="gray.500">
                  {filteredContacts.length} of {contacts.length} contacts
                </Text>
              </PopoverHeader>
              <PopoverBody 
                flex="1" 
                overflowY="auto"
                py={4}
              >
                <VStack spacing={4} align="stretch">
                  {filterableColumns.date && filterableColumns.date.length > 0 && (
                    <Box borderWidth="1px" borderRadius="md" p={4} bg="gray.50">
                      <FormControl mb={4}>
                        <FormLabel fontWeight="medium">Filter Date By</FormLabel>
                        <HStack wrap="wrap" spacing={2}>
                          {filterableColumns.date.map((column) => (
                            <Button
                              key={column.name}
                              size="sm"
                              bg={dynamicFilters[column.name] ? selectedBg : unselectedBg}
                              color={dynamicFilters[column.name] ? selectedTextColor : unselectedTextColor}
                              _hover={{
                                bg: dynamicFilters[column.name] ? selectedBg : buttonHoverBg,
                                color: dynamicFilters[column.name] ? selectedTextColor : 'inherit',
                              }}
                              borderWidth="1px"
                              borderColor="gray.300"
                              borderRadius="md"
                              onClick={() => {
                                if (dynamicFilters[column.name]) {
                                  const newFilters = { ...dynamicFilters };
                                  delete newFilters[column.name];
                                  setDynamicFilters(newFilters);
                                } else {
                                  handleDynamicFilterChange(column.name, {});
                                }
                              }}
                            >
                              {column.label}
                            </Button>
                          ))}
                        </HStack>
                      </FormControl>

                      <VStack spacing={4} align="stretch">
                        {filterableColumns.date.map((column) => (
                          dynamicFilters[column.name] && (
                            <FormControl key={column.name}>
                              <FormLabel fontWeight="medium">Select Range for {column.label}</FormLabel>
                              <Stack direction="row">
                                <Input
                                  type="date"
                                  value={dynamicFilters[column.name]?.startDate || ''}
                                  onChange={(e) => handleDynamicFilterChange(column.name, {
                                    ...dynamicFilters[column.name],
                                    startDate: e.target.value
                                  })}
                                  bg="white"
                                />
                                <Input
                                  type="date"
                                  value={dynamicFilters[column.name]?.endDate || ''}
                                  onChange={(e) => handleDynamicFilterChange(column.name, {
                                    ...dynamicFilters[column.name],
                                    endDate: e.target.value
                                  })}
                                  bg="white"
                                />
                              </Stack>
                            </FormControl>
                          )
                        ))}
                      </VStack>
                    </Box>
                  )}

                  {filterableColumns.option && filterableColumns.option.length > 0 && (
                    filterableColumns.option.map((column) => (
                      <FormControl key={column.name}>
                        <Button
                          variant="unstyled"
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          onClick={() => toggleSection(column.name)}
                          mb={2}
                          _hover={{ bg: "gray.50" }}
                          p={2}
                          borderRadius="md"
                        >
                          <FormLabel fontWeight="medium" mb={0}>
                            {column.label}
                            {dynamicFilters[column.name]?.values?.length > 0 && (
                              <Text as="span" ml={2} color="gray.500" fontSize="sm">
                                ({dynamicFilters[column.name].values.length} filter{dynamicFilters[column.name].values.length === 1 ? '' : 's'})
                              </Text>
                            )}
                          </FormLabel>
                          <Icon
                            as={expandedSections[column.name] ? ChevronUp : ChevronDown}
                            boxSize={5}
                          />
                        </Button>
                        {expandedSections[column.name] && (
                          loadingSections[column.name] ? (
                            <Box p={4} display="flex" justifyContent="center" alignItems="center">
                              <Spinner />
                            </Box>
                          ) : (
                            <Box 
                              maxH="150px"
                              overflowY="auto"
                              borderWidth="1px"
                              borderColor="gray.200"
                              borderRadius="md"
                              bg="gray.50"
                              _hover={{ borderColor: "gray.300" }}
                              sx={{
                                '&::-webkit-scrollbar': {
                                  width: '8px',
                                  borderRadius: '8px',
                                  backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                  borderRadius: '8px',
                                },
                              }}
                            >
                              <CheckboxGroup 
                                value={dynamicFilters[column.name]?.values || []}
                                onChange={(values) => handleDynamicFilterChange(column.name, { values })}
                              >
                                <Stack spacing={2} p={3}>
                                  {column.values.map((item) => (
                                    <Checkbox key={item.value} value={item.value}>
                                      {item.label}
                                    </Checkbox>
                                  ))}
                                </Stack>
                              </CheckboxGroup>
                            </Box>
                          )
                        )}
                      </FormControl>
                    ))
                  )}
                </VStack>
              </PopoverBody>
              <PopoverFooter
                position="sticky"
                bottom={0}
                bg="white"
                zIndex={1}
                borderTopWidth="1px"
                py={3}
              >
                <HStack justify="flex-end" spacing={2}>
                  <Button size="sm" onClick={handleResetFilters}>Reset</Button>
                  <Button size="sm" colorScheme="blue" onClick={() => setIsFilterOpen(false)}>Apply</Button>
                </HStack>
              </PopoverFooter>
            </PopoverContent>
          </Popover>
        }
        searchRightElement={
          <Box>
            <Button
              leftIcon={<Icon as={FiPlus} />}
              colorScheme="blue"
              borderRadius="md"
              onClick={handleCreateContact}
            >
              New Contact
            </Button>
          </Box>
        }
      />

      <CreateContactModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
      />

      {isLoading ? (
        <TableSkeleton rowCount={10} />
      ) : isError ? (
        <Box p={4} color="red.500">
          <Text>Failed to load contacts. Please try again later.</Text>
        </Box>
      ) : (
        <>
          <SimpleGrid mb='20px' columns={{ sm: 1, md: 1 }} spacing={{ base: "20px", xl: "20px" }}>
            <Box
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              p={4}
              bg="white"
            >
              {!isLoading && (!contacts || contacts.length === 0) ? (
                <Text textAlign="center" py={8} color="gray.500">
                  No contacts available.
                </Text>
              ) : (
                <ContactsTable
                  contacts={sortedContacts}
                  onViewDetails={handleViewDetails}
                  onSort={handleSort}
                  sortConfig={sortConfig}
                  searchQuery={debouncedSearchQuery}
                />
              )}
            </Box>
          </SimpleGrid>

          {sortedContacts.length > 0 && (
            <PaginationControls
              currentPage={currentPage}
              totalPages={totalPages}
              onPrevious={handlePreviousPage}
              onNext={() => {
                if (currentPage < totalPages) {
                  setCurrentPage((prev) => prev + 1);
                }
              }}
              disableNext={currentPage >= totalPages}
              disablePrevious={currentPage === 1}
              onPageSelect={handlePageSelect}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default Contacts;