// /Users/kylearchie/Documents/GitHub/lysto-fe/src/views/lysto/Meetings/index.jsx
// MeetingsView.jsx
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Box,
  Text,
  SimpleGrid,
  Icon,
  HStack,
  Tooltip,
  Avatar,
  useColorModeValue,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverFooter,
  PopoverHeader,
  FormControl,
  FormLabel,
  Input,
  CheckboxGroup,
  Checkbox,
  VStack,
  Stack,
  Spinner,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useAuthData } from 'auth-context';
import { Video, MapPin, CalendarDays, ChevronDown, ChevronUp } from 'lucide-react';
import { useDebounce } from 'use-debounce';
import { FaExpandAlt } from 'react-icons/fa';
import { DateTime } from 'luxon';
import { useFilterState } from '../hooks/useFilterState';
import { FiFilter } from 'react-icons/fi';
import axios from 'axios';

import PageHeader from '../Shared/PageHeader';
import DataTable from '../Shared/DataTable';
import PaginationControls from '../Shared/PaginationControls';
import HoverBox from '../Shared/HoverBox';
import TableSkeleton from '../Shared/TableSkeleton';
import useFetchMeetings from '../hooks/useFetchMeetings';

const ITEMS_PER_PAGE = 10;

const MeetingsView = () => {
  const navigate = useNavigate();
  const userData = useAuthData();
  const accountID = userData?.account_ID;
  const userID = userData?.user_ID;
  const { getAccessToken } = useAuthData();

  // State management
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedSegment, setSelectedSegment] = useFilterState('meetings-segment-filter', 'schedule');
  const [searchQuery, setSearchQuery] = useFilterState('meetings-search-query', '');
  const [sortConfig, setSortConfig] = useState({
    key: 'startTime',
    direction: 'ascending'
  });
  const [dynamicFilters, setDynamicFilters] = useFilterState('meetings-dynamic-filters', {});

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [isLoadingHistorical, setIsLoadingHistorical] = useState(false);
  const [hasLoadedHistorical, setHasLoadedHistorical] = useState(false);

  // Add handler for segment changes
  const handleSegmentChange = useCallback((newSegment) => {
    setSelectedSegment(newSegment);
    // Reset historical data
    setHasLoadedHistorical(false);
    // Reset filters when switching segments
    setDynamicFilters({});
  }, [setSelectedSegment, setDynamicFilters]);

  // Debounce search query
  const [debouncedSearchQuery] = useDebounce(searchQuery, 800);

  // Fetch meetings using the custom hook
  const {
    allMeetings,
    loading: isFetching,
    error: fetchError
  } = useFetchMeetings(
    accountID,
    userID,
    selectedSegment,
    currentPage,
    debouncedSearchQuery.trim(),
    dynamicFilters
  );

  // Helper functions for filtering
  const getColumnType = (meetings, columnName) => {
    if (!meetings || meetings.length === 0) return null;
    
    // Special handling for attendees
    if (columnName === 'attendees') return 'option';
    if (columnName === 'duration') return 'number';
    if (columnName === 'startTime' || columnName === 'endTime') return 'date';
    
    const sampleValue = meetings.find(meeting => meeting[columnName] != null)?.[columnName];
    
    if (columnName.toLowerCase().includes('date')) return 'date';
    if (typeof sampleValue === 'number') return 'number';
    return 'option';
  };

  const getColumnValues = (meetings, columnName) => {
    // Special handling for attendees
    if (columnName === 'attendees') {
      const attendeeMap = new Map();
      meetings.forEach(meeting => {
        meeting.attendees?.forEach(att => {
          if (att.email) {
            attendeeMap.set(att.email, (attendeeMap.get(att.email) || 0) + 1);
          }
        });
      });

      const entries = Array.from(attendeeMap.entries());
      if (entries.length < 2) return [];

      return entries
        .sort((a, b) => b[1] - a[1])
        .map(([email, count]) => ({
          value: email,
          count,
          label: `${email} (${count})`
        }));
    }

    // Regular column handling
    const frequencyMap = meetings.reduce((acc, meeting) => {
      const value = meeting[columnName];
      if (value) {
        acc[value] = (acc[value] || 0) + 1;
      } else {
        acc["None"] = (acc["None"] || 0) + 1;
      }
      return acc;
    }, {});

    const entries = Object.entries(frequencyMap);
    const nonNoneEntries = entries.filter(([value]) => value !== "None");
    
    if (nonNoneEntries.length < 2 || !nonNoneEntries.some(([, count]) => count >= 3)) {
      return [];
    }

    return entries
      .filter(([value, count]) => value === "None" ? count >= 3 : true)
      .sort((a, b) => {
        const countDiff = b[1] - a[1];
        if (countDiff !== 0) return countDiff;
        if (a[0] === "None") return 1;
        if (b[0] === "None") return -1;
        return a[0].localeCompare(b[0]);
      })
      .map(([value, count]) => ({
        value,
        count,
        label: `${value} (${count})`
      }));
  };

  const getColumnRange = (meetings, columnName) => {
    const values = meetings.map(meeting => meeting[columnName]).filter(value => typeof value === 'number');
    if (values.length === 0) return null;

    const uniqueValues = new Set(values);
    if (uniqueValues.size < 3) return null;

    return {
      min: Math.min(...values),
      max: Math.max(...values)
    };
  };

  const titleCase = (str) => {
    return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  // Function to capitalize first letter
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  // Handle row click to view meeting details
  const handleViewDetails = useCallback((meeting) => {
    navigate(`/admin/meetings/${meeting.id}`);
  }, [navigate]);

  // Format location with Zoom support
  const renderLocation = useCallback((row) => {
    const isZoom = row.location?.toLowerCase().includes('zoom') || 
                  row.description?.toLowerCase().includes('zoom');
    const hasLink = row.location?.includes('http') || row.hangoutLink;

    if (hasLink || isZoom) {
      const meetingLink = row.location?.includes('http') ? 
        row.location : 
        (row.hangoutLink || row.location);
        
      return (
        <HoverBox
          icon={Video}
          hoverIcon={FaExpandAlt}
          onClick={() => window.open(meetingLink, '_blank')}
          tooltipLabel="Join Meeting"
        >
          <Text>{isZoom ? 'Zoom Meeting' : 'Google Meet'}</Text>
        </HoverBox>
      );
    }

    if (row.location) {
      return (
        <Box display="flex" alignItems="center">
          <Icon as={MapPin} color="gray.500" boxSize={4} mr={2} />
          <Text>{row.location}</Text>
        </Box>
      );
    }

    return <Text>No location</Text>;
  }, []);

  // Get row icon based on meeting type
  const getRowIcon = useCallback(() => {
    return CalendarDays;
  }, []);

  // Table columns configuration
  const columns = useMemo(() => {
    const baseColumns = [
      {
        key: 'title',
        label: 'Meeting',
        minWidth: '200px',
        maxWidth: '40%',
        sortable: true,
        isFirst: true,
        render: (row) => (
          <Box 
            width="100%" 
            display="flex" 
            alignItems="center"
            position="relative"
          >
            <HoverBox
              icon={CalendarDays}
              hoverIcon={FaExpandAlt}
              onClick={() => handleViewDetails(row)}
              tooltipLabel="View Details"
              width="100%"
              tooltipProps={{
                placement: "top",
                hasArrow: true,
                gutter: 8
              }}
            >
              <Box width="100%" display="flex" alignItems="center">
                <Text isTruncated flex="1">
                  {row.title}
                </Text>
              </Box>
            </HoverBox>
          </Box>
        )
      },
      {
        key: 'location',
        label: 'Location',
        minWidth: '120px',
        maxWidth: '20%',
        render: renderLocation
      },
      {
        key: 'attendees',
        label: 'Attendees',
        minWidth: '150px',
        maxWidth: '25%',
        render: (row) => {
          // Sort attendees: accepted first, then others
          const sortedAttendees = [...(row.attendees || [])].sort((a, b) => {
            const getResponsePriority = (response) => {
              switch (response?.toLowerCase()) {
                case 'accepted': return 0;
                case 'tentative': return 1;
                case 'declined': return 2;
                default: return 3;
              }
            };
            return getResponsePriority(a.response) - getResponsePriority(b.response);
          });

          return (
            <HStack spacing={2} wrap="wrap">
              {sortedAttendees.map((att, index) => {
                const email = att.email;
                const response = att.response?.toLowerCase() || 'needsaction';
                const name = att.name || email?.split('@')[0];
                const displayName = capitalizeFirstLetter(name);
                const role = att.organizer
                  ? 'Organizer'
                  : att.optional
                  ? 'Optional'
                  : 'Attendee';
                
                if (!email) return null;

                const formatResponse = (resp) => {
                  switch(resp) {
                    case 'needsaction': return 'Needs Action';
                    default: return capitalizeFirstLetter(resp);
                  }
                };
                
                return (
                  <Tooltip 
                    key={index} 
                    label={`${role} • ${formatResponse(response)} • ${email}`}
                    placement="top"
                  >
                    <HStack spacing={2}>
                      <Avatar
                        size="xs"
                        name={displayName}
                        bg={
                          response === 'accepted'
                            ? 'blue.200'
                            : response === 'tentative'
                            ? 'purple.200'
                            : response === 'declined'
                            ? 'red.200'
                            : 'gray.200'
                        }
                        color={useColorModeValue("gray.700", "white")}
                      />
                    </HStack>
                  </Tooltip>
                );
              })}
            </HStack>
          );
        }
      }
    ];

    // Add time/date column based on segment
    if (selectedSegment === 'schedule') {
      baseColumns.push({
        key: 'startTime',
        label: 'Start Time',
        minWidth: '150px',
        sortable: true,
        render: (row) => {
          const startTime = DateTime.fromISO(row.startTime);
          const isToday = startTime.hasSame(DateTime.now(), 'day');
          const isTomorrow = startTime.hasSame(DateTime.now().plus({ days: 1 }), 'day');
          
          let dateDisplay;
          if (isToday) {
            dateDisplay = 'Today';
          } else if (isTomorrow) {
            dateDisplay = 'Tomorrow';
          } else {
            dateDisplay = startTime.toFormat('LLL d');
          }
          
          return (
            <Text>
              {dateDisplay} at {startTime.toFormat('h:mm a')}
            </Text>
          );
        }
      });
    } else {
      baseColumns.push({
        key: 'startTime',
        label: 'Recorded',
        minWidth: '150px',
        sortable: true,
        render: (row) => {
          const recordingDate = DateTime.fromISO(row.startTime);
          return (
            <Text>{recordingDate.toFormat('LLL d, h:mm a')}</Text>
          );
        }
      });
    }

    return baseColumns;
  }, [selectedSegment, renderLocation, handleViewDetails]);

  // Compute filteredMeetings using allMeetings instead of the paginated subset
  const filteredMeetings = useMemo(() => {
    if (!allMeetings) return [];
    let filtered = allMeetings;

    if (debouncedSearchQuery.trim()) {
      const searchLower = debouncedSearchQuery.toLowerCase();
      filtered = filtered.filter(meeting => 
        meeting.title?.toLowerCase().includes(searchLower) ||
        meeting.description?.toLowerCase().includes(searchLower) ||
        meeting.attendees?.some(att => 
          att.name?.toLowerCase().includes(searchLower) ||
          att.email?.toLowerCase().includes(searchLower)
        )
      );
    }

    Object.entries(dynamicFilters).forEach(([column, filter]) => {
      if (!filter) return;

      if (column === 'attendees') {
        if (filter.values && filter.values.length > 0) {
          filtered = filtered.filter(meeting => 
            meeting.attendees?.some(att => 
              filter.values.includes(att.email)
            )
          );
        }
        return;
      }

      if (column === 'meetingType') {
        if (filter.values && filter.values.length > 0) {
          filtered = filtered.filter(meeting => {
            const isZoom = meeting.location?.toLowerCase().includes('zoom');
            const isGoogleMeet = !!meeting.hangoutLink;
            const isInPerson = meeting.location && !isZoom && !isGoogleMeet;
            
            return filter.values.some(value => {
              switch(value) {
                case 'zoom': return isZoom;
                case 'meet': return isGoogleMeet;
                case 'inPerson': return isInPerson;
                default: return false;
              }
            });
          });
        }
        return;
      }

      const type = getColumnType(allMeetings, column);
      
      if (type === 'date' && filter.startDate && filter.endDate) {
        const start = DateTime.fromISO(filter.startDate).startOf('day');
        const end = DateTime.fromISO(filter.endDate).endOf('day');
        filtered = filtered.filter(meeting => {
          const value = meeting[column];
          if (!value) return false;
          const meetingDate = DateTime.fromISO(value);
          return meetingDate >= start && meetingDate <= end;
        });
      }
      
      if (type === 'number' && (filter.min !== undefined || filter.max !== undefined)) {
        filtered = filtered.filter(meeting => {
          const value = meeting[column];
          if (!value) return false;
          if (filter.min !== undefined && value < filter.min) return false;
          if (filter.max !== undefined && value > filter.max) return false;
          return true;
        });
      }
      
      if (type === 'option' && filter.values && filter.values.length > 0) {
        filtered = filtered.filter(meeting => {
          const value = meeting[column];
          return filter.values.includes(value || "None");
        });
      }
    });

    return filtered;
  }, [allMeetings, debouncedSearchQuery, dynamicFilters]);

  // Compute total pages based on filteredMeetings
  const computedTotalPages = useMemo(() => {
    return Math.ceil(filteredMeetings.length / ITEMS_PER_PAGE) || 1;
  }, [filteredMeetings]);

  // Get current page's meetings from filteredMeetings
  const currentMeetings = useMemo(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return filteredMeetings.slice(startIndex, endIndex);
  }, [currentPage, filteredMeetings]);

  // Update pagination handlers
  const handlePreviousPage = () => {
    setCurrentPage(prev => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prev => Math.min(prev + 1, computedTotalPages));
  };

  const handlePageSelect = (pageNum) => {
    setCurrentPage(pageNum);
  };

  // Reset to first page when filters change
  useEffect(() => {
    setCurrentPage(1);
  }, [allMeetings, debouncedSearchQuery, dynamicFilters]);

  // Update local state when fetched meetings change
  useEffect(() => {
    if (filteredMeetings) {
      setCurrentPage(1);
    }
  }, [filteredMeetings]);

  // Effect to update sort direction when segment changes
  useEffect(() => {
    setSortConfig(() => ({
      key: 'startTime',
      direction: selectedSegment === 'schedule' ? 'ascending' : 'descending'
    }));
  }, [selectedSegment]);

  // Generate search suggestions based on allMeetings
  const searchSuggestions = useMemo(() => {
    const filteredItems = debouncedSearchQuery.trim() !== ""
      ? allMeetings.filter(item => 
          item.title && item.title.toLowerCase().includes(debouncedSearchQuery.toLowerCase())
        )
      : allMeetings;

    const uniqueSubjectsMap = new Map();
    filteredItems.forEach((item) => {
      if (item.title && !uniqueSubjectsMap.has(item.title)) {
        uniqueSubjectsMap.set(item.title, item.id);
      }
    });

    return Array.from(uniqueSubjectsMap.entries()).map(([title, id]) => ({
      id,
      label: title,
    }));
  }, [allMeetings, debouncedSearchQuery]);

  // Add filterableColumns computation
  const filterableColumns = useMemo(() => {
    if (!allMeetings || allMeetings.length === 0) return { date: [], number: [], option: [] };
    
    const columns = { date: [], number: [], option: [] };
    const blacklist = [
      "id",
      "description",
      "hangoutLink",
      "hangoutlink",
      "recordingUrl",
      "timezone",
      "status",
      "title",
      "summary"
    ];
    
    // Add attendees as a special case
    columns.option.push({
      name: 'attendees',
      label: 'Attendees',
      values: getColumnValues(allMeetings, 'attendees')
    });

    // Add meeting type filter
    const meetingTypes = {
      zoom: allMeetings.filter(m => m.location?.toLowerCase().includes('zoom')).length,
      meet: allMeetings.filter(m => m.hangoutLink).length,
      inPerson: allMeetings.filter(m => m.location && !m.location.toLowerCase().includes('zoom') && !m.hangoutLink).length
    };

    if (Object.values(meetingTypes).some(count => count > 0)) {
      columns.option.push({
        name: 'meetingType',
        label: 'Meeting Type',
        values: [
          { value: 'zoom', label: `Zoom Meeting (${meetingTypes.zoom})`, count: meetingTypes.zoom },
          { value: 'meet', label: `Google Meet (${meetingTypes.meet})`, count: meetingTypes.meet },
          { value: 'inPerson', label: `In Person (${meetingTypes.inPerson})`, count: meetingTypes.inPerson }
        ].filter(type => type.count > 0)
      });
    }
    
    // Process other columns
    const sampleMeeting = allMeetings[0];
    Object.keys(sampleMeeting).forEach(column => {
      if (blacklist.includes(column.toLowerCase())) return;
      
      const type = getColumnType(allMeetings, column);
      
      if (type === 'date') {
        const dateFrequencyMap = allMeetings.reduce((acc, meeting) => {
          const value = meeting[column];
          if (value) {
            const date = DateTime.fromISO(value);
            const monthYear = `${date.month}-${date.year}`;
            acc[monthYear] = (acc[monthYear] || 0) + 1;
          }
          return acc;
        }, {});

        const hasFrequentDates = Object.values(dateFrequencyMap).some(count => count >= 3);
        if (hasFrequentDates) {
          columns.date.push({
            name: column,
            label: titleCase(column.replace(/_/g, " ").replace(/([A-Z])/g, ' $1').trim())
          });
        }
      } else if (type === 'number') {
        const range = getColumnRange(allMeetings, column);
        if (range) {
          columns.number.push({
            name: column,
            label: titleCase(column.replace(/_/g, " ").replace(/([A-Z])/g, ' $1').trim()),
            range
          });
        }
      } else if (type === 'option' && column !== 'attendees' && column !== 'meetingType') {
        const values = getColumnValues(allMeetings, column);
        if (values.length > 0) {
          columns.option.push({
            name: column,
            label: titleCase(column.replace(/_/g, " ").replace(/([A-Z])/g, ' $1').trim()),
            values
          });
        }
      }
    });
    
    return columns;
  }, [allMeetings]);

  // Add theme colors
  const selectedBg = useColorModeValue("blue.500", "blue.300");
  const selectedTextColor = useColorModeValue("white", "gray.800");
  const unselectedBg = useColorModeValue("white", "gray.600");
  const unselectedTextColor = useColorModeValue("gray.700", "gray.200");
  const buttonHoverBg = useColorModeValue("gray.200", "gray.500");

  // Add state for expanded sections and loading
  const [expandedSections, setExpandedSections] = useState({});
  const [loadingSections, setLoadingSections] = useState({});

  // Add toggle section handler
  const toggleSection = (sectionName) => {
    setExpandedSections(prev => {
      const newExpanded = { ...prev, [sectionName]: !prev[sectionName] };
      if (newExpanded[sectionName]) {
        setLoadingSections(prevLoad => ({ ...prevLoad, [sectionName]: true }));
        setTimeout(() => {
          setLoadingSections(prevLoad => ({ ...prevLoad, [sectionName]: false }));
        }, 300);
      } else {
        setLoadingSections(prevLoad => ({ ...prevLoad, [sectionName]: false }));
      }
      return newExpanded;
    });
  };

  // Add filter reset handler
  const handleResetFilters = () => {
    setDynamicFilters({});
  };

  // Add hasActiveFilters computation
  const hasActiveFilters = useMemo(() => {
    return Object.values(dynamicFilters).some(filter => {
      if (!filter) return false;
      if (filter.startDate || filter.endDate) return true;
      if (filter.min !== undefined || filter.max !== undefined) return true;
      if (filter.values && filter.values.length > 0) return true;
      return false;
    });
  }, [dynamicFilters]);

  // Function to fetch historical data
  const fetchHistoricalData = useCallback(async () => {
    if (!accountID || !userID || hasLoadedHistorical) return;

    try {
      setIsLoadingHistorical(true);
      const accessToken = await getAccessToken();
      const baseUrl =
        process.env.REACT_APP_NODE_ENV === "local"
          ? "https://lysto-staging-eyf9ejcwf8h3awbw.eastus2-01.azurewebsites.net"
          : "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      // Fetch a larger page size for filter data
      await axios.get(
        `${baseUrl}/accounts/${accountID}/users/${userID}/calendar/events`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: {
            page_number: 1,
            items_per_page: 100, // Fetch more items for filter data
            start_time: new Date().toISOString(),
            status: "confirmed",
            time_range: selectedSegment === "recordings" ? "past" : "future",
            include_recorded: selectedSegment === "recordings",
            recordings_only: selectedSegment === "recordings",
          }
        }
      );

      setHasLoadedHistorical(true);
    } catch (err) {
      console.error("Error fetching historical data:", err);
    } finally {
      setIsLoadingHistorical(false);
    }
  }, [accountID, userID, hasLoadedHistorical, getAccessToken, selectedSegment]);

  // Add effect to fetch historical data when filter opens
  useEffect(() => {
    if (isFilterOpen && !hasLoadedHistorical && !isLoadingHistorical) {
      fetchHistoricalData();
    }
  }, [isFilterOpen, hasLoadedHistorical, isLoadingHistorical, fetchHistoricalData]);

  return (
    <Box px={4}>
      <PageHeader
        title="Meetings"
        segmentOptions={[
          { label: "Upcoming", value: "schedule" },
          { label: "Recordings", value: "recordings" }
        ]}
        selectedSegment={selectedSegment}
        onSegmentChange={handleSegmentChange}
        searchPlaceholder="Search meetings..."
        onSearchChange={setSearchQuery}
        searchQuery={searchQuery}
        searchSuggestions={searchSuggestions}
        onSuggestionSelect={handleViewDetails}
        additionalControls={
          <Popover 
            placement="bottom-end" 
            isOpen={isFilterOpen}
            onClose={() => setIsFilterOpen(false)}
          >
            <PopoverTrigger>
              <Button
                leftIcon={<Icon as={FiFilter} />}
                rightIcon={<Icon as={isFilterOpen ? ChevronUp : ChevronDown} />}
                variant="outline"
                bg="white"
                borderRadius="md"
                borderColor="gray.300"
                color={useColorModeValue("gray.700", "gray.200")}
                _hover={{
                  bg: "gray.50",
                }}
                onClick={() => setIsFilterOpen(true)}
              >
                Filters {hasActiveFilters && `(${Object.values(dynamicFilters).filter(Boolean).length})`}
              </Button>
            </PopoverTrigger>
            <PopoverContent 
              width="400px" 
              maxH="calc(100vh - var(--chakra-space-48))"
              position="relative"
              boxShadow="lg"
              mt={2}
              display="flex"
              flexDirection="column"
            >
              <PopoverHeader 
                fontWeight="semibold" 
                position="sticky"
                top={0}
                bg="white"
                zIndex={1}
                borderBottomWidth="1px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text>Advanced Filters</Text>
                {(isFetching || isLoadingHistorical) && <Spinner size="sm" ml={2} />}
                <Text fontSize="sm" color="gray.500">
                  {filteredMeetings.length} meetings
                </Text>
              </PopoverHeader>
              <PopoverBody 
                flex="1" 
                overflowY="auto"
                py={4}
              >
                <VStack spacing={4} align="stretch">
                  {/* Date Filters */}
                  {filterableColumns.date && filterableColumns.date.length > 0 && (
                    <Box borderWidth="1px" borderRadius="md" p={4} bg="gray.50">
                      <FormControl mb={4}>
                        <FormLabel fontWeight="medium">Filter Date By</FormLabel>
                        <HStack wrap="wrap" spacing={2}>
                          {filterableColumns.date.map((column) => (
                            <Button
                              key={column.name}
                              size="sm"
                              bg={dynamicFilters[column.name] ? selectedBg : unselectedBg}
                              color={dynamicFilters[column.name] ? selectedTextColor : unselectedTextColor}
                              _hover={{
                                bg: dynamicFilters[column.name] ? selectedBg : buttonHoverBg,
                                color: dynamicFilters[column.name] ? selectedTextColor : 'inherit',
                              }}
                              borderWidth="1px"
                              borderColor="gray.300"
                              borderRadius="md"
                              onClick={() => {
                                if (dynamicFilters[column.name]) {
                                  const newFilters = { ...dynamicFilters };
                                  delete newFilters[column.name];
                                  setDynamicFilters(newFilters);
                                } else {
                                  setDynamicFilters(prev => ({
                                    ...prev,
                                    [column.name]: {}
                                  }));
                                }
                              }}
                            >
                              {column.label}
                            </Button>
                          ))}
                        </HStack>
                      </FormControl>

                      <VStack spacing={4} align="stretch">
                        {filterableColumns.date.map((column) => (
                          dynamicFilters[column.name] && (
                            <FormControl key={column.name}>
                              <FormLabel fontWeight="medium">Select Range for {column.label}</FormLabel>
                              <Stack direction="row">
                                <Input
                                  type="date"
                                  value={dynamicFilters[column.name]?.startDate || ''}
                                  onChange={(e) => setDynamicFilters(prev => ({
                                    ...prev,
                                    [column.name]: {
                                      ...prev[column.name],
                                      startDate: e.target.value
                                    }
                                  }))}
                                  bg="white"
                                />
                                <Input
                                  type="date"
                                  value={dynamicFilters[column.name]?.endDate || ''}
                                  onChange={(e) => setDynamicFilters(prev => ({
                                    ...prev,
                                    [column.name]: {
                                      ...prev[column.name],
                                      endDate: e.target.value
                                    }
                                  }))}
                                  bg="white"
                                />
                              </Stack>
                            </FormControl>
                          )
                        ))}
                      </VStack>
                    </Box>
                  )}

                  {/* Option Filters */}
                  {filterableColumns.option && filterableColumns.option.length > 0 && (
                    filterableColumns.option.map((column) => (
                      <FormControl key={column.name}>
                        <Button
                          variant="unstyled"
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          onClick={() => toggleSection(column.name)}
                          mb={2}
                          _hover={{ bg: "gray.50" }}
                          p={2}
                          borderRadius="md"
                        >
                          <FormLabel fontWeight="medium" mb={0}>
                            {column.label}
                            {dynamicFilters[column.name]?.values?.length > 0 && (
                              <Text as="span" ml={2} color="gray.500" fontSize="sm">
                                ({dynamicFilters[column.name].values.length} filter{dynamicFilters[column.name].values.length === 1 ? '' : 's'})
                              </Text>
                            )}
                          </FormLabel>
                          <Icon
                            as={expandedSections[column.name] ? ChevronUp : ChevronDown}
                            boxSize={5}
                          />
                        </Button>
                        {expandedSections[column.name] && (
                          loadingSections[column.name] ? (
                            <Box p={4} display="flex" justifyContent="center" alignItems="center">
                              <Spinner />
                            </Box>
                          ) : (
                            <Box 
                              maxH="150px"
                              overflowY="auto"
                              borderWidth="1px"
                              borderColor="gray.200"
                              borderRadius="md"
                              bg="gray.50"
                              _hover={{ borderColor: "gray.300" }}
                              sx={{
                                '&::-webkit-scrollbar': {
                                  width: '8px',
                                  borderRadius: '8px',
                                  backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                  borderRadius: '8px',
                                },
                              }}
                            >
                              <CheckboxGroup 
                                value={dynamicFilters[column.name]?.values?.map(String) || []}
                                onChange={(values) => {
                                  if (values.length === 0) {
                                    const newFilters = { ...dynamicFilters };
                                    delete newFilters[column.name];
                                    setDynamicFilters(newFilters);
                                  } else {
                                    setDynamicFilters(prev => ({
                                      ...prev,
                                      [column.name]: { values: values.map(String) }
                                    }));
                                  }
                                }}
                              >
                                <Stack spacing={2} p={3}>
                                  {column.values.map((item) => (
                                    <Checkbox key={item.value} value={item.value}>
                                      {item.label}
                                    </Checkbox>
                                  ))}
                                </Stack>
                              </CheckboxGroup>
                            </Box>
                          )
                        )}
                      </FormControl>
                    ))
                  )}
                </VStack>
              </PopoverBody>
              <PopoverFooter
                position="sticky"
                bottom={0}
                bg="white"
                zIndex={1}
                borderTopWidth="1px"
                py={3}
              >
                <HStack justify="flex-end" spacing={2}>
                  <Button size="sm" onClick={handleResetFilters}>Reset</Button>
                  <Button size="sm" colorScheme="blue" onClick={() => setIsFilterOpen(false)}>Apply</Button>
                </HStack>
              </PopoverFooter>
            </PopoverContent>
          </Popover>
        }
      />

      <SimpleGrid mb="20px" columns={{ sm: 1, md: 1 }} spacing={{ base: "20px", xl: "20px" }}>
        <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4} bg={useColorModeValue("white", "gray.800")}>
          {isFetching && (!allMeetings || allMeetings.length === 0) ? (
            <TableSkeleton columns={columns} />
          ) : fetchError ? (
            <Text color="red.500" textAlign="center" py={4}>Error: {fetchError}</Text>
          ) : filteredMeetings.length === 0 ? (
            <Text textAlign="center" py={8} color="gray.500">
              No {selectedSegment.toLowerCase()} meetings available.
            </Text>
          ) : (
            <>
              <DataTable
                data={currentMeetings}
                columns={columns}
                getRowIcon={getRowIcon}
                sortConfig={sortConfig}
                onSort={(key) => {
                  setSortConfig(prevConfig => ({
                    key,
                    direction: prevConfig.key === key && prevConfig.direction === 'ascending' 
                      ? 'descending' 
                      : 'ascending'
                  }));
                }}
                loading={isFetching}
              />
              {filteredMeetings.length > 0 && (
                <PaginationControls
                  currentPage={currentPage}
                  totalPages={computedTotalPages}
                  onPrevious={handlePreviousPage}
                  onNext={handleNextPage}
                  disableNext={currentPage === computedTotalPages}
                  disablePrevious={currentPage === 1}
                  onPageSelect={handlePageSelect}
                />
              )}
            </>
          )}
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default MeetingsView;