import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  VStack,
  Text,
  Flex,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { ListTodo, Clock } from 'lucide-react';
import { OverviewTag } from '../Shared/Tags';
import { useNavigate } from "react-router-dom";
import { formatDistanceToNow } from 'date-fns';
import ExternalLinksSection from './ExternalLinksSection';
import MeetingNotes from './MeetingNotes';
import AttendeesSection from './AttendeesSection';
import CallRecordingPlayer from './CallRecordingPlayer';
import { useFetchMeetingDeals } from '../hooks/useFetchMeetingDeals';
import DealTag from "../Contacts/DealTag";
import GoBackHeader from "../Shared/GoBackHeader";
import PageHeader from "../Shared/PageHeader";

const MeetingDetailHeader = ({ lastActivity }) => (
  <Box mb={0}>
    <Text fontSize="md" fontWeight="semibold" color="gray.700" mb={4}>
      Overview
    </Text>
    <Flex gap={4} flexWrap="wrap">
      <OverviewTag
        icon={ListTodo}
        label="Type"    
        value="Meeting"
        colorScheme="green"
      />
      <OverviewTag
        icon={Clock}
        label="Last Activity"
        value={lastActivity}
        colorScheme="orange"
      />
    </Flex>
  </Box>
);

const MeetingDealsSection = ({ deals, onViewDetails }) => (
  <Box>
    <Text fontSize="md" fontWeight="semibold" color="gray.700" mb={4}>
      Deals
    </Text>
    {deals.length === 0 ? (
      <Text color="gray.500">No deals associated with this meeting.</Text>
    ) : (
      <Flex gap={4} flexWrap="wrap">
        {deals.map((deal) => (
          <DealTag
            key={deal.id}
            dealName={deal.deal_name}
            onClick={() => onViewDetails(deal)}
          />
        ))}
      </Flex>
    )}
  </Box>
);

const MeetingDetail = ({ meetingAnalysis }) => {
  const navigate = useNavigate();
  const [audioCurrentTime, setAudioCurrentTime] = useState(0);
  const [audioIsPlaying, setAudioIsPlaying] = useState(false);
  const [audioPlaybackSpeed, setAudioPlaybackSpeed] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');

  const { data: deals, isLoading, error } = useFetchMeetingDeals(
    meetingAnalysis?.account_id,
    meetingAnalysis?.deal_id
  );

  if (!meetingAnalysis) {
    console.log('No meeting analysis data');
    return <Text>No meeting data found.</Text>;
  }

  if (isLoading) {
    console.log('Loading meeting analysis');
    return (
      <Center py={8}>
        <Spinner size="xl" />
      </Center>
    );
  }

  if (error) {
    console.error('Meeting analysis error:', error);
    return <Text color="red.500">{error}</Text>;
  }

  console.log('Meeting analysis data:', {
    hasTranscript: !!meetingAnalysis.transcript_text,
    transcriptLength: meetingAnalysis.transcript_text?.length,
    transcriptPreview: meetingAnalysis.transcript_text?.substring(0, 100)
  });

  const lastActivity = meetingAnalysis.date 
    ? formatDistanceToNow(new Date(meetingAnalysis.date), { addSuffix: true })
    : 'N/A';

  const handleViewDetails = (deal) => {
    navigate(`/admin/deals/${deal.id}`);
  };

  // Audio player controls
  const handlePlayPause = () => {
    setAudioIsPlaying(!audioIsPlaying);
  };

  const handleSeek = (time) => {
    setAudioCurrentTime(time);
  };

  const handleAudioError = (err) => {
    console.error("Audio Error:", err);
  };

  const handlePlaybackSpeedChange = (speed) => {
    setAudioPlaybackSpeed(speed);
  };

  const handleSearchQueryChange = (query) => {
    setSearchQuery(query)
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  const noop = () => undefined;

  return (
    <Box
      bg="transparent"
      p={0}
      borderRadius="md"
      boxShadow="none"
      mb={4}
      height="100%"
    >
      {/* Main Header */}
      <PageHeader
        title="Meetings"
        segmentOptions={[]}
        selectedSegment=""
        onSegmentChange={noop}
        onSearchChange={noop}
        showSearch={false}
        isDetailView
      />

      {/* Go Back Header */}
      <Box mb={2}>
        <GoBackHeader
          onGoBack={handleGoBack}
          title={meetingAnalysis?.title || "Meeting Details"}
        />
      </Box>

      <VStack spacing={6} align="stretch" height="100%">
        {/* Card 1: Overview, External Links, Attendees, and Deals */}
        <Box
          bg="white"
          p={6}
          borderRadius="md"
          boxShadow="sm"
          borderWidth="1px"
          borderColor="gray.200"
        >
          <VStack spacing={6} align="stretch">
            <MeetingDetailHeader lastActivity={lastActivity} />
            
            <ExternalLinksSection 
              meetingLink={meetingAnalysis.meeting_link}
              recordingUrl={meetingAnalysis.recording_url}
            />

            <AttendeesSection attendees={meetingAnalysis.attendees} />

            <MeetingDealsSection 
              deals={deals} 
              onViewDetails={handleViewDetails}
            />
          </VStack>
        </Box>

        {/* Card 2: AI Summary */}
        {meetingAnalysis.ai_summary && (
          <Box
            bg="white"
            p={6}
            borderRadius="md"
            boxShadow="sm"
            borderWidth="1px"
            borderColor="gray.200"
          >
            <VStack spacing={6} align="stretch">
              <Text fontSize="md" fontWeight="semibold" color="gray.700">
                Meeting Notes
              </Text>
              <MeetingNotes notes={meetingAnalysis.ai_summary} />
            </VStack>
          </Box>
        )}

         {/* Card 3: Transcript */}
        {meetingAnalysis.transcript_text && (
          <Box
              bg="white"
              p={6}
              borderRadius="md"
              boxShadow="sm"
              borderWidth="1px"
              borderColor="gray.200"
          >
              <VStack spacing={6} align="stretch">
                  <CallRecordingPlayer
                      transcript={meetingAnalysis.transcript_text}
                      audioUrl={meetingAnalysis.recording_url}
                      currentTime={audioCurrentTime}
                      duration={meetingAnalysis.duration || 0}
                      isPlaying={audioIsPlaying}
                      onPlayPause={handlePlayPause}
                      onSeek={handleSeek}
                      onError={handleAudioError}
                      playbackSpeed={audioPlaybackSpeed}
                      onPlaybackSpeedChange={handlePlaybackSpeedChange}
                      searchQuery={searchQuery}
                      onSearchQueryChange={handleSearchQueryChange}
                      onSpeakerSegmentClick={handleSeek}
                  />
              </VStack>
          </Box>
        )}
      </VStack>
    </Box>
  );
};

MeetingDetailHeader.propTypes = {
  lastActivity: PropTypes.string.isRequired,
};

MeetingDetail.propTypes = {
  meetingAnalysis: PropTypes.shape({
    ID: PropTypes.number,
    account_id: PropTypes.number,
    user_id: PropTypes.number,
    audio_id: PropTypes.any,
    attendees: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        event_id: PropTypes.number,
        account_id: PropTypes.number,
        user_id: PropTypes.number,
        additional_guests: PropTypes.number,
        comment: PropTypes.string,
        display_name: PropTypes.string,
        email: PropTypes.string,
        attendee_id: PropTypes.string,
        optional: PropTypes.bool,
        organizer: PropTypes.bool,
        resource: PropTypes.bool,
        response_status: PropTypes.string,
        self: PropTypes.bool,
        created_at: PropTypes.string,
        updated_at: PropTypes.string,
      })
    ),
    deal_id: PropTypes.number,
    title: PropTypes.string,
    meeting_link: PropTypes.string,
    recording_url: PropTypes.string,
    transcript_text: PropTypes.string,
    ai_summary: PropTypes.string,
    date: PropTypes.string,
    timezone: PropTypes.string,
    duration: PropTypes.string,
    // Additional props for transcript analysis
    transcript_analysis: PropTypes.shape({
      speaker_segments: PropTypes.arrayOf(
        PropTypes.shape({
          speaker: PropTypes.string,
          start_time: PropTypes.number,
          end_time: PropTypes.number,
        })
      ),
      speakers: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
};

MeetingDetailHeader.propTypes = {
  lastActivity: PropTypes.string.isRequired,
};

MeetingDealsSection.propTypes = {
  deals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      deal_name: PropTypes.string.isRequired,
    })
  ).isRequired,
  onViewDetails: PropTypes.func.isRequired,
};

export default MeetingDetail;