import { useState, useEffect, useCallback, useMemo } from "react";
import axios from "axios";
import { useAuthData } from "../../../auth-context";
import { DateTime } from "luxon";
import { GetUserTimezone } from "../Meetings/utils";

const useFetchMeetings = (
  accountID,
  userID,
  selectedSegment = "schedule",
  currentPage = 1,
  searchQuery = "",
  dynamicFilters = {}
) => {
  const { getAccessToken } = useAuthData();
  const [meetings, setMeetings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  // Transform meetings data
  const transformMeetingsData = useCallback((events) => {
    if (!events) return []; // Add null check for events
    const userTimezone = GetUserTimezone();
    return events.map((event) => {
      const eventTimezone = event.startTimezone || "UTC";
      const startTime = DateTime.fromISO(event.startTime, {
        zone: eventTimezone,
      });
      const endTime = DateTime.fromISO(event.endTime, { zone: eventTimezone });

      const userStartTime = startTime.setZone(userTimezone);
      const userEndTime = endTime.setZone(userTimezone);

      const attendees =
        event.attendees?.map((attendee) => {
          const parts = attendee.split(", ");
          const email =
            parts
              .find((p) => p.startsWith("Email: "))
              ?.replace("Email: ", "") || "";
          const name =
            parts.find((p) => p.startsWith("Name: "))?.replace("Name: ", "") ||
            "";
          const response =
            parts
              .find((p) => p.startsWith("Response: "))
              ?.replace("Response: ", "") || "needsAction";
          return {
            name: name || email.split("@")[0],
            email,
            response: response.toLowerCase(),
          };
        }) || [];

      return {
        id: event.id,
        title: event.summary || "Untitled Meeting",
        description: event.description || "",
        startTime: userStartTime.toISO(),
        endTime: userEndTime.toISO(),
        location: event.location || "",
        hangoutLink: event.hangoutLink || "",
        attendees: attendees,
        status: event.status || "confirmed",
        timezone: eventTimezone,
        recorded: event.isRecorded || false,
        recordingUrl: event.recordingUrl || null,
      };
    });
  }, []);

  const fetchMeetingsPage = useCallback(
    async () => {
      if (!accountID || !userID) {
        return null;
      }

      try {
        const accessToken = await getAccessToken();
        const baseUrl =
          process.env.REACT_APP_NODE_ENV === "local"
            ? "https://lysto-staging-eyf9ejcwf8h3awbw.eastus2-01.azurewebsites.net"
            : "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

        // Map new segment values to API parameters
        const timeRange = selectedSegment === "schedule" ? "future" : "past";
        const includeRecorded = selectedSegment === "recordings";

        const params = {
          page_number: 1, // Always fetch first page with more items
          start_time: new Date().toISOString(),
          items_per_page: 100, // Fetch more items to handle client-side filtering
          status: "confirmed",
          time_range: timeRange,
          include_recorded: includeRecorded,
          recordings_only: includeRecorded,
        };

        // Only add search parameter if there's a non-empty search query
        const trimmedQuery = String(searchQuery || "").trim();
        if (trimmedQuery !== "") {
          params.search = trimmedQuery;
        }

        // Add dynamic filters to params
        if (Object.keys(dynamicFilters).length > 0) {
          const formattedFilters = {};
          const mapFilterValue = (filter) => filter.values.map(v => String(v));

          Object.entries(dynamicFilters).forEach(([column, filter]) => {
            if (!filter) return;

            if (column === 'meetingType') {
              return;
            } else if (column === 'startTime') {
              if (filter.startDate || filter.endDate) {
                formattedFilters.dateRange = {
                  startDate: filter.startDate,
                  endDate: filter.endDate
                };
              }
            } else if (column === 'recorded') {
              formattedFilters.recorded = mapFilterValue(filter);
            } else {
              formattedFilters[column] = mapFilterValue(filter);
            }
          });

          params.dynamic_filters = JSON.stringify(formattedFilters);
        }

        const response = await axios.get(
          `${baseUrl}/accounts/${accountID}/users/${userID}/calendar/events`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
            params,
          }
        );

        const data = response.data;
        const transformedMeetings = transformMeetingsData(data?.items || []);

        // Apply client-side filtering for meetingType
        let filteredMeetings = transformedMeetings;
        if (dynamicFilters.meetingType?.values?.length > 0) {
          filteredMeetings = filteredMeetings.filter(meeting => {
            const isZoom = meeting.location?.toLowerCase().includes('zoom');
            const isGoogleMeet = !!meeting.hangoutLink;
            const isInPerson = meeting.location && !isZoom && !isGoogleMeet;
            
            return dynamicFilters.meetingType.values.some(value => {
              switch(value) {
                case 'zoom': return isZoom;
                case 'meet': return isGoogleMeet;
                case 'inPerson': return isInPerson;
                default: return false;
              }
            });
          });
        }

        setMeetings(filteredMeetings);
        setTotalCount(filteredMeetings.length);
        setHasMore(currentPage * 10 < filteredMeetings.length);
        setError(null);
        return data;
      } catch (err) {
        console.error("Error fetching meetings:", err);
        setError("Failed to load meetings");
        setMeetings([]);
        setHasMore(false);
        setTotalCount(0);
        throw err;
      }
    },
    [accountID, userID, getAccessToken, selectedSegment, searchQuery, transformMeetingsData, dynamicFilters]
  );

  useEffect(() => {
    const loadMeetings = async () => {
      if (!accountID || !userID) {
        setLoading(false);
        setMeetings([]);
        return;
      }

      try {
        setLoading(true);
        await fetchMeetingsPage();
      } finally {
        setLoading(false);
      }
    };

    loadMeetings();
  }, [
    accountID,
    userID,
    selectedSegment,
    fetchMeetingsPage,
  ]);

  // Get paginated meetings
  const paginatedMeetings = useMemo(() => {
    const startIndex = (currentPage - 1) * 10;
    const endIndex = startIndex + 10;
    return meetings.slice(startIndex, endIndex);
  }, [meetings, currentPage]);

  return { 
    meetings: paginatedMeetings,
    allMeetings: meetings,
    loading, 
    error, 
    hasMore, 
    totalCount,
    refetch: fetchMeetingsPage 
  };
};

export default useFetchMeetings;
