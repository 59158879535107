import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

const AutoLogin = () => {
  const { isAuthenticated, loginWithRedirect, isLoading, logout } = useAuth0();
  const [error, setError] = useState(null);

  useEffect(() => {
    // Check if Auth0 returned an error related to organization membership
    const params = new URLSearchParams(window.location.search);
    const errorParam = params.get("error");
    const errorDescription = params.get("error_description");
    if (
      errorParam &&
      errorDescription &&
      errorDescription.includes("client requires organization membership")
    ) {
      setError(
        "Your account is not part of any organization. Please contact support or your admin."
      );
      return;
    }

    if (isLoading) {
      return;
    }
    if (isAuthenticated) {
      // If authenticated, redirect to /#/admin
      window.location.hash = "admin";
    } else {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  // Set up automatic logout if an error is detected
  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        logout({ returnTo: window.location.origin });
      }, 8000);
      return () => clearTimeout(timer);
    }
  }, [error, logout]);

  if (error) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          backgroundColor: "#f2f2f2",
          padding: "20px",
          fontSize: "1.5rem",
        }}
      >
        <h2>Login Issue</h2>
        <p>{error}</p>
        <p>
          You will be logged out in a few seconds. Please try logging in again
          or contact support if the issue persists.
        </p>
      </div>
    );
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return <div>Redirecting to login...</div>;
};

export default AutoLogin;
