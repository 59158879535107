import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  VStack,
  Text,
  Flex,
  ButtonGroup,
  Button,
  Circle,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Link,
  useToast,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { OverviewTag } from '../Shared/Tags';
import { FaCalendar, FaCog, FaUser, FaUsers } from 'react-icons/fa';
import { useAuthData } from 'auth-context';
import axios from 'axios';

const ConnectionCard = ({ connection, type }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isResubscribing, setIsResubscribing] = useState(false);
  const { getAccessToken, account_ID: accountID } = useAuthData();
  const toast = useToast();
  const apiUrl = process.env.REACT_APP_API_URL || "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

  const getFormattedDate = (timestamp) => {
    try {
      if (!timestamp) return 'No date';
      
      // Handle ISO string format
      const date = new Date(timestamp);
      if (isNaN(date.getTime())) return 'Invalid date';
      return format(date, 'MMM d, yyyy');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid date';
    }
  };

  const isActive = type === 'gmail' 
    ? new Date((connection.expiration > 9999999999 
        ? Math.floor(connection.expiration / 1000) 
        : connection.expiration) * 1000) > new Date()
    : new Date(connection.expires_at) > new Date();

  const handleResubscribe = async () => {
    try {
      setIsResubscribing(true);
      const accessToken = await getAccessToken();
      const endpoint = type === 'gmail' 
        ? `/accounts/${accountID}/users/${connection.user_id}/gmail/subscribe`
        : `/accounts/${accountID}/users/${connection.user_id}/calendar/subscribe`;

      await axios.post(
        `${apiUrl}${endpoint}?email=${encodeURIComponent(connection.email)}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      toast({
        title: "Resubscription Successful",
        description: `Your ${type === 'gmail' ? 'Gmail' : 'Calendar'} integration has been resubscribed.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      setIsModalOpen(false);
    } catch (error) {
      console.error('Error resubscribing:', error);
      toast({
        title: "Resubscription Failed",
        description: error.response?.data?.error || `Failed to resubscribe to ${type === 'gmail' ? 'Gmail' : 'Calendar'}. Please try again or contact support.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsResubscribing(false);
    }
  };

  const getStatusDetails = (isActive) => {
    if (isActive) {
      return { 
        icon: FaCalendar,
        label: "Status",
        value: "Active",
        colorScheme: "green"
      };
    }
    return { 
      icon: FaCalendar,
      label: "Status",
      value: "Inactive",
      colorScheme: "red"
    };
  };

  const getPermissionDetails = (permissionType) => {
    if (permissionType === 'shared') {
      return {
        icon: FaUsers,
        label: "Permission",
        value: "Shared",
        colorScheme: "blue"
      };
    }
    return {
      icon: FaUser,
      label: "Permission",
      value: "Personal",
      colorScheme: "teal"
    };
  };

  const statusDetails = getStatusDetails(isActive);
  const permissionDetails = getPermissionDetails(connection.permission_type);
  const createdAt = getFormattedDate(connection.created_at);

  return (
    <>
      <Box
        bg="white"
        p={6}
        borderRadius="md"
        boxShadow="sm"
        borderWidth="1px"
        borderColor="gray.200"
        _hover={{
          borderColor: "gray.300",
          boxShadow: "md",
        }}
        transition="all 0.2s"
        height="100%"
        display="flex"
        flexDirection="column"
      >
        <VStack spacing={3} align="stretch" flex={1}>
          {/* Title Bar */}
          <Flex 
            justify="space-between" 
            align="flex-start" 
            mb={1} 
            flexWrap={{ base: "wrap", sm: "nowrap" }}
            gap={3}
          >
            <Flex align="center" gap={4} flex="1" minW="0" maxW="calc(100% - 100px)">
              <Circle
                size="56px"
                bg="blue.50"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexShrink={0}
              >
                <Image
                  src={type === 'gmail' 
                    ? 'https://www.gstatic.com/images/branding/product/2x/gmail_2020q4_32dp.png'
                    : 'https://www.gstatic.com/images/branding/product/2x/calendar_2020q4_32dp.png'
                  }
                  alt={type === 'gmail' ? 'Gmail' : 'Google Calendar'}
                  width="32px"
                  height="32px"
                />
              </Circle>
              <VStack align="start" spacing={0} minW="0" flex="1">
                <Text fontSize="lg" fontWeight="semibold" color="gray.700" noOfLines={1} width="100%">
                  {connection.email}
                </Text>
                <Text fontSize="sm" color="gray.600">
                  {type === 'gmail' ? 'Gmail' : 'Google Calendar'}
                </Text>
              </VStack>
            </Flex>
            <Box flexShrink={0} ml="auto">
              <ButtonGroup size="sm" spacing={2}>
                <Button
                  leftIcon={<FaCog />}
                  variant="outline"
                  colorScheme="gray"
                  size="sm"
                  onClick={() => setIsModalOpen(true)}
                >
                  Edit
                </Button>
              </ButtonGroup>
            </Box>
          </Flex>

          {/* Overview Tags */}
          <Flex gap={3} flexWrap="wrap">
            <OverviewTag
              icon={statusDetails.icon}
              label={statusDetails.label}
              value={statusDetails.value}
              colorScheme={statusDetails.colorScheme}
            />
            <OverviewTag
              icon={permissionDetails.icon}
              label={permissionDetails.label}
              value={permissionDetails.value}
              colorScheme={permissionDetails.colorScheme}
            />
            <OverviewTag
              icon={FaCalendar}
              label="Created"
              value={createdAt}
              colorScheme="purple"
            />
          </Flex>
        </VStack>
      </Box>

      {/* Edit Modal */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Integration</ModalHeader>
          <ModalBody>
            <VStack spacing={4} align="stretch">
              <Text>
                You can resubscribe to this integration to refresh its access. If you experience any issues, please{' '}
                <Link
                  href="mailto:support@lysto.ai"
                  color="blue.500"
                  textDecoration="underline"
                >
                  email our support team
                </Link>
                .
              </Text>
              <Button
                colorScheme="blue"
                onClick={handleResubscribe}
                isLoading={isResubscribing}
                loadingText="Resubscribing..."
              >
                Resubscribe
              </Button>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setIsModalOpen(false)}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

ConnectionCard.propTypes = {
  connection: PropTypes.shape({
    email: PropTypes.string.isRequired,
    expiration: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    expires_at: PropTypes.string,
    status: PropTypes.string,
    permission_type: PropTypes.oneOf(['personal', 'shared']).isRequired,
    created_at: PropTypes.string.isRequired,
    user_id: PropTypes.number.isRequired,
  }).isRequired,
  type: PropTypes.oneOf(['gmail', 'calendar']).isRequired,
};

export default ConnectionCard; 