// src/components/Shared/GoBackHeader.jsx

import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Flex,
  Heading,
  useColorModeValue,
  Box,
  Button,
  Icon,
} from "@chakra-ui/react";
import { ChevronLeft } from "lucide-react";
import { Edit2 } from "lucide-react";
import EditDealModal from "../Deals/EditDealModal";

/**
 * GoBackHeader Component
 * Renders a header with a back button and a title.
 *
 * @param {Object} props - Component props
 * @param {Function} props.onGoBack - Function to call when the back button is clicked
 * @param {string} props.title - The title to display next to the back button
 * @param {Object} [props.deal] - Optional deal object for editing
 * @param {Object} [props.contact] - Optional contact object for editing
 * @param {Object} [props.account] - Optional account object for editing
 * @param {Function} [props.onEdit] - Optional function to call when edit button is clicked (for contacts and accounts)
 * @returns {JSX.Element}
 */
const GoBackHeader = ({ onGoBack, title, deal, contact, account, onEdit }) => {
  const textColor = useColorModeValue("#56595E", "white");
  const buttonBg = useColorModeValue("white", "gray.700");
  const buttonHoverBg = useColorModeValue("gray.50", "gray.600");
  const buttonBorderColor = useColorModeValue("gray.300", "gray.500");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleOpenEditModal = () => setIsEditModalOpen(true);
  const handleCloseEditModal = () => setIsEditModalOpen(false);

  const getEditButtonText = () => {
    if (contact) return 'Edit Contact';
    if (account) return 'Edit Account';
    return 'Edit Deal';
  };

  return (
    <>
      <Flex align="center" mb={4} justify="space-between">
        <Flex align="center">
          <Box
            as="button"
            onClick={onGoBack}
            borderRadius="md"
            w="32px"
            h="32px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            mr={3}
            bg={buttonBg}
            color="gray.600"
            borderWidth="1px"
            borderColor={buttonBorderColor}
            _hover={{ 
              bg: buttonHoverBg,
            }}
            transition="all 0.2s"
          >
            <ChevronLeft size={20} />
          </Box>
          <Heading as="h1" size="md" color={textColor} fontWeight="600">
            {title}
          </Heading>
        </Flex>
        
        {(deal || contact || account) && (
          <Button
            leftIcon={<Icon as={Edit2} size={16} />}
            variant="outline"
            bg={buttonBg}
            borderRadius="md"
            borderColor={buttonBorderColor}
            color={textColor}
            _hover={{
              bg: buttonHoverBg,
            }}
            onClick={(contact || account) ? onEdit : handleOpenEditModal}
          >
            {getEditButtonText()}
          </Button>
        )}
      </Flex>

      {deal && (
        <EditDealModal
          isOpen={isEditModalOpen}
          onClose={handleCloseEditModal}
          deal={deal}
        />
      )}
    </>
  );
};

// Define PropTypes for type checking
GoBackHeader.propTypes = {
  onGoBack: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  deal: PropTypes.object,
  contact: PropTypes.object,
  account: PropTypes.object,
  onEdit: PropTypes.func,
};

export default GoBackHeader;
