// contexts/SidebarContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { useAuthData } from "../auth-context";
import { useFetchVisibleRoutes } from "../views/lysto/hooks/useFetchVisibleRoutes";

const STORAGE_KEY = 'lysto-sidebar-expanded';

export const SidebarContext = createContext();

const getSavedState = () => {
  try {
    const savedState = localStorage.getItem(STORAGE_KEY);
    console.log('Reading initial state from localStorage:', savedState);
    // If no saved state, default to expanded
    return savedState !== null ? JSON.parse(savedState) : true;
  } catch (error) {
    console.error('Error reading sidebar state from localStorage:', error);
    return true;
  }
};

const saveState = (state) => {
  try {
    // Only save state if it's different from what's already saved
    const currentSaved = localStorage.getItem(STORAGE_KEY);
    if (currentSaved === null || JSON.parse(currentSaved) !== state) {
      console.log('Saving state to localStorage:', state);
      localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
    }
  } catch (error) {
    console.error('Error saving sidebar state to localStorage:', error);
  }
};

export const SidebarProvider = ({ children }) => {
  const { account_ID: accountID } = useAuthData();
  const {
    visibleRoutes,
    loading,
    error,
    fetchVisibleRoutes
  } = useFetchVisibleRoutes();

  const [isExpanded, setIsExpanded] = useState(() => {
    const initialState = getSavedState();
    console.log('Setting initial state:', initialState);
    return initialState;
  });

  const handleSetIsExpanded = (newState) => {
    const value = typeof newState === 'function' ? newState(isExpanded) : newState;
    console.log('handleSetIsExpanded called with:', value);
    setIsExpanded(value);
    saveState(value);
  };

  // Initialize visibleRoutes by fetching from the backend
  useEffect(() => {
    if (accountID) {
      fetchVisibleRoutes();
    }
  }, [accountID]);

  return (
    <SidebarContext.Provider
      value={{
        visibleRoutes,
        loading,
        error,
        isExpanded,
        setIsExpanded: handleSetIsExpanded
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

SidebarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useSidebar = () => {
  const context = useContext(SidebarContext);
  if (context === undefined) {
    throw new Error('useSidebar must be used within a SidebarProvider');
  }
  return context;
};
