import { useState, useEffect } from "react";
import axios from "axios";
import { useAuthData } from "auth-context";
import { useAuth0 } from "@auth0/auth0-react";

/**
 * Custom hook to fetch account based on the account ID.
 * @param {string} accountID - The account ID to fetch account for.
 * @param {string} crmAccountId - The CRM account ID to fetch.
 * @returns {Object} - Contains account data, loading state, and error state.
 */
export function useFetchAccountById(accountID, crmAccountId) {
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessToken } = useAuthData();
  const { user } = useAuth0();

  useEffect(() => {
    const fetchAccount = async () => {
      // Reset states at the start of each fetch attempt
      setLoading(true);
      setError(null);

      if (!accountID || !crmAccountId || !user?.sub) {
        return; // Just return without setting error, maintaining loading state
      }

      try {
        const accessToken = await getAccessToken();

        const target =
          process.env.REACT_APP_API_BASE_URL ||
          "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

        // Updated endpoint to include user_id from Auth0
        const response = await axios.get(
          `${target}/accounts/${accountID}/users/${user.sub}/crm-accounts/${crmAccountId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (!response.data) {
          throw new Error(`Account with ID ${crmAccountId} not found`);
        }

        setAccount(response.data);
        setError(null);
      } catch (error) {
        console.error("Error fetching account:", error);
        setAccount(null);
        setError(error.response?.data?.message || error.message || "Failed to fetch account details");
      } finally {
        setLoading(false);
      }
    };

    fetchAccount();
  }, [accountID, crmAccountId, user?.sub, getAccessToken]);

  return { data: account, loading, error };
}

export default useFetchAccountById;