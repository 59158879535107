// CallDisplay.jsx
import React, { useMemo } from 'react';
import {
  Box,
  VStack,
  Text,
  useColorModeValue,
  Divider,
  HStack,
  Icon,
  Flex,
} from '@chakra-ui/react';
import { FaUser } from 'react-icons/fa';
import { ActivityPropType } from './types';

const SPEAKER_COLORS = [
  { color: 'blue', hover: 'blue.50' },
  { color: 'green', hover: 'green.50' },
  { color: 'purple', hover: 'purple.50' },
  { color: 'orange', hover: 'orange.50' },
  { color: 'cyan', hover: 'cyan.50' },
  { color: 'pink', hover: 'pink.50' },
];

const CallDisplay = ({ activity }) => {
  const callData = activity?.activity_data?.data || {};
  const { summary, transcript_diarization } = callData;

  const bgColor = useColorModeValue('gray.50', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const labelColor = useColorModeValue('gray.600', 'gray.400');
  const messageBgColor = useColorModeValue('white', 'gray.800');
  const messageBorderColor = useColorModeValue('gray.200', 'gray.600');

  // Parse transcript on render
  const parsedTranscript = useMemo(() => {
    if (!transcript_diarization) return [];

    const lines = transcript_diarization.split('\n').map(line => line.trim()).filter(line => line);
    const parsed = [];

    for (const line of lines) {
      // Check if this line starts with a timestamp (format: "HH:MM:SS - Speaker Name")
      const headerMatch = line.match(/^\s*(\d{2}:\d{2}:\d{2})\s*-\s*([^(]+)(?:\(([^)]+)\))?$/);
      
      if (headerMatch) {
        const [, timestamp, speaker] = headerMatch;
        const nextLine = lines[lines.indexOf(line) + 1];
        
        if (nextLine && !nextLine.match(/^\s*\d{2}:\d{2}:\d{2}/)) {
          parsed.push({
            id: parsed.length,
            timestamp,
            speaker: speaker.trim(),
            text: nextLine.trim(),
          });
        }
      }
    }
    
    return parsed;
  }, [transcript_diarization]);

  // Create a mapping of speakers to colors
  const speakerColors = useMemo(() => {
    const uniqueSpeakers = [...new Set(parsedTranscript.map(entry => entry.speaker))];
    return Object.fromEntries(
      uniqueSpeakers.map((speaker, idx) => [
        speaker,
        SPEAKER_COLORS[idx % SPEAKER_COLORS.length]
      ])
    );
  }, [parsedTranscript]);

  return (
    <Box
      p={4}
      bg={bgColor}
      borderRadius="md"
      borderWidth="1px"
      borderColor={borderColor}
    >
      <VStack align="stretch" spacing={3}>
        {/* Caller */}
        <Box>
          <Text>
            <Text as="span" fontWeight="semibold" color={labelColor}>
              From:
            </Text>{' '}
            {callData.caller_name || callData.caller_email || 'Unknown Caller'}
          </Text>
        </Box>

        {/* Summary */}
        {summary && (
          <Box>
            <Text fontWeight="semibold" color={labelColor} mb={2}>
              Summary:
            </Text>
            <Text>{summary}</Text>
          </Box>
        )}

        {/* Transcript */}
        {parsedTranscript.length > 0 ? (
          <>
            <Divider />
            <Box>
              <Text fontSize="md" fontWeight="semibold" color={labelColor} mb={4}>
                Meeting Transcript
              </Text>
              <Box 
                maxHeight="600px" 
                overflowY="auto"
                borderWidth="1px"
                borderColor={messageBorderColor}
                borderRadius="md"
                p={4}
              >
                <VStack spacing={3} align="stretch">
                  {parsedTranscript.map((message) => {
                    const speakerColor = speakerColors[message.speaker] || SPEAKER_COLORS[0];
                    
                    return (
                      <Box 
                        key={message.id}
                        width="100%"
                      >
                        <Box
                          p={3}
                          bg={messageBgColor}
                          boxShadow="sm"
                          borderRadius="md"
                          borderWidth="1px"
                          borderColor={messageBorderColor}
                          transition="all 0.2s"
                          _hover={{
                            borderColor: `${speakerColor.color}.300`,
                            bg: speakerColor.hover,
                          }}
                        >
                          <HStack spacing={4} width="100%" align="flex-start">
                            <Flex
                              align="center"
                              justify="center"
                              boxSize="24px"
                              borderRadius="full"
                              bg={`${speakerColor.color}.100`}
                              color={`${speakerColor.color}.700`}
                            >
                              <Icon as={FaUser} boxSize={3} />
                            </Flex>
                            
                            <VStack align="start" flex="1" spacing={1}>
                              <HStack spacing={2} width="100%" justify="space-between">
                                <Text 
                                  fontWeight="medium" 
                                  fontSize="sm" 
                                  color={`${speakerColor.color}.700`}
                                >
                                  {message.speaker}
                                </Text>
                                <Text fontSize="xs" color="gray.500">
                                  {message.timestamp}
                                </Text>
                              </HStack>
                              <Box
                                fontSize="sm"
                                color="gray.700"
                                width="100%"
                              >
                                <Text>{message.text}</Text>
                              </Box>
                            </VStack>
                          </HStack>
                        </Box>
                      </Box>
                    );
                  })}
                </VStack>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Divider />
            <Box>
              <Text fontWeight="semibold" color={labelColor} mb={2}>
                Transcript:
              </Text>
              <Text color="gray.500">No transcript available.</Text>
            </Box>
          </>
        )}
      </VStack>
    </Box>
  );
};

CallDisplay.propTypes = {
  activity: ActivityPropType,
};

export default CallDisplay;