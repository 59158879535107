import { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

export const useFetchMeetingDeals = (accountId, dealId) => {
  const { getAccessToken } = useAuth0();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!dealId) {
        setIsLoading(false);
        return;
      }

      try {
        const accessToken = await getAccessToken();
        const baseUrl =
          process.env.REACT_APP_NODE_ENV === "local"
            ? "https://lysto-staging-eyf9ejcwf8h3awbw.eastus2-01.azurewebsites.net"
            : "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

        const response = await axios.get(
          `${baseUrl}/accounts/${accountId}/deals/${dealId}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        setData(response.data ? [response.data] : []);
      } catch (error) {
        console.error("Error fetching deals:", error);
        setError("Failed to load deal information");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [accountId, dealId, getAccessToken]);

  return { data, isLoading, error };
}; 