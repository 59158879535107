import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  HStack,
  Text,
  Progress,
  useToast,
  Box,
  Collapse,
  Spinner,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Icon,
  List,
  ListItem,
  Alert,
  AlertIcon,
  Spacer
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon, SearchIcon } from '@chakra-ui/icons';
import { FaPlus, FaBuilding, FaHandshake } from 'react-icons/fa';
import useCreateDealFromSpark from '../hooks/useCreateDealFromSpark';
import { useAuthData } from 'auth-context';
import useFetchAccounts from '../hooks/useFetchAccounts';
import { useDebounce } from 'use-debounce';
import useFetchDeals from '../hooks/useFetchDeals';

const ServiceButton = ({ icon, title, description, isSelected, onClick, color }) => (
  <Button
    height="auto"
    minH="120px"  
    width="100%"
    onClick={onClick}
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    gap={3}
    py={5}
    px={4}
    bg={isSelected ? `${color}.50` : "white"}
    border="1px solid"
    borderColor={isSelected ? `${color}.200` : "gray.200"}
    _hover={{
      bg: isSelected ? `${color}.50` : "gray.50",
      borderColor: isSelected ? `${color}.300` : "gray.300"
    }}
    transition="all 0.2s"
    rounded="lg"
    whiteSpace="normal"
  >
    <Icon as={icon} boxSize={5} color={isSelected ? `${color}.500` : "gray.400"} flexShrink={0} />
    <Box textAlign="center" width="100%">
      <Text
        color={isSelected ? `${color}.700` : "gray.700"}
        fontWeight="medium"
        fontSize="sm"
        mb={2}
      >
        {title}
      </Text>
      <Text
        color={isSelected ? `${color}.600` : "gray.500"}
        fontSize="xs"
        lineHeight="1.4"
        whiteSpace="normal"
        wordBreak="break-word"
      >
        {description}
      </Text>
    </Box>
  </Button>
);

ServiceButton.propTypes = {
  icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired
};

const AddDealModal = ({ isOpen, onClose, onSubmit, data, isLoading: isLoadingProp }) => {
  const searchContainerRef = useRef(null);
  const dealSearchContainerRef = useRef(null);
  const accountSuggestionRef = useRef(null);
  const dealSuggestionRef = useRef(null);
  const userData = useAuthData();
  const createDealMutation = useCreateDealFromSpark();
  const isLoading = isLoadingProp || createDealMutation.isLoading;
  const toast = useToast();

  // New multi-step states
  const [modalStep, setModalStep] = useState(0); // 0: Account Options, 1: Deal Options, 2: Contact Info
  const [accountMode, setAccountMode] = useState(null); // 'new' or 'existing'
  const [dealMode, setDealMode] = useState(null); // 'new' or 'existing'
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [selectedDealId, setSelectedDealId] = useState(null);

  // For toggling additional fields
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);

  // Account search state
  const [accountSearch, setAccountSearch] = useState('');
  const [debouncedAccountSearch] = useDebounce(accountSearch, 300);
  const [showAccountSuggestions, setShowAccountSuggestions] = useState(false);
  const { accounts, isLoading: isLoadingAccounts } = useFetchAccounts(userData.account_ID, 'Lead');
  const filteredAccounts = useMemo(() => {
    if (!accounts) return [];
    if (!debouncedAccountSearch) return accounts;
    return accounts.filter(account => 
      account.name?.toLowerCase().includes(debouncedAccountSearch.toLowerCase()) ||
      account.website_root?.toLowerCase().includes(debouncedAccountSearch.toLowerCase())
    );
  }, [accounts, debouncedAccountSearch]);

  // Deal search state
  const [dealSearch, setDealSearch] = useState('');
  const [debouncedDealSearch] = useDebounce(dealSearch, 300);
  const [showDealSuggestions, setShowDealSuggestions] = useState(false);
  const { deals: existingDeals, isLoading: isLoadingDeals } = useFetchDeals(userData.account_ID);
  const filteredDeals = useMemo(() => {
    if (!existingDeals) return [];
    let dealsForAccount = existingDeals;
    if (accountMode === 'existing' && selectedAccountId) {
      dealsForAccount = existingDeals.filter(deal => deal.crm_account_id === selectedAccountId);
    }
    if (!debouncedDealSearch) return dealsForAccount;
    return dealsForAccount.filter(deal => 
      deal.name?.toLowerCase().includes(debouncedDealSearch.toLowerCase()) ||
      deal.stage_name?.toLowerCase().includes(debouncedDealSearch.toLowerCase())
    );
  }, [existingDeals, debouncedDealSearch, accountMode, selectedAccountId]);

  // Form data holds fields for account, deal, and contacts
  const [formData, setFormData] = useState({
    accountName: '',
    accountDomain: '',
    accountEmail: '',
    accountPhone: '',
    accountSize: '',
    accountType: '',
    accountDescription: '',
    industry: '',
    city: '',
    state: '',
    country: '',
    address: '',
    linkedIn: '',
    contacts: [],
    dealName: '',
    dealSource: '',
    stage: '',
    dealDescription: '',
    dealPriority: '',
  });

  // Add new state for contact pagination
  const [currentContactIndex, setCurrentContactIndex] = useState(0);

  // Pre-populate form from spark data
  useEffect(() => {
    if (!data?.spark) return;
    const contacts = getExternalContacts();
    const domainCounts = contacts.reduce((acc, contact) => {
      acc[contact.domain] = (acc[contact.domain] || 0) + 1;
      return acc;
    }, {});

    // Get the most frequent domain
    const mostFrequentDomain = Object.entries(domainCounts)
      .sort(([, a], [, b]) => b - a)[0]?.[0] || '';

    // Check if the most frequent domain is a common email provider
    const isCommonDomain = (domain) => {
      const commonDomains = [
        'gmail.com', 'googlemail.com', 'google.com', 'yahoo.com', 'yahoo.co.uk',
        'yahoo.co.in', 'yahoo.ca', 'yahoo.fr', 'yahoo.de', 'yahoo.it',
        'yahoo.es', 'yahoo.com.br', 'yahoo.com.au', 'hotmail.com', 'hotmail.co.uk',
        'outlook.com', 'outlook.co.uk', 'live.com', 'aol.com', 'icloud.com',
        'me.com', 'msn.com', 'protonmail.com', 'proton.me', 'zoho.com'
      ];
      return commonDomains.includes(domain.toLowerCase());
    };

    // Only use the domain if it's not a common email provider
    const companyDomain = isCommonDomain(mostFrequentDomain) ? '' : mostFrequentDomain;

    const initialContacts = contacts.length > 0 ? 
      contacts.map(contact => {
        const [firstName = '', ...lastNameParts] = (contact.name || '').split(' ');
        return { 
          firstName, 
          lastName: lastNameParts.join(' '), 
          email: contact.email, 
          phone: '',
          title: '',
          type: '',
          city: '',
          state: '',
          country: '',
          linkedIn: '',
          skipped: false 
        };
      }) : 
      [{ firstName: '', lastName: '', email: '', phone: '', title: '', type: '', city: '', state: '', country: '', linkedIn: '', skipped: false }];
    setFormData(prev => ({
      ...prev,
      accountName: data.spark.company_name || '',
      accountDomain: companyDomain,
      contacts: initialContacts,
      dealName: data.spark.company_name || '',
      dealSource: 'Email',
    }));
  }, [data]);

  // When an existing account is selected, update account fields but don't overwrite contacts
  useEffect(() => {
    if (selectedAccountId && accounts) {
      const selectedAccount = accounts.find(account => account.id === selectedAccountId);
      if (selectedAccount) {
        setFormData(prev => ({
          ...prev,
          accountName: selectedAccount.name || '',
          accountDomain: selectedAccount.website_root || '',
          accountEmail: selectedAccount.email || '',
          accountPhone: selectedAccount.phone || '',
          accountSize: selectedAccount.size || '',
          accountType: selectedAccount.type || '',
          accountDescription: selectedAccount.description || '',
          industry: selectedAccount.industry || '',
          city: selectedAccount.city || '',
          state: selectedAccount.state || '',
          country: selectedAccount.country || '',
          address: selectedAccount.address || '',
          linkedIn: selectedAccount.linkedin || '',
        }));
      }
    }
  }, [selectedAccountId, accounts]);

  // Update handleDealSelect to properly handle contacts
  const handleDealSelect = (deal) => {
    setSelectedDealId(deal.id);
    setDealSearch(deal.name);
    setShowDealSuggestions(false);
    if (deal.crm_account_id) {
      setSelectedAccountId(deal.crm_account_id);
      const account = accounts?.find(a => a.id === deal.crm_account_id);
      if (account) {
        // Get existing account contact emails for comparison, excluding "Other" type contacts
        const existingEmails = new Set(
          (account.contacts || [])
            .filter(contact => contact.type !== 'Other')
            .map(contact => contact.email?.toLowerCase())
        );

        // Get spark contacts
        const sparkContacts = getExternalContacts();
        
        // Filter out existing contacts and map to our format
        const newSparkContacts = sparkContacts
          .filter(contact => !existingEmails.has(contact.email?.toLowerCase()))
          .map(contact => ({
            firstName: contact.name?.split(' ')[0] || '',
            lastName: contact.name?.split(' ').slice(1).join(' ') || '',
            email: contact.email || '',
            phone: '',
            title: '',
            type: '',
            city: '',
            state: '',
            country: '',
            linkedIn: '',
            skipped: false
          }));

        // If no new contacts are found, create an empty contact form
        const contactsToUse = newSparkContacts.length > 0 ? newSparkContacts : [{
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          title: '',
          type: '',
          city: '',
          state: '',
          country: '',
          linkedIn: '',
          skipped: false
        }];

        setFormData(prev => ({
          ...prev,
          accountName: account.name || '',
          accountDomain: account.website_root || '',
          accountEmail: account.email || '',
          accountPhone: account.phone || '',
          accountSize: account.size || '',
          accountType: account.type || '',
          accountDescription: account.description || '',
          industry: account.industry || '',
          city: account.city || '',
          state: account.state || '',
          country: account.country || '',
          address: account.address || '',
          linkedIn: account.linkedin || '',
          contacts: contactsToUse,
          dealName: deal.name || '',
          dealSource: deal.lead_source || '',
          stage: deal.stage_name || '',
          dealDescription: deal.description || '',
          dealPriority: deal.priority || '',
        }));

        // Reset contact index when new contacts are loaded
        setCurrentContactIndex(0);
      }
    }
  };

  // Helper functions from original code
  const getExternalContacts = () => {
    if (!data?.spark?.user_domain) return [];
    const userDomain = data.spark.user_domain;
    const contactsMap = new Map();
    const mergeContactInfo = (existingContact, newContact) => ({
        email: existingContact.email,
        name: existingContact.name || newContact.name,
        domain: existingContact.domain,
    });
    if (data.spark.recipients) {
      data.spark.recipients.forEach(recipient => {
        const recipientDomain = recipient.email.split('@')[1];
        // Only exclude the user's own domain
        if (recipientDomain !== userDomain) {
          const contactInfo = { email: recipient.email, name: recipient.name, domain: recipientDomain };
          if (contactsMap.has(recipient.email)) {
            contactsMap.set(recipient.email, mergeContactInfo(contactsMap.get(recipient.email), contactInfo));
          } else {
            contactsMap.set(recipient.email, contactInfo);
          }
        }
      });
    }
    if (data.spark?.activities) {
      data.spark.activities.forEach(activity => {
        if (activity.activity_data?.activity_type === 'email') {
          const senderEmail = activity.activity_data?.data?.sender_email;
          const senderName = activity.activity_data?.data?.sender_name;
          const senderDomain = senderEmail?.split('@')[1];
          if (senderDomain && senderDomain !== userDomain) {
            const contactInfo = { email: senderEmail, name: senderName, domain: senderDomain };
            if (contactsMap.has(senderEmail)) {
              contactsMap.set(senderEmail, mergeContactInfo(contactsMap.get(senderEmail), contactInfo));
            } else {
              contactsMap.set(senderEmail, contactInfo);
            }
          }
          const recipients = activity.activity_data?.data?.recipients || [];
          recipients.forEach(recipient => {
            const recipientDomain = recipient.email.split('@')[1];
            if (recipientDomain && recipientDomain !== userDomain) {
              const contactInfo = { email: recipient.email, name: recipient.name, domain: recipientDomain };
              if (contactsMap.has(recipient.email)) {
                contactsMap.set(recipient.email, mergeContactInfo(contactsMap.get(recipient.email), contactInfo));
              } else {
                contactsMap.set(recipient.email, contactInfo);
              }
            }
          });
        }
      });
    }
    return Array.from(contactsMap.values());
  };

  // Remove or comment out the isCommonEmailProvider function since we're not using it anymore
  // const isCommonEmailProvider = (domain) => { ... };

  // Navigation functions for multi-step modal
  const handleNext = () => {
    if (modalStep === 0) {
      if (!accountMode) {
        toast({ title: 'Select account option', description: 'Please choose new or existing account', status: 'error', duration: 3000 });
        return;
      }
      if (accountMode === 'existing' && !selectedAccountId) {
        toast({ title: 'No account selected', description: 'Please choose an existing account', status: 'error', duration: 3000 });
        return;
      }
      setModalStep(1);
    } else if (modalStep === 1) {
      if (!dealMode) {
        toast({ title: 'Select deal option', description: 'Please choose new or existing deal', status: 'error', duration: 3000 });
        return;
      }
      if (dealMode === 'existing' && !selectedDealId) {
        toast({ title: 'No deal selected', description: 'Please choose an existing deal', status: 'error', duration: 3000 });
        return;
      }
      setModalStep(2);
      setCurrentContactIndex(0); // Reset to first contact when entering contact step
    } else if (modalStep === 2) {
      // Validate current contact if not skipped
      const currentContact = formData.contacts[currentContactIndex];
      if (!currentContact.skipped && (!currentContact.firstName || !currentContact.email)) {
        toast({ 
          title: 'Required fields missing', 
          description: 'Please fill in all required fields for the current contact or skip this contact', 
          status: 'error', 
          duration: 3000 
        });
        return;
      }

      // If there are more contacts to review, go to next contact
      if (currentContactIndex < formData.contacts.length - 1) {
        setCurrentContactIndex(prev => prev + 1);
        return;
      }

      // If we've reviewed all contacts, submit
      handleSubmit();
    }
  };

  const handleBack = () => {
    if (modalStep === 2 && currentContactIndex > 0) {
      setCurrentContactIndex(prev => prev - 1);
    } else if (modalStep > 0) {
      setModalStep(modalStep - 1);
    } else {
      onClose();
    }
  };

  const handleSubmit = async () => {
    if (isLoading) return;
    try {
      const filteredFormData = { ...formData, contacts: formData.contacts.filter(contact => !contact.skipped) };
      await createDealMutation.mutateAsync({
        accountId: userData.account_ID,
        userId: userData.user_ID,
        formData: filteredFormData,
        sparkData: data,
        existingDealId: dealMode === 'existing' ? selectedDealId : null,
        existingAccountId: accountMode === 'existing' ? selectedAccountId : null,
      });

      // Show a single success toast
      toast({
        title: dealMode === 'existing' ? "Deal Updated" : "Deal Created",
        description: dealMode === 'existing' 
          ? "The deal has been successfully updated" 
          : "The deal has been successfully created",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
        containerStyle: {
          width: "400px",
          maxWidth: "100%",
          top: "20px",
          left: "50%",
          transform: "translateX(-50%)",
        },
      });

      if (onSubmit) onSubmit(filteredFormData);
      
      // Reset modal state
      setModalStep(0);
      setCurrentContactIndex(0);
      setAccountMode(null);
      setDealMode(null);
      setSelectedAccountId(null);
      setSelectedDealId(null);
      setFormData({
        accountName: '',
        accountDomain: '',
        accountEmail: '',
        accountPhone: '',
        accountSize: '',
        accountType: '',
        accountDescription: '',
        industry: '',
        city: '',
        state: '',
        country: '',
        address: '',
        linkedIn: '',
        contacts: [],
        dealName: '',
        dealSource: '',
        stage: '',
        dealDescription: '',
        dealPriority: '',
      });
      
      onClose();
    } catch (error) {
      console.error('Submit error:', error);
      toast({ title: 'Error Creating Deal', description: error.message || 'Failed to create deal', status: 'error', duration: 5000 });
    }
  };

  // Render functions for each step
  const renderAccountStep = () => (
    <VStack spacing={6} align="stretch">
      <Text fontSize="sm" color="gray.600">Would you like to create a new account or use an existing one?</Text>
        <SimpleGrid columns={2} spacing={4}>
          <ServiceButton
            icon={FaPlus}
          title="New Account"
          description="Create a new account with spark details"
          isSelected={accountMode === 'new'}
          onClick={() => setAccountMode('new')}
            color="blue"
          />
          <ServiceButton
            icon={FaBuilding}
            title="Existing Account"
          description="Select an existing account"
          isSelected={accountMode === 'existing'}
          onClick={() => setAccountMode('existing')}
            color="purple"
          />
        </SimpleGrid>
      {accountMode === 'new' && renderAccountForm()}
      {accountMode === 'existing' && renderExistingAccountSelection()}
    </VStack>
  );

  const renderExistingAccountSelection = () => (
            <FormControl>
      <FormLabel>Search Accounts</FormLabel>
      <Box position="relative" className="account-search-container" ref={searchContainerRef}>
                <InputGroup>
                  <Input
            value={accountSearch}
            onChange={(e) => { setAccountSearch(e.target.value); setShowAccountSuggestions(true); }}
            placeholder="Search by account name or domain"
                    onFocus={() => {
              setShowAccountSuggestions(true);
                      setTimeout(() => {
                accountSuggestionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
              }, 300);
                    }}
                  />
                  <InputRightElement>
            {isLoadingAccounts ? <Spinner size="sm" color="gray.400" /> : <SearchIcon color="gray.500" />}
                  </InputRightElement>
                </InputGroup>
        {showAccountSuggestions && (
                  <List
            ref={accountSuggestionRef}
                    position="absolute"
                    top="100%"
                    left={0}
                    right={0}
                    bg="white"
                    boxShadow="lg"
                    borderRadius="md"
                    maxH="200px"
                    overflowY="auto"
                    zIndex={2000}
                    border="1px solid"
                    borderColor="gray.200"
                    mt={1}
                  >
            {isLoadingAccounts ? (
              <ListItem p={4} textAlign="center"><Spinner size="sm" /></ListItem>
            ) : filteredAccounts.length === 0 ? (
              <ListItem p={4} color="gray.500">{accountSearch ? "No accounts found" : "Type to search accounts"}</ListItem>
            ) : (
              filteredAccounts.map(account => (
                        <ListItem
                  key={account.id}
                          p={3}
                          cursor="pointer"
                          _hover={{ bg: 'gray.50' }}
                  onClick={() => { setSelectedAccountId(account.id); setAccountSearch(account.name); setShowAccountSuggestions(false); }}
                          transition="background-color 0.2s"
                        >
                  <Text fontWeight="medium">{account.name}</Text>
                  {account.website_root && <Text fontSize="sm" color="gray.500">{account.website_root}</Text>}
                        </ListItem>
                      ))
                    )}
                  </List>
                )}
              </Box>
            </FormControl>
  );

  const renderAccountForm = () => (
    <VStack spacing={4} align="stretch">
      <Text fontSize="sm" color="gray.600" mb={2}>Enter or update the company details.</Text>
      <FormControl isRequired>
        <FormLabel>Company Name</FormLabel>
        <Input name="accountName" value={formData.accountName} onChange={(e) => setFormData(prev => ({ ...prev, accountName: e.target.value }))} placeholder="Enter company name" isDisabled={isLoading} />
      </FormControl>
            <FormControl>
        <FormLabel>Company Domain</FormLabel>
        <Input name="accountDomain" value={formData.accountDomain} onChange={(e) => setFormData(prev => ({ ...prev, accountDomain: e.target.value }))} placeholder="example.com" isDisabled={isLoading} />
      </FormControl>
      <Button rightIcon={showAdditionalFields ? <ChevronUpIcon /> : <ChevronDownIcon />} onClick={() => setShowAdditionalFields(!showAdditionalFields)} variant="ghost" size="sm" width="fit-content">
        {showAdditionalFields ? 'Hide' : 'Show'} Additional Fields
      </Button>
      <Collapse in={showAdditionalFields}>
        <VStack spacing={4} align="stretch" mt={4}>
          <FormControl>
            <FormLabel>Description</FormLabel>
            <Input name="accountDescription" value={formData.accountDescription} onChange={(e) => setFormData(prev => ({ ...prev, accountDescription: e.target.value }))} placeholder="Brief description" isDisabled={isLoading} />
          </FormControl>
          <FormControl>
            <FormLabel>Industry</FormLabel>
            <Select name="industry" value={formData.industry} onChange={(e) => setFormData(prev => ({ ...prev, industry: e.target.value }))} placeholder="Select industry" isDisabled={isLoading}>
              <option value="technology">Technology</option>
              <option value="healthcare">Healthcare</option>
              <option value="finance">Finance</option>
              <option value="retail">Retail</option>
              <option value="manufacturing">Manufacturing</option>
              <option value="other">Other</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Company Email</FormLabel>
            <Input name="accountEmail" value={formData.accountEmail} onChange={(e) => setFormData(prev => ({ ...prev, accountEmail: e.target.value }))} placeholder="email@example.com" isDisabled={isLoading} />
          </FormControl>
          <FormControl>
            <FormLabel>Company Phone</FormLabel>
            <Input name="accountPhone" value={formData.accountPhone} onChange={(e) => setFormData(prev => ({ ...prev, accountPhone: e.target.value }))} placeholder="Phone number" isDisabled={isLoading} />
          </FormControl>
          <FormControl>
            <FormLabel>Company Size</FormLabel>
            <Input name="accountSize" value={formData.accountSize} onChange={(e) => setFormData(prev => ({ ...prev, accountSize: e.target.value }))} placeholder="Size" isDisabled={isLoading} />
          </FormControl>
          <FormControl>
            <FormLabel>Company Type</FormLabel>
            <Input name="accountType" value={formData.accountType} onChange={(e) => setFormData(prev => ({ ...prev, accountType: e.target.value }))} placeholder="Type" isDisabled={isLoading} />
          </FormControl>
        </VStack>
      </Collapse>
    </VStack>
  );

  const renderDealStep = () => (
    <VStack spacing={6} align="stretch">
      <Text fontSize="sm" color="gray.600">Would you like to create a new deal or use an existing one?</Text>
      <SimpleGrid columns={2} spacing={4}>
        <ServiceButton
          icon={FaPlus}
          title="New Deal"
          description="Create new deal with spark details"
          isSelected={dealMode === 'new'}
          onClick={() => setDealMode('new')}
          color="blue"
        />
        <ServiceButton
          icon={FaHandshake}
          title="Existing Deal"
          description="Select an existing deal"
          isSelected={dealMode === 'existing'}
          onClick={() => setDealMode('existing')}
          color="green"
        />
      </SimpleGrid>
      {dealMode === 'new' && renderDealForm()}
      {dealMode === 'existing' && renderExistingDealSelection()}
    </VStack>
  );

  const renderExistingDealSelection = () => {
    // If there's exactly one deal for the account, show non-interactive deal info
    if (filteredDeals.length === 1) {
      const deal = filteredDeals[0];
      const account = accounts?.find(a => a.id === deal.crm_account_id);
      return (
        <Box p={4} borderWidth="1px" borderRadius="md" bg="gray.50">
          <VStack align="stretch" spacing={4}>
            <HStack spacing={3} align="center">
              <Icon as={FaHandshake} boxSize={5} color="green.500" />
              <Text fontSize="sm" color="gray.600" fontWeight="medium">
                Found existing deal:
              </Text>
            </HStack>
            <VStack align="stretch" spacing={2} pl={8}>
              <Text fontWeight="bold">{deal.name}</Text>
              <Text fontSize="sm" color="gray.600">Stage: {deal.stage_name || 'Not set'}</Text>
              {account && <Text fontSize="sm" color="gray.600">Account: {account.name}</Text>}
            </VStack>
          </VStack>
        </Box>
      );
    }

    // Original search UI for multiple deals
    return (
      <FormControl>
        <FormLabel>Search Deals</FormLabel>
        <Box position="relative" className="deal-search-container" ref={dealSearchContainerRef}>
          <InputGroup>
            <Input
              value={dealSearch}
              onChange={(e) => { setDealSearch(e.target.value); setShowDealSuggestions(true); }}
              placeholder="Search by deal name or stage"
              onFocus={() => {
                setShowDealSuggestions(true);
                setTimeout(() => {
                  dealSuggestionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
                }, 300);
              }}
            />
            <InputRightElement>
              {isLoadingDeals ? <Spinner size="sm" color="gray.400" /> : <SearchIcon color="gray.500" />}
            </InputRightElement>
          </InputGroup>
          {showDealSuggestions && (
            <List
              ref={dealSuggestionRef}
              position="absolute"
              top="100%"
              left={0}
              right={0}
              bg="white"
              boxShadow="lg"
              borderRadius="md"
              maxH="200px"
              overflowY="auto"
              zIndex={2000}
              border="1px solid"
              borderColor="gray.200"
              mt={1}
            >
              {isLoadingDeals ? (
                <ListItem p={4} textAlign="center"><Spinner size="sm" /></ListItem>
              ) : filteredDeals.length === 0 ? (
                <ListItem p={4} color="gray.500">{dealSearch ? "No deals found" : "Type to search deals"}</ListItem>
              ) : (
                filteredDeals.map(deal => (
                  <ListItem
                    key={deal.id}
                    p={3}
                    cursor="pointer"
                    _hover={{ bg: 'gray.50' }}
                    onClick={() => handleDealSelect(deal)}
                    transition="background-color 0.2s"
                  >
                    <Text fontWeight="medium">{deal.name}</Text>
                    <VStack spacing={1} align="start">
                      {deal.stage_name && (<Text fontSize="sm" color="gray.500">Stage: {deal.stage_name}</Text>)}
                      {accounts?.find(a => a.id === deal.crm_account_id)?.name && (<Text fontSize="sm" color="gray.500">Account: {accounts.find(a => a.id === deal.crm_account_id).name}</Text>)}
                    </VStack>
                  </ListItem>
                ))
              )}
            </List>
          )}
        </Box>
      </FormControl>
    );
  };

  const renderDealForm = () => (
    <VStack spacing={4} align="stretch">
      <Text fontSize="sm" color="gray.600" mb={2}>Enter the deal opportunity details.</Text>
      <FormControl isRequired>
        <FormLabel>Deal Name</FormLabel>
        <Input name="dealName" value={formData.dealName} onChange={(e) => setFormData(prev => ({ ...prev, dealName: e.target.value }))} placeholder="Enter deal name" isDisabled={isLoading} />
      </FormControl>
      <FormControl>
        <FormLabel>Deal Source</FormLabel>
        <Select name="dealSource" value={formData.dealSource} onChange={(e) => setFormData(prev => ({ ...prev, dealSource: e.target.value }))} placeholder="Select deal source" isDisabled={isLoading}>
          <option value="Direct Contact">Direct Contact</option>
          <option value="Email">Email</option>
          <option value="Website">Website</option>
          <option value="Referral">Referral</option>
          <option value="Other">Other</option>
        </Select>
      </FormControl>
      <FormControl>
        <FormLabel>Deal Priority</FormLabel>
        <Select name="dealPriority" value={formData.dealPriority} onChange={(e) => setFormData(prev => ({ ...prev, dealPriority: e.target.value }))} placeholder="Select deal priority" isDisabled={isLoading}>
          <option value="PMS">PMS</option>
          <option value="PM">PM</option>
          <option value="PO">PO</option>
          <option value="Adhoc">Ad-hoc</option>
          <option value="Content">Content</option>
        </Select>
      </FormControl>
      <Button rightIcon={showAdditionalFields ? <ChevronUpIcon /> : <ChevronDownIcon />} onClick={() => setShowAdditionalFields(!showAdditionalFields)} variant="ghost" size="sm" width="fit-content">
        {showAdditionalFields ? 'Hide' : 'Show'} Additional Fields
      </Button>
      <Collapse in={showAdditionalFields}>
        <VStack spacing={4} align="stretch" mt={4}>
          <FormControl>
            <FormLabel>Stage</FormLabel>
            <Select name="stage" value={formData.stage} onChange={(e) => setFormData(prev => ({ ...prev, stage: e.target.value }))} placeholder="Select deal stage" isDisabled={isLoading}>
              <option value="new_lead">New Lead</option>
              <option value="qualifying">Qualifying</option>
              <option value="meeting_phase">Meeting Phase</option>
              <option value="negotiating">Negotiating</option>
              <option value="closing">Closing</option>
            </Select>
          </FormControl>
            <FormControl>
            <FormLabel>Description</FormLabel>
            <Input name="dealDescription" value={formData.dealDescription} onChange={(e) => setFormData(prev => ({ ...prev, dealDescription: e.target.value }))} placeholder="Describe the deal" isDisabled={isLoading} />
          </FormControl>
        </VStack>
      </Collapse>
    </VStack>
  );

  const renderContactStep = () => {
    const totalContacts = formData.contacts?.length || 0;
    if (totalContacts === 0) {
      // Create an empty contact form
      const emptyContact = { 
        firstName: '', 
        lastName: '', 
        email: '', 
        phone: '', 
        title: '', 
        type: '', 
        city: '', 
        state: '', 
        country: '', 
        linkedIn: '', 
        skipped: false 
      };
      setFormData(prev => ({
        ...prev,
        contacts: [emptyContact]
      }));
      return renderContactForm(0);
    }

    // Add safety check for currentContactIndex
    if (currentContactIndex >= totalContacts) {
      setCurrentContactIndex(0);
      return null;
    }

    const currentContact = formData.contacts[currentContactIndex];
    if (!currentContact) return null;

    return (
      <VStack spacing={4} align="stretch">
        <HStack justify="space-between" align="center" mb={2}>
          <Text fontSize="md" fontWeight="medium">
            Contact {currentContactIndex + 1} of {totalContacts}
          </Text>
          <Text 
            fontSize="sm" 
            color={currentContact.skipped ? "red.500" : "green.500"}
            fontWeight="medium"
          >
            {currentContact.skipped ? "Skipped" : "Included"}
          </Text>
        </HStack>

        {currentContact.existing && (
          <Alert status="info" borderRadius="md">
            <AlertIcon />
            <Text fontSize="sm">This contact already exists in the CRM</Text>
          </Alert>
        )}

        {renderContactForm(currentContactIndex)}
      </VStack>
    );
  };

  const renderContactForm = (contactIndex) => {
    const contact = formData.contacts[contactIndex] || { 
      firstName: '', 
      lastName: '', 
      email: '', 
      phone: '', 
      title: '', 
      type: '', 
      city: '', 
      state: '', 
      country: '', 
      linkedIn: '', 
      skipped: false 
    };

    return (
      <VStack spacing={4} align="stretch" key={contactIndex}>
        <Text fontSize="sm" color="gray.600">Enter contact information</Text>
        <FormControl isRequired>
          <FormLabel>First Name</FormLabel>
          <Input name="firstName" value={contact.firstName} onChange={(e) => handleInputChange(e, contactIndex)} placeholder="First name" isDisabled={isLoading} />
        </FormControl>
        <FormControl>
          <FormLabel>Last Name</FormLabel>
          <Input name="lastName" value={contact.lastName} onChange={(e) => handleInputChange(e, contactIndex)} placeholder="Last name" isDisabled={isLoading} />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Email</FormLabel>
          <Input name="email" type="email" value={contact.email} onChange={(e) => handleInputChange(e, contactIndex)} placeholder="contact@example.com" isDisabled={isLoading} />
        </FormControl>
        <Button rightIcon={showAdditionalFields ? <ChevronUpIcon /> : <ChevronDownIcon />} onClick={() => setShowAdditionalFields(!showAdditionalFields)} variant="ghost" size="sm" width="fit-content">
          {showAdditionalFields ? 'Hide' : 'Show'} Additional Fields
        </Button>
        <Collapse in={showAdditionalFields}>
          <VStack spacing={4} align="stretch" mt={4}>
            <FormControl>
              <FormLabel>Phone</FormLabel>
              <Input name="phone" value={contact.phone || ''} onChange={(e) => handleInputChange(e, contactIndex)} placeholder="Phone number" isDisabled={isLoading} />
            </FormControl>
            <FormControl>
              <FormLabel>Title</FormLabel>
              <Input name="title" value={contact.title || ''} onChange={(e) => handleInputChange(e, contactIndex)} placeholder="Job title" isDisabled={isLoading} />
            </FormControl>
            <FormControl>
              <FormLabel>Type</FormLabel>
              <Select name="type" value={contact.type || ''} onChange={(e) => handleInputChange(e, contactIndex)} placeholder="Select type" isDisabled={isLoading}>
                <option value="sales">Sales</option>
                <option value="marketing">Marketing</option>
                <option value="engineering">Engineering</option>
                <option value="finance">Finance</option>
                <option value="operations">Operations</option>
                <option value="other">Other</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>LinkedIn Profile</FormLabel>
              <Input name="linkedIn" value={contact.linkedIn || ''} onChange={(e) => handleInputChange(e, contactIndex)} placeholder="LinkedIn profile URL" isDisabled={isLoading} />
            </FormControl>
            <FormControl>
              <FormLabel>City</FormLabel>
              <Input name="city" value={contact.city || ''} onChange={(e) => handleInputChange(e, contactIndex)} placeholder="City" isDisabled={isLoading} />
            </FormControl>
            <FormControl>
              <FormLabel>State</FormLabel>
              <Input name="state" value={contact.state || ''} onChange={(e) => handleInputChange(e, contactIndex)} placeholder="State" isDisabled={isLoading} />
            </FormControl>
            <FormControl>
              <FormLabel>Country</FormLabel>
              <Input name="country" value={contact.country || ''} onChange={(e) => handleInputChange(e, contactIndex)} placeholder="Country" isDisabled={isLoading} />
            </FormControl>
          </VStack>
        </Collapse>
      </VStack>
    );
  };

  const handleInputChange = (e, contactIndex = null) => {
    const { name, value } = e.target;
    if (contactIndex !== null) {
      setFormData(prev => ({
        ...prev,
        contacts: prev.contacts.map((contact, idx) => idx === contactIndex ? { ...contact, [name]: value } : contact),
      }));
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const renderStepContent = () => {
    if (modalStep === 0) return renderAccountStep();
    if (modalStep === 1) return renderDealStep();
    if (modalStep === 2) return renderContactStep();
        return null;
  };

  const getStepTitle = () => {
    if (modalStep === 0) return "Account Options";
    if (modalStep === 1) return "Deal Options";
    if (modalStep === 2) return "Contact Information";
    return "";
  };

  // Modify useEffect for auto-selecting single deal
  useEffect(() => {
    if (
      modalStep === 1 &&
      dealMode === 'existing' &&
      accountMode === 'existing' &&
      selectedAccountId &&
      !debouncedDealSearch &&
      filteredDeals.length === 1
    ) {
      const singleDeal = filteredDeals[0];
      setSelectedDealId(singleDeal.id);
      setDealSearch(singleDeal.name);
      setShowDealSuggestions(false);
      // Do not auto-advance to the next step; let user click Next to confirm
    }
  }, [modalStep, dealMode, accountMode, selectedAccountId, debouncedDealSearch, filteredDeals]);

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose} 
      size="xl"
      closeOnOverlayClick={!isLoading}
      closeOnEsc={!isLoading}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent maxH="90vh" position="relative" overflow="visible">
        {isLoading && (
          <Box
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            bg="whiteAlpha.900"
            zIndex="overlay"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            borderRadius="md"
          >
            <VStack spacing={4}>
              <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
              <Text color="gray.600" fontWeight="medium">Processing...</Text>
            </VStack>
          </Box>
        )}
        <ModalHeader p={0}>
          <Box px={6} pt={6} pb={4}>
            <Text mb={4}>{getStepTitle()}</Text>
            <Progress 
              value={modalStep === 2 
                ? ((modalStep * 33.33) + ((currentContactIndex + 1) / formData.contacts.length) * 33.33)
                : ((modalStep + 1) * 33.33)} 
              size="sm" 
              colorScheme="blue" 
              borderRadius="full" 
              width="100%" 
            />
          </Box>
        </ModalHeader>
        <ModalBody overflowY="auto" position="relative">
          {renderStepContent()}
        </ModalBody>
        <ModalFooter>
          <HStack width="100%" spacing={2}>
            <Button size="md" variant="ghost" onClick={onClose} isDisabled={isLoading}>
              Cancel
            </Button>
            
            {modalStep > 0 && (
              <Button size="md" onClick={handleBack} isDisabled={isLoading}>
                Back
              </Button>
            )}

            <Spacer />
            
            {modalStep === 2 && (
              <Button
                size="md"
                variant={formData.contacts[currentContactIndex]?.skipped ? "solid" : "outline"}
                onClick={() => {
                  const updatedContacts = [...formData.contacts];
                  updatedContacts[currentContactIndex] = {
                    ...updatedContacts[currentContactIndex],
                    skipped: !updatedContacts[currentContactIndex].skipped
                  };
                  setFormData(prev => ({
                    ...prev,
                    contacts: updatedContacts
                  }));
                }}
                colorScheme="red"
                isDisabled={isLoading}
              >
                {formData.contacts[currentContactIndex]?.skipped ? "Include Contact" : "Skip Contact"}
              </Button>
            )}

            <Button 
              size="md"
              colorScheme="blue" 
              onClick={handleNext} 
              isDisabled={
                isLoading ||
                (modalStep === 0 && accountMode === 'existing' && !selectedAccountId) ||
                (modalStep === 1 && dealMode === 'existing' && !selectedDealId)
              }
              isLoading={modalStep === 2 && currentContactIndex === formData.contacts.length - 1 && isLoading}
              loadingText="Creating Deal"
            >
              {modalStep === 2 
                ? (currentContactIndex === formData.contacts.length - 1 
                  ? (dealMode === 'existing' ? 'Update Deal' : 'Create Deal')
                  : 'Next Contact')
                : 'Next'}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

AddDealModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  data: PropTypes.shape({
    crm_account_id: PropTypes.number.isRequired,
    spark: PropTypes.shape({
      user_domain: PropTypes.string.isRequired,
      company_name: PropTypes.string,
      recipients: PropTypes.arrayOf(PropTypes.shape({
        email: PropTypes.string.isRequired,
        name: PropTypes.string,
      })),
      activities: PropTypes.arrayOf(PropTypes.shape({
        activity_data: PropTypes.shape({
          activity_type: PropTypes.string.isRequired,
          data: PropTypes.shape({
            sender_email: PropTypes.string,
            sender_name: PropTypes.string,
            recipients: PropTypes.arrayOf(PropTypes.shape({
              email: PropTypes.string.isRequired,
              name: PropTypes.string,
            })),
          }),
        }),
      })),
    }).isRequired,
  }).isRequired,
  isLoading: PropTypes.bool,
};

export default AddDealModal; 