// src/components/Shared/PageHeader.jsx

import React, { useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  useColorModeValue,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  ButtonGroup,
  Avatar,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  VStack,
  Text,
  Link,
  HStack,
} from "@chakra-ui/react";
import { Search } from "lucide-react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { MdSettings, MdLogout } from "react-icons/md";
import PropTypes from "prop-types";
import { useAuthData } from "auth-context";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

const PageHeader = ({
  title,
  segmentOptions,
  selectedSegment,
  onSegmentChange,
  searchPlaceholder = "Search...",
  onSearchChange,
  searchSuggestions = [],
  searchQuery = "",
  onSuggestionSelect = null,
  showSearch = true,
  additionalControls = null,
  isDetailView = false,
  searchRightElement = null,
}) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const userData = useAuthData();
  const { logout } = useAuth0();
  const navigate = useNavigate();

  // Theme Colors
  const searchBg = useColorModeValue("white", "gray.600");
  const searchBorderColor = useColorModeValue("gray.300", "gray.500");
  const suggestionBg = useColorModeValue("white", "gray.700");
  const suggestionBorderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBg = useColorModeValue("gray.100", "gray.600");
  const highlightBg = "blue.500";
  const highlightColor = "white";
  const popoverBg = useColorModeValue("white", "gray.700");
  const popoverBorderColor = useColorModeValue("gray.200", "gray.600");
  
  // Button group colors
  const selectedBg = useColorModeValue("blue.500", "blue.300");
  const selectedTextColor = useColorModeValue("white", "gray.800");
  const unselectedBg = useColorModeValue("white", "gray.600");
  const unselectedTextColor = useColorModeValue("gray.700", "gray.200");
  const buttonHoverBg = useColorModeValue("gray.200", "gray.500");

  const renderHighlightText = (text, query) => {
    if (!query) return text;
    const escapedQuery = escapeRegExp(query);
    const regex = new RegExp(`(${escapedQuery})`, "gi");
    const parts = text.split(regex);

    return (
      <Box as="span">
        {parts.map((part, index) =>
          regex.test(part) ? (
            <Box
              as="mark"
              bg={highlightBg}
              color={highlightColor}
              key={index}
              px={1}
              borderRadius="sm"
              whiteSpace="nowrap"
              display="inline"
            >
              {part}
            </Box>
          ) : (
            part
          )
        )}
      </Box>
    );
  };

  const handleSuggestionClick = (itemId) => {
    if (onSuggestionSelect) {
      onSuggestionSelect(itemId);
      setShowSuggestions(false);
    }
  };

  const handleBlur = () => {
    // Delay hiding suggestions to allow click events to register
    setTimeout(() => setShowSuggestions(false), 200);
  };

  const handleFocus = () => {
    if (searchQuery && searchSuggestions.length > 0) {
      setShowSuggestions(true);
    }
  };

  return (
    <Box w="100%" mb={isDetailView ? 0 : 6}>
      {/* Header */}
      <Flex
        flexDirection="row"
        mb="4"
        align="center"
        justify="space-between"
        w="100%"
      >
        <Heading
          size="lg"
          color={useColorModeValue("gray.700", "white")}
          mb={0}
          flex="1"
          isTruncated
        >
          {title}
        </Heading>

        {/* Profile Component */}
        <Popover placement="bottom-end">
          <PopoverTrigger>
            <Box
              as="button"
              display="flex"
              alignItems="center"
              cursor="pointer"
              borderRadius="lg"
              p={2}
              _hover={{ bg: hoverBg }}
            >
              <Avatar
                size="sm"
                name={userData?.name}
                bg="#1A5BF6"
                color="white"
                borderRadius="3xl"
                mr={{ base: 0, md: 3 }}
                fontWeight="700"
              />
              <Text 
                fontWeight="medium" 
                color={useColorModeValue("gray.700", "white")}
                display={{ base: "none", md: "block" }}
              >
                {userData?.name}
              </Text>
              <ChevronDownIcon 
                ml={1} 
                color={useColorModeValue("gray.500", "gray.400")} 
                display={{ base: "none", md: "block" }}
              />
            </Box>
          </PopoverTrigger>
          <PopoverContent
            bg={popoverBg}
            borderColor={popoverBorderColor}
            boxShadow="lg"
            _focus={{ outline: "none" }}
            minW="160px"
            maxW="280px"
            w="auto"
          >
            <PopoverBody py={3}>
              <VStack spacing={2} align="stretch">
                <Box px={3} py={2}>
                  <Text fontWeight="500">{userData?.name}</Text>
                  <Text fontSize="sm" color="gray.500">
                    {userData?.email}
                  </Text>
                </Box>
                <Link
                  px={3}
                  py={2}
                  borderRadius="lg"
                  _hover={{ bg: hoverBg }}
                  onClick={() => navigate('/admin/settings')}
                  cursor="pointer"
                  display="block"
                  w="100%"
                >
                  <HStack spacing={3}>
                    <Icon as={MdSettings} boxSize={5} />
                    <Text fontWeight="500">Settings</Text>
                  </HStack>
                </Link>
                <Link
                  px={3}
                  py={2}
                  borderRadius="md"
                  color="red.500"
                  _hover={{ bg: hoverBg }}
                  onClick={() => logout({ returnTo: window.location.origin })}
                  cursor="pointer"
                  display="block"
                  w="100%"
                >
                  <HStack spacing={3}>
                    <Icon as={MdLogout} boxSize={5} />
                    <Text fontWeight="500">Log Out</Text>
                  </HStack>
                </Link>
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>

      {/* Controls Container */}
      <Flex
        direction={{ base: "column", md: "row" }}
        gap={{ base: 3, md: 6 }}
        justify="space-between"
        align={{ base: "stretch", md: "center" }}
        w="100%"
        mt={{ base: 2, md: 0 }}
      >
        {/* Left Controls: Segment Options and Filter Button */}
        <Flex gap={4} align="center">
          {segmentOptions && segmentOptions.length <= 3 ? (
            <ButtonGroup isAttached variant="outline" spacing={0}>
              {segmentOptions.map((option) => {
                const isSelected = selectedSegment === option.value;
                return (
                  <Button
                    key={option.value}
                    onClick={() => onSegmentChange(option.value)}
                    bg={isSelected ? selectedBg : unselectedBg}
                    color={isSelected ? selectedTextColor : unselectedTextColor}
                    _hover={{
                      bg: isSelected ? selectedBg : buttonHoverBg,
                      color: isSelected ? selectedTextColor : "inherit",
                    }}
                    _active={{
                      bg: isSelected ? selectedBg : buttonHoverBg,
                      color: isSelected ? selectedTextColor : "inherit",
                    }}
                    borderRadius="md"
                    minW="100px"
                    borderWidth="1px"
                    borderColor={useColorModeValue("gray.300", "gray.500")}
                  >
                    {option.label}
                  </Button>
                );
              })}
            </ButtonGroup>
          ) : (
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                variant="outline"
                bg={unselectedBg}
                borderRadius="md"
                borderColor={useColorModeValue("gray.300", "gray.500")}
                _hover={{ bg: buttonHoverBg }}
                color={unselectedTextColor}
              >
                {segmentOptions.find(opt => opt.value === selectedSegment)?.label || "Select Option"}
              </MenuButton>
              <MenuList>
                {segmentOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    onClick={() => onSegmentChange(option.value)}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          )}
          {additionalControls && <Box>{additionalControls}</Box>}
        </Flex>

        {/* Right Controls: Search Input and Create Button */}
        <Flex gap={4} align="center" justify={{ base: "flex-start", md: "flex-end" }}>
          {showSearch && (
            <Box w={{ base: "100%", md: "224px" }}>
              <InputGroup size="md">
                <InputLeftElement pointerEvents="none">
                  <Icon as={Search} color="gray.400" />
                </InputLeftElement>
                <Input
                  type="text"
                  placeholder={searchPlaceholder}
                  value={searchQuery}
                  onChange={(e) => onSearchChange(e.target.value)}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  bg={searchBg}
                  borderColor={searchBorderColor}
                  _hover={{ borderColor: "gray.400" }}
                  _focus={{ borderColor: "blue.500", boxShadow: "none" }}
                  size="md"
                />
              </InputGroup>
              {showSuggestions && searchQuery && (
                <Box
                  position="absolute"
                  top="100%"
                  left="0"
                  right="0"
                  bg={suggestionBg}
                  border="1px solid"
                  borderColor={suggestionBorderColor}
                  borderRadius="md"
                  mt={1}
                  zIndex={10}
                  maxHeight="200px"
                  overflowY="auto"
                >
                  {searchSuggestions.length > 0 ? (
                    <List spacing={0}>
                      {searchSuggestions.map((suggestion) => (
                        <ListItem
                          key={suggestion.id}
                          px={4}
                          py={2}
                          cursor="pointer"
                          _hover={{
                            bg: hoverBg,
                          }}
                          onClick={() => handleSuggestionClick(suggestion.id)}
                        >
                          {renderHighlightText(suggestion.label, searchQuery)}
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <Box px={4} py={2} color="gray.500">
                      No matching results found.
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          )}
          {searchRightElement && <Box>{searchRightElement}</Box>}
        </Flex>
      </Flex>
    </Box>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  segmentOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedSegment: PropTypes.string.isRequired,
  onSegmentChange: PropTypes.func.isRequired,
  searchPlaceholder: PropTypes.string,
  onSearchChange: PropTypes.func.isRequired,
  searchSuggestions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  searchQuery: PropTypes.string,
  onSuggestionSelect: PropTypes.func,
  showSearch: PropTypes.bool,
  additionalControls: PropTypes.node,
  isDetailView: PropTypes.bool,
  searchRightElement: PropTypes.node,
};

export default PageHeader;
