import React from 'react';
import PropTypes from 'prop-types';
import { Box, VStack, Flex, Text } from '@chakra-ui/react';
import { 
  FaEnvelope, 
  FaBriefcase, 
  FaFilter, 
  FaFlag,
  FaPhone,
  FaMapMarker,
  FaLinkedin,
  FaBuilding,
  FaCalendar,
} from 'react-icons/fa';
import { OverviewTag } from '../Shared/Tags';
import ContactDealsSection from './ContactDealsSection';
import ContactActivity from './ContactActivity';
import ContactAccountsSection from './ContactAccountsSection';
import { format } from 'date-fns';

const ContactDetail = ({ contact }) => {
  const formattedCreatedDate = contact.created_date 
    ? format(new Date(contact.created_date), 'MMMM do, yyyy')
    : null;

  return (
    <Box
      bg="transparent"
      p={0}
      borderRadius="md"
      boxShadow="none"
      mb={4}
      height="100%"
    >
      <VStack spacing={6} align="stretch" height="100%">
        {/* Combined Overview, Deals, and Accounts Section */}
        <Box
          bg="white"
          p={6}
          borderRadius="md"
          boxShadow="sm"
          borderWidth="1px"
          borderColor="gray.200"
        >
          <VStack spacing={6} align="stretch">
            {/* Overview Section */}
            <Box>
              <Text fontSize="md" fontWeight="semibold" mb={3}>
                Overview
              </Text>
              <Flex gap={3} flexWrap="wrap">
                {contact.email && (
                  <OverviewTag
                    icon={FaEnvelope}
                    label="Email"
                    value={contact.email}
                    colorScheme="green"
                  />
                )}
                {contact.title && (
                  <OverviewTag
                    icon={FaBriefcase}
                    label="Title"
                    value={contact.title}
                    colorScheme="purple"
                  />
                )}
                {contact.source && (
                  <OverviewTag
                    icon={FaFilter}
                    label="Source"
                    value={contact.source}
                    colorScheme="orange"
                  />
                )}
                {contact.status && (
                  <OverviewTag
                    icon={FaFlag}
                    label="Status"
                    value={contact.status}
                    colorScheme="teal"
                  />
                )}
                {contact.phone && (
                  <OverviewTag
                    icon={FaPhone}
                    label="Phone"
                    value={contact.phone}
                    colorScheme="blue"
                  />
                )}
                {(contact.city || contact.state || contact.country) && (
                  <OverviewTag
                    icon={FaMapMarker}
                    label="Location"
                    value={[contact.city, contact.state, contact.country].filter(Boolean).join(', ')}
                    colorScheme="yellow"
                  />
                )}
                {contact.linkedin && (
                  <OverviewTag
                    icon={FaLinkedin}
                    label="LinkedIn"
                    value={contact.linkedin}
                    colorScheme="linkedin"
                  />
                )}
                {contact.department && (
                  <OverviewTag
                    icon={FaBuilding}
                    label="Department"
                    value={contact.department}
                    colorScheme="cyan"
                  />
                )}
                {formattedCreatedDate && (
                  <OverviewTag
                    icon={FaCalendar}
                    label="Created"
                    value={formattedCreatedDate}
                    colorScheme="gray"
                  />
                )}
              </Flex>
            </Box>
            <ContactDealsSection deals={contact.deals || []} />
            <ContactAccountsSection contact={contact} />
          </VStack>
        </Box>

        {/* Activity Section */}
        <Box
          bg="white"
          p={6}
          borderRadius="md"
          boxShadow="sm"
          borderWidth="1px"
          borderColor="gray.200"
        >
          <ContactActivity accountId={contact.account_id} contactId={contact.id} />
        </Box>
      </VStack>
    </Box>
  );
};

ContactDetail.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.number.isRequired,
    account_id: PropTypes.number.isRequired,
    crm_account_id: PropTypes.number,
    crm_account_name: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    title: PropTypes.string,
    department: PropTypes.string,
    type: PropTypes.string,
    source: PropTypes.string,
    status: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    linkedin: PropTypes.string,
    deals: PropTypes.array,
    accounts: PropTypes.array,
    created_date: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
  }).isRequired,
};

export default ContactDetail;