import { useState, useEffect } from 'react';

/**
 * Custom hook to manage persisted filter state
 * @param {string} key - The key to use for localStorage
 * @param {any} defaultValue - The default value if no stored value exists
 * @returns {[any, (value: any) => void]} - The current value and setter function
 */
export const useFilterState = (key, defaultValue) => {
  // Initialize state from localStorage or default value
  const [value, setValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : defaultValue;
    } catch (error) {
      console.warn(`Error reading localStorage key "${key}":`, error);
      return defaultValue;
    }
  });

  // Update localStorage when the value changes
  useEffect(() => {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.warn(`Error setting localStorage key "${key}":`, error);
    }
  }, [value, key]);

  return [value, setValue];
}; 