import React, { useMemo } from 'react';
import {
    Box,
    VStack,
    Text,
    useColorModeValue,
    HStack,
    Spacer,
} from '@chakra-ui/react';
import { extractEmailContent } from '../Shared/extractEmailContent';
import { ActivityPropType } from './types';
import PropTypes from 'prop-types';

const RecipientList = ({ type, emails }) => {
    const labelColor = useColorModeValue('gray.600', 'gray.400');
    if (!emails || emails.length === 0) return null;

    // Properly capitalize only the first letter
    const formattedType = type.charAt(0).toUpperCase() + type.slice(1).toLowerCase();

    return (
        <Text>
            <Text as="span" fontWeight="semibold" color={labelColor}>
                {formattedType}:
            </Text>{' '}
            {emails.join(', ')}
        </Text>
    );
};

const EmailDisplay = ({ activity }) => {
    // Add null check for activity_data with informative fallback
    if (!activity?.activity_data?.data) {
        return (
            <Box p={4} bg="gray.50" borderRadius="md" borderWidth="1px" borderColor="gray.200">
                <Text color="gray.500">Email data not available</Text>
            </Box>
        );
    }

    const emailData = activity.activity_data.data;
    const cleanedContent = useMemo(() =>
            extractEmailContent(emailData.body),
        [emailData.body]
    );

    // Format date
    const formattedDate = useMemo(() => {
        const date = new Date(activity.completed_date_time || activity.activity_date || activity.created_at);
        return new Intl.DateTimeFormat('en-US', {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
            timeZoneName: 'short'
        }).format(date);
    }, [activity.completed_date_time, activity.activity_date, activity.created_at]);

    // Style variables
    const bgColor = useColorModeValue('gray.50', 'gray.700');
    const borderColor = useColorModeValue('gray.200', 'gray.600');
    const labelColor = useColorModeValue('gray.600', 'gray.400');
    const contentBgColor = useColorModeValue('white', 'gray.800');
    const dateColor = useColorModeValue('gray.500', 'gray.400');

    return (
        <Box p={4} bg={bgColor} borderRadius="md" borderWidth="1px" borderColor={borderColor}>
            <VStack align="stretch" spacing={4}>
                {/* Email Header */}
                <VStack align="stretch" spacing={2}>
                    {/* From and Date */}
                    <HStack align="center" spacing={2}>
                        <Text>
                            <Text as="span" fontWeight="semibold" color={labelColor}>
                                From:
                            </Text>{' '}
                            {emailData.sender_name ? `${emailData.sender_name} <${emailData.sender_email}>` : emailData.sender_email || 'Unknown Sender'}
                        </Text>
                        <Spacer />
                        <Text fontSize="sm" color={dateColor}>
                            {formattedDate}
                        </Text>
                    </HStack>

                    {/* Recipients */}
                    {emailData.recipients && emailData.recipients.length > 0 && (
                        <RecipientList 
                            type="To" 
                            emails={emailData.recipients
                                .filter(r => r.recipient_type === 'to')
                                .map(r => r.email)
                            } 
                        />
                    )}
                    {emailData.recipients && emailData.recipients.length > 0 && (
                        <RecipientList 
                            type="Cc" 
                            emails={emailData.recipients
                                .filter(r => r.recipient_type === 'cc')
                                .map(r => r.email)
                            } 
                        />
                    )}
                    {emailData.recipients && emailData.recipients.length > 0 && (
                        <RecipientList 
                            type="Bcc" 
                            emails={emailData.recipients
                                .filter(r => r.recipient_type === 'bcc')
                                .map(r => r.email)
                            } 
                        />
                    )}

                    {/* Subject */}
                    <Text>
                        <Text as="span" fontWeight="semibold" color={labelColor}>
                            Subject:
                        </Text>{' '}
                        {emailData.subject}
                    </Text>
                </VStack>

                {/* Email Body */}
                <Box
                    className="email-content"
                    p={4}
                    borderRadius="md"
                    borderWidth="1px"
                    borderColor={borderColor}
                    bg={contentBgColor}
                    dangerouslySetInnerHTML={{ __html: cleanedContent }}
                    sx={{
                        'p, div': {
                            marginBottom: '0.5rem',
                            '&:last-child': {
                                marginBottom: 0
                            }
                        },
                        'a': {
                            color: 'blue.500',
                            textDecoration: 'underline'
                        }
                    }}
                />
            </VStack>
        </Box>
    );
};

EmailDisplay.propTypes = {
    activity: ActivityPropType
};

RecipientList.propTypes = {
    type: PropTypes.string.isRequired,
    emails: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default EmailDisplay;