import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  useToast,
  Text,
} from '@chakra-ui/react';
import { useAuthData } from 'auth-context';
import useUpdateDeal from '../hooks/useUpdateDeal';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const EditDealModal = ({ isOpen, onClose, deal }) => {
  const toast = useToast();
  const userData = useAuthData();
  const updateDealMutation = useUpdateDeal();
  const [formData, setFormData] = useState({
    deal_name: '',
    description: '',
    lead_source: '',
    priority: '',
    amount: '',
    stage_id: '',
    stage_name: '',
  });

  // Fetch pipeline stages
  const { data: pipelineStages } = useQuery({
    queryKey: ['pipelineStages', userData.account_ID, deal?.pipeline_id],
    queryFn: async () => {
      const target = process.env.REACT_APP_API_BASE_URL || 'https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net';
      const response = await axios.get(
        `${target}/accounts/${userData.account_ID}/pipelines/${deal?.pipeline_id}/stages`
      );
      const data = response.data;
      // Ensure that response data is an array; if not, try to return data.stages or fallback to empty array
      return Array.isArray(data) ? data : (data.stages || []);
    },
    enabled: !!userData.account_ID && !!deal?.pipeline_id,
  });

  useEffect(() => {
    if (deal) {
      setFormData({
        deal_name: deal.deal_name || '',
        description: deal.description || '',
        lead_source: deal.lead_source || '',
        priority: deal.priority || '',
        amount: deal.amount?.toString() || '',
        stage_id: deal.stage_id ? parseInt(deal.stage_id) : '',
        stage_name: deal.stage_name || '',
      });
    }
  }, [deal]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'stage_id' && pipelineStages) {
      const numericValue = parseInt(value);
      const selectedStage = pipelineStages.find(stage => stage.id === numericValue);
      setFormData(prev => ({
        ...prev,
        [name]: numericValue,
        stage_name: selectedStage?.name || '',
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      await updateDealMutation.mutateAsync({
        accountId: userData.account_ID,
        dealId: deal.id,
        dealData: {
          crm_account_id: deal.crm_account_id,
          pipeline_id: deal.pipeline_id,
          stage_id: parseInt(formData.stage_id) || deal.stage_id,
          stage_name: formData.stage_name,
          ...formData,
          amount: formData.amount === '' ? null : formData.amount,
        },
      });

      toast({
        title: 'Deal Updated',
        description: 'The deal has been successfully updated.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      onClose();
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message || 'Failed to update deal',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Deal</ModalHeader>
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <Text fontSize="sm" color="gray.600" mb={2}>
              Update the deal details below.
            </Text>

            <FormControl isRequired>
              <FormLabel>Deal Name</FormLabel>
              <Input
                name="deal_name"
                value={formData.deal_name}
                onChange={handleInputChange}
                placeholder="Enter deal name"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Description</FormLabel>
              <Input
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                placeholder="Deal description"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Stage</FormLabel>
              <Select
                name="stage_id"
                value={formData.stage_id}
                onChange={handleInputChange}
                placeholder="Select stage"
              >
                {pipelineStages?.map((stage) => (
                  <option key={stage.id} value={stage.id}>
                    {stage.name}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Lead Source</FormLabel>
              <Select
                name="lead_source"
                value={formData.lead_source}
                onChange={handleInputChange}
                placeholder="Select lead source"
              >
                <option value="Direct Contact">Direct Contact</option>
                <option value="Email">Email</option>
                <option value="Website">Website</option>
                <option value="Referral">Referral</option>
                <option value="Other">Other</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Priority</FormLabel>
              <Select
                name="priority"
                value={formData.priority}
                onChange={handleInputChange}
                placeholder="Select priority"
              >
                <option value="PMS">PMS</option>
                <option value="PM">PM</option>
                <option value="PO">PO</option>
                <option value="Adhoc">Ad-hoc</option>
                <option value="Content">Content</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Amount</FormLabel>
              <Input
                name="amount"
                value={formData.amount}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === '' || (!isNaN(value) && value.trim() !== '')) {
                    handleInputChange(e);
                  }
                }}
                placeholder="Enter amount"
                type="number"
                step="0.01"
                min="0"
              />
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            onClick={handleSubmit}
            isLoading={updateDealMutation.isLoading}
          >
            Save Changes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

EditDealModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  deal: PropTypes.shape({
    id: PropTypes.number.isRequired,
    crm_account_id: PropTypes.number.isRequired,
    pipeline_id: PropTypes.number.isRequired,
    stage_id: PropTypes.number.isRequired,
    stage_name: PropTypes.string,
    deal_name: PropTypes.string,
    description: PropTypes.string,
    lead_source: PropTypes.string,
    priority: PropTypes.string,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};

export default EditDealModal; 