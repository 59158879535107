import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  VStack,
  Text,
  Button,
  Flex,
  ButtonGroup,
  IconButton,
  Tooltip,
  useColorModeValue,
  Skeleton,
  SkeletonText,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Collapse,
} from '@chakra-ui/react';
import { FaChevronDown, FaChevronUp, FaPlus, FaTimes, FaFire, FaCalendar, FaUser, FaThermometerHalf, FaExpandAlt } from 'react-icons/fa';
import { RiLightbulbFlashLine } from 'react-icons/ri';
import { ExternalLink } from 'lucide-react';
import { OverviewTag } from '../Shared/Tags';
import { format } from 'date-fns';
import { ActivityItem } from '../Activities';
import AddDealModal from './AddDealModal';
import { useAuthData } from 'auth-context';
import { useDealCreationModal } from '../hooks/useDealCreationModal';
import HoverBox from '../Shared/HoverBox';
import IgnoreActionModal from './IgnoreActionModal';

const SparkCard = ({ spark, onAddDeal, onIgnore, isCreatingDeal, currentPage, isPageLoading }) => {
  const [isActivitiesModalOpen, setIsActivitiesModalOpen] = useState(false);
  const [isAddDealModalOpen, setIsAddDealModalOpen] = useState(false);
  const [isIgnoreModalOpen, setIsIgnoreModalOpen] = useState(false);
  const [isActivitiesExpanded, setIsActivitiesExpanded] = useState(false);
  const userData = useAuthData();
  const { isLoading, fetchModalData } = useDealCreationModal();
  
  useEffect(() => {
    setIsActivitiesModalOpen(false);
  }, [currentPage]);
  
  // If the page is currently loading, render a skeleton placeholder
  if(isPageLoading) {
    return (
      <Box
        bg="white"
        p={6}
        borderRadius="md"
        boxShadow="sm"
        borderWidth="1px"
        borderColor="gray.200"
        height="100%"
      >
        <VStack spacing={4} align="stretch">
          {/* Header */}
          <HStack justify="space-between">
            <Skeleton height="24px" width="200px" />
            <HStack spacing={2}>
              <Skeleton height="32px" width="70px" />
              <Skeleton height="32px" width="90px" />
            </HStack>
          </HStack>

          {/* Subject line */}
          <Skeleton height="20px" width="80%" />

          {/* Tags */}
          <HStack spacing={4}>
            <Skeleton height="24px" width="120px" borderRadius="full" />
            <Skeleton height="24px" width="150px" borderRadius="full" />
            <Skeleton height="24px" width="130px" borderRadius="full" />
          </HStack>

          {/* AI Recommendation */}
          <Box>
            <Skeleton height="20px" width="150px" mb={2} />
            <SkeletonText noOfLines={2} spacing={2} />
          </Box>

          {/* Previous Messages */}
          <Box>
            <Skeleton height="20px" width="150px" mb={2} />
            <VStack spacing={3} align="stretch">
              <SkeletonText noOfLines={3} spacing={2} />
              <SkeletonText noOfLines={3} spacing={2} />
              <SkeletonText noOfLines={3} spacing={2} />
            </VStack>
          </Box>
        </VStack>
      </Box>
    );
  }

  const getSparkDetails = (score) => {
    if (score >= 0.9) {
      return { colorScheme: "red", label: "Hot", icon: FaFire };
    } else if (score >= 0.7) {
      return { colorScheme: "orange", label: "Warm", icon: FaThermometerHalf };
    }
    return { colorScheme: "blue", label: "Cool", icon: FaThermometerHalf };
  };
  
  const getExternalCrmAccountId = () => {
    if (!spark.recipients || !Array.isArray(spark.recipients)) {
      return null;
    }
    const externalRecipient = spark.recipients.find(recipient => {
      const recipientDomain = recipient.email.split('@')[1];
      return recipientDomain !== spark.user_domain;
    });
    return externalRecipient?.crm_account_id;
  };

  const handleOpenModal = async () => {
    
    // Try to get CRM account ID but don't require it
    const crmAccountId = getExternalCrmAccountId();
    
    try {
      // Only try to fetch modal data if we have a CRM account ID
      if (crmAccountId) {
        await fetchModalData(userData.account_ID, userData.user_ID, crmAccountId);
      }
      
      // Always open the modal
      setIsAddDealModalOpen(true);
      
    } catch (err) {
      console.error('Error fetching deal creation data:', err);
      // Still open the modal
      setIsAddDealModalOpen(true);
    }
  };
  
  const getFormattedDate = (timestamp) => {
    try {
      if (!timestamp) return 'No date';
      const date = new Date(timestamp);
      // Check if date is valid
      if (isNaN(date.getTime())) return 'Invalid date';
      return format(date, 'MMM d, yyyy');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid date';
    }
  };

  const getDomainFromParticipants = (spark) => {
    try {
      // Get the user's domain to exclude it
      const userDomain = spark.user_domain?.toLowerCase();
      
      // Helper function to split and capitalize concatenated words
      const splitAndCapitalizeWords = (text) => {
        // First split by any existing separators
        let words = text.split(/[._-]/);
        
        // For each word, check for camelCase or concatenated words
        words = words.flatMap(word => {
          // Split camelCase (e.g., "halulaProperties" -> ["halula", "Properties"])
          const camelCaseWords = word.split(/(?=[A-Z])/);
          
          // Split concatenated lowercase words by looking for common word boundaries
          if (camelCaseWords.length === 1) {
            // Common business word patterns
            const commonWords = [
              // Property & Real Estate
              'properties', 'property', 'realty', 'estate', 'estates', 'homes', 'house', 'housing',
              // Travel & Hospitality
              'travels', 'travel', 'stays', 'stay', 'holiday', 'holidays', 'vacations', 'rentals',
              'hospitality', 'host', 'hosting', 'lodging', 'accommodations', 'getaways', 'retreats',
              'villas', 'cottages', 'apartments', 'rooms', 'suites', 'barn', 'barns',
              // Business Services
              'solutions', 'services', 'technologies', 'systems', 'group', 'consulting',
              'partners', 'associates', 'advisors', 'management', 'ventures',
              // Beauty & Wellness
              'beauty', 'wellness', 'spa', 'salon', 'cosmetics', 'skincare', 'care',
              // Common Joining Words
              'and', 'the', 'of', 'in', 'at', 'by', 'with',
              // Location Related
              'london', 'cornish', 'uk', 'british', 'england', 'scotland', 'wales', 'irish'
            ];

            // Try to match common words at the end first
            for (const commonWord of commonWords) {
              const pattern = new RegExp(`(.*?)(${commonWord})$`, 'i');
              const match = word.toLowerCase().match(pattern);
              if (match && match[1] && match[2]) {
                // Recursively split the first part if it's still a compound word
                const firstPart = splitAndCapitalizeWords(match[1]);
                const secondPart = match[2].charAt(0).toUpperCase() + match[2].slice(1).toLowerCase();
                return [firstPart, secondPart];
              }
            }

            // If no common word found at the end, try finding them at the start
            for (const commonWord of commonWords) {
              const pattern = new RegExp(`^(${commonWord})(.*?)`, 'i');
              const match = word.toLowerCase().match(pattern);
              if (match && match[1] && match[2]) {
                const firstPart = match[1].charAt(0).toUpperCase() + match[1].slice(1).toLowerCase();
                const secondPart = splitAndCapitalizeWords(match[2]);
                return [firstPart, secondPart];
              }
            }

            // If still no match, try to split by common patterns
            const commonPatterns = [
              /([a-z]+)(stay|host|travel|beauty|home|house|rental)s?/i,
              /([a-z]+)(and)([a-z]+)/i
            ];

            for (const pattern of commonPatterns) {
              const match = word.match(pattern);
              if (match) {
                return match.slice(1).filter(Boolean).map(part => 
                  part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
                );
              }
            }
          }
          
          return camelCaseWords;
        });
        
        // Clean and capitalize each word
        return words
          .flat() // Flatten nested arrays from recursive calls
          .filter(word => word.length > 0)
          .map(word => {
            // Special case for joining words to keep them lowercase unless at start
            const joiningWords = ['and', 'the', 'of', 'in', 'at', 'by', 'with'];
            if (joiningWords.includes(word.toLowerCase())) {
              return word.toLowerCase();
            }
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
          })
          .join(' ');
      };

      // First check CRM domain if available
      if (spark.crm_domain) {
        const domain = spark.crm_domain.split('.')[0];
        return splitAndCapitalizeWords(domain);
      }

      // Helper function to check if domain is Gmail
      const isGmailDomain = (domain) => {
        const gmailDomains = ['gmail.com', 'googlemail.com'];
        return gmailDomains.includes(domain.toLowerCase());
      };

      // Helper function to capitalize email username
      const capitalizeEmailUsername = (email) => {
        const username = email.split('@')[0];
        return splitAndCapitalizeWords(username);
      };

      // Helper function to get full name from activity
      const getFullNameFromActivity = (activity) => {
        const senderName = activity.activity_data?.data?.sender_name;
        if (!senderName || !senderName.trim()) return null;
        
        // Split and clean name parts
        const nameParts = senderName.split(' ').map(part => part.trim()).filter(Boolean);
        if (nameParts.length === 0) return null;
        
        // Return full name with proper capitalization
        return nameParts.map(part => 
          splitAndCapitalizeWords(part)
        ).join(' ');
      };

      // Find all external email activities (non-internal)
      const externalEmailActivities = spark.activities?.filter(activity => 
        activity.activity_type === 'email' && 
        !activity.is_internal &&
        activity.activity_data?.data?.sender_email
      ) || [];

      // Sort activities by received_at date to get the most recent first
      const sortedActivities = externalEmailActivities.sort((a, b) => {
        const dateA = new Date(a.activity_data?.data?.received_at || 0);
        const dateB = new Date(b.activity_data?.data?.received_at || 0);
        return dateB - dateA;
      });

      for (const activity of sortedActivities) {
        const senderEmail = activity.activity_data?.data?.sender_email;
        const senderDomain = senderEmail?.split('@')[1]?.toLowerCase();

        // For Gmail accounts, first try sender name
        if (senderDomain && isGmailDomain(senderDomain)) {
          // Try to get full name first
          const fullName = getFullNameFromActivity(activity);
          if (fullName) return fullName;

          // Use capitalized email username as fallback
          if (senderEmail) {
            return capitalizeEmailUsername(senderEmail);
          }
        }

        // For non-Gmail domains
        if (senderDomain && !isGmailDomain(senderDomain) && senderDomain !== userDomain) {
          const domain = senderDomain.split('.')[0];
          return domain.charAt(0).toUpperCase() + domain.slice(1);
        }
      }

      // Check for non-Gmail recipients if no sender found
      const nonGmailRecipient = spark.recipients?.find(recipient => {
        const domain = recipient.email?.split('@')[1]?.toLowerCase();
        return domain && domain !== userDomain && !isGmailDomain(domain);
      });

      if (nonGmailRecipient) {
        const domain = nonGmailRecipient.email.split('@')[1].split('.')[0];
        return domain.charAt(0).toUpperCase() + domain.slice(1);
      }

      return 'Unknown';
    } catch (error) {
      console.error('Error getting domain from participants:', error);
      return 'Unknown';
    }
  };

  const handleAddDeal = (formData) => {
    // Get the CRM account ID from the external recipient
    const crmAccountId = getExternalCrmAccountId();
    
    // Get the most recent email activity for sender info
    const lastEmailActivity = spark.activities?.find(activity => 
      activity.activity_data?.activity_type === 'email'
    );

    const sparkData = {
      crm_account_id: crmAccountId,
      company_name: getDomainFromParticipants(spark),
      activities: spark.activities?.map(activity => ({
        id: activity.id,
        activity_type: activity.activity_data?.activity_type,
        activity_data: activity.activity_data,
        is_internal: activity.is_internal,
        classification: activity.classification,
        created_at: activity.created_at,
        updated_at: activity.updated_at
      })),
      user_domain: spark.user_domain,
      recipients: spark.recipients?.map(recipient => ({
        email: recipient.email,
        name: recipient.name,
        recipient_type: recipient.recipient_type,
        crm_account_id: recipient.crm_account_id
      })),
      thread_id: spark.thread_id,
      sender: lastEmailActivity ? {
        email: lastEmailActivity.activity_data?.data?.sender_email,
        name: lastEmailActivity.activity_data?.data?.sender_name
      } : null,
      subject: spark.subject,
      email_link: spark.email_link,
      spark_score: spark.spark_score,
      reason: spark.reason
    };

    console.log('Sending spark data:', sparkData);
    onAddDeal(formData, sparkData);
    setIsAddDealModalOpen(false);
  };

  // Transform activities into the format expected by ActivityItem
  const transformedActivities = spark.activities?.map(activity => {
    // Helper function to capitalize email username
    const getFormattedSenderName = (senderName, senderEmail) => {
      if (senderName) return senderName;
      if (senderEmail) {
        const username = senderEmail.split('@')[0];
        return username.charAt(0).toUpperCase() + username.slice(1);
      }
      return 'Unknown Sender';
    };

    return {
      id: activity.id,
      account_id: activity.account_id,
      user_id: activity.user_id,
      owner_id: activity.user_id,
      user_name: activity.user_name,
      user_last_name: activity.user_last_name,
      user_email: activity.user_email,
      deal_id: activity.deal_id,
      activity_id: activity.activity_id,
      activity_type: activity.activity_data.activity_type,
      activity_date: activity.created_at,
      completed_date_time: activity.activity_data.data.received_at,
      status: "completed",
      title: activity.activity_data.data.subject,
      description: activity.activity_data.data.body,
      activity_data: {
        activity_type: activity.activity_data.activity_type,
        data: {
          body: activity.activity_data.data.body,
          received_at: activity.activity_data.data.received_at,
          recipients: activity.activity_data.data.recipients?.map(recipient => ({
            email: recipient.email,
            recipient_type: recipient.recipient_type
          })) || [],
          sender_name: getFormattedSenderName(
            activity.activity_data.data.sender_name,
            activity.activity_data.data.sender_email
          ),
          sender_email: activity.activity_data.data.sender_email,
          subject: activity.activity_data.data.subject
        },
        version: activity.activity_data.version
      },
      source_system: "email",
      timestamp: activity.activity_data.data.received_at,
      created_at: activity.created_at || activity.activity_data.data.received_at,
      updated_at: activity.updated_at || activity.activity_data.data.received_at,
      is_internal: activity.is_internal,
      classification: activity.classification,
      email_link: spark.email_link,
    };
  }) || [];

  // Get the most recent email activity
  const lastEmailActivity = spark.activities?.find(activity => 
    activity.activity_data?.activity_type === 'email'
  );

  const sparkDetails = getSparkDetails(spark.spark_score);
  const formattedDate = getFormattedDate(lastEmailActivity?.activity_data?.data?.received_at);
  const senderEmail = lastEmailActivity?.activity_data?.data?.sender_email;
  const senderName = lastEmailActivity?.activity_data?.data?.sender_name;
  const companyName = getDomainFromParticipants(spark);

  // Show only first 3 activities in card
  const visibleActivities = transformedActivities.slice(0, 3);
  const hasMoreActivities = transformedActivities.length > 3;

  const renderActivitiesList = (activities, showAll = false) => (
    <VStack spacing={4} align="stretch" width="100%">
      {(showAll ? activities : activities.slice(0, 3)).map((activity) => (
        <Box key={activity.id}>
          <ActivityItem activity={activity} />
        </Box>
      ))}
    </VStack>
  );

  // Get the thread_id from the first activity
  const getThreadId = (spark) => {
    if (!spark?.activities?.length) return null;
    return spark.activities[0]?.activity_data?.data?.client_thread_id;
  };

  const handleIgnoreClick = () => {
    setIsIgnoreModalOpen(true);
  };

  return (
    <>
      <Box
        height="fit-content"
        alignSelf="flex-start"
        bg="white"
        p={8}
        borderRadius="md"
        boxShadow="sm"
        borderWidth="1px"
        borderColor="gray.200"
        _hover={{
          borderColor: "gray.300",
          boxShadow: "md",
        }}
        transition="all 0.2s"
        display="flex"
        flexDirection="column"
      >
        <VStack spacing={6} align="stretch" flex={1}>
          {/* Title Bar */}
          <Flex justify="space-between" align="center">
            <Box position="relative" flex={1} maxWidth="fit-content">
              <HoverBox
                icon={RiLightbulbFlashLine}
                hoverIcon={FaExpandAlt}
                onClick={() => setIsActivitiesModalOpen(true)}
                tooltipLabel="View All Messages"
              >
                <Flex align="center">
                  <Text fontSize="lg" fontWeight="semibold" color="gray.700">
                    {companyName} Deal
                  </Text>
                </Flex>
              </HoverBox>
            </Box>
            <Flex align="center" gap={2} ml="auto">
              {spark.email_link && (
                <Tooltip 
                  label="View email thread in Gmail" 
                  placement="top"
                  hasArrow
                >
                  <IconButton
                    as="a"
                    href={spark.email_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    icon={<ExternalLink size={18} />}
                    variant="ghost"
                    size="xs"
                    padding={0}
                    minW="auto"
                    height="auto"
                    color="gray.500"
                    _hover={{ color: "blue.500" }}
                    aria-label="View in Gmail"
                  />
                </Tooltip>
              )}
              <ButtonGroup size="sm" spacing={2}>
                <Button
                  leftIcon={<FaTimes />}
                  variant="ghost"
                  colorScheme="gray"
                  onClick={handleIgnoreClick}
                  size="sm"
                >
                  Ignore
                </Button>
                <Button
                  leftIcon={<FaPlus />}
                  variant="solid"
                  colorScheme="blue"
                  onClick={handleOpenModal}
                  size="sm"
                  isLoading={isCreatingDeal}
                >
                  Add Deal
                </Button>
              </ButtonGroup>
            </Flex>
          </Flex>

          {/* Subject */}
          {spark?.subject && (
            <Text fontSize="md" color="gray.700" fontWeight="medium">
              Subject: {spark.subject}
            </Text>
          )}

          {/* Overview Tags */}
          <Flex gap={3} flexWrap="wrap">
            <OverviewTag
              icon={sparkDetails.icon}
              label="Confidence"
              value={`${Math.round(spark.spark_score * 100)}%`}
              colorScheme={sparkDetails.colorScheme}
            />
            {senderEmail && (
              <OverviewTag
                icon={FaUser}
                label="From"
                value={senderName || senderEmail.split('@')[0]}
                colorScheme="purple"
                tooltip={`${senderName || senderEmail.split('@')[0]} <${senderEmail}>`}
              />
            )}
            <OverviewTag
              icon={FaCalendar}
              label="Last Activity"
              value={formattedDate}
              colorScheme="green"
            />
          </Flex>

          {/* AI Recommendation Section */}
          <VStack spacing={4} align="stretch">
            <Text fontSize="md" fontWeight="semibold" color="gray.700">
              AI Recommendation
            </Text>
            <Box
              bg={useColorModeValue("gray.50", "gray.700")}
              p={6}
              borderRadius="md"
            >
              <Text fontSize="sm" color="gray.600" lineHeight="tall">
                {spark.reason}
              </Text>
            </Box>
          </VStack>

          {/* Activities Section */}
          <VStack spacing={4} align="stretch">
            <Text fontSize="md" fontWeight="semibold" color="gray.700">
              Previous Messages
            </Text>
            <VStack spacing={4} align="stretch">
              {renderActivitiesList(visibleActivities)}
              <Collapse in={isActivitiesExpanded} animateOpacity>
                {renderActivitiesList(transformedActivities.slice(3), true)}
              </Collapse>
              {hasMoreActivities && (
                <Flex justify="center" width="100%" mt={2}>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => setIsActivitiesExpanded(!isActivitiesExpanded)}
                    rightIcon={isActivitiesExpanded ? <FaChevronUp /> : <FaChevronDown />}
                  >
                    {isActivitiesExpanded ? 'Show Less' : `Show ${transformedActivities.length - 3} More`}
                  </Button>
                </Flex>
              )}
            </VStack>
          </VStack>
        </VStack>
      </Box>

      {/* Activities Modal */}
      <Modal 
        isOpen={isActivitiesModalOpen} 
        onClose={() => setIsActivitiesModalOpen(false)}
        size="4xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent maxH="90vh">
          <ModalHeader p={8}>
            <Flex justify="space-between" align="center">
              <Text fontSize="2xl" fontWeight="bold">{companyName} Deal</Text>
              <ModalCloseButton position="static" />
            </Flex>
          </ModalHeader>
          <ModalBody px={8} pb={8}>
            <VStack spacing={6} align="stretch">
              {/* Subject */}
              {spark?.subject && (
                <Text fontSize="md" color="gray.700" fontWeight="medium">
                  Subject: {spark.subject}
                </Text>
              )}

              {/* Overview Tags */}
              <Box>
                <Text fontSize="md" fontWeight="semibold" mb={3}>
                  Overview
                </Text>
                <Flex gap={3} flexWrap="wrap">
                  <OverviewTag
                    icon={sparkDetails.icon}
                    label="Confidence"
                    value={`${Math.round(spark.spark_score * 100)}%`}
                    colorScheme={sparkDetails.colorScheme}
                  />
                  {senderEmail && (
                    <OverviewTag
                      icon={FaUser}
                      label="From"
                      value={senderName || senderEmail.split('@')[0]}
                      colorScheme="purple"
                      tooltip={`${senderName || senderEmail.split('@')[0]} <${senderEmail}>`}
                    />
                  )}
                  <OverviewTag
                    icon={FaCalendar}
                    label="Last Activity"
                    value={formattedDate}
                    colorScheme="green"
                  />
                </Flex>
              </Box>

              {/* AI Recommendation */}
              <VStack spacing={4} align="stretch">
                <Text fontSize="md" fontWeight="semibold" color="gray.700">
                  AI Recommendation
                </Text>
                <Box
                  bg={useColorModeValue("gray.50", "gray.700")}
                  p={6}
                  borderRadius="md"
                >
                  <Text fontSize="sm" color="gray.600" lineHeight="tall">
                    {spark.reason}
                  </Text>
                </Box>
              </VStack>

              {/* All Activities */}
              <VStack spacing={4} align="stretch">
                <Text fontSize="md" fontWeight="semibold" color="gray.700">
                  All Messages
                </Text>
                {renderActivitiesList(transformedActivities, true)}
              </VStack>
            </VStack>
          </ModalBody>
          <ModalFooter borderTop="1px" borderColor="gray.100" p={8}>
            <ButtonGroup size="sm" spacing={3}>
              <Button
                leftIcon={<FaTimes />}
                variant="outline"
                colorScheme="gray"
                onClick={() => {
                  onIgnore();
                  setIsActivitiesModalOpen(false);
                }}
              >
                Ignore
              </Button>
              <Button
                leftIcon={<FaPlus />}
                variant="solid"
                colorScheme="blue"
                onClick={() => {
                  handleOpenModal();
                  setIsActivitiesModalOpen(false);
                }}
                isLoading={isCreatingDeal}
              >
                Add Deal
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AddDealModal
        isOpen={isAddDealModalOpen}
        onClose={() => setIsAddDealModalOpen(false)}
        onSubmit={handleAddDeal}
        data={{
          crm_account_id: spark.crm_account_id,
          spark: {
            company_name: getDomainFromParticipants(spark),
            activities: spark.activities,
            user_domain: spark.user_domain,
            recipients: spark.recipients,
            thread_id: getThreadId(spark),
            sender: {
              email: lastEmailActivity?.activity_data?.data?.sender_email,
              name: lastEmailActivity?.activity_data?.data?.sender_name
            },
            subject: spark.subject,
            email_link: spark.email_link
          }
        }}
        isLoading={isLoading}
      />

      <IgnoreActionModal
        isOpen={isIgnoreModalOpen}
        onClose={() => setIsIgnoreModalOpen(false)}
        data={{
          crm_account_id: getExternalCrmAccountId() || spark.crm_account_id,
        }}
      />
    </>
  );
};

SparkCard.propTypes = {
  spark: PropTypes.shape({
    thread_id: PropTypes.string.isRequired,
    spark_score: PropTypes.number.isRequired,
    user_domain: PropTypes.string.isRequired,
    reason: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    email_link: PropTypes.string.isRequired,
    crm_account_id: PropTypes.string,
    recipients: PropTypes.arrayOf(PropTypes.shape({
      crm_account_id: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      recipient_type: PropTypes.oneOf(['to', 'cc', 'bcc']).isRequired,
    })).isRequired,
    activities: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      account_id: PropTypes.number.isRequired,
      user_id: PropTypes.number.isRequired,
      user_name: PropTypes.string.isRequired,
      user_last_name: PropTypes.string.isRequired,
      user_email: PropTypes.string.isRequired,
      deal_id: PropTypes.number.isRequired,
      activity_id: PropTypes.number.isRequired,
      activity_type: PropTypes.string.isRequired,
      activity_data: PropTypes.shape({
        activity_type: PropTypes.string.isRequired,
        data: PropTypes.shape({
          body: PropTypes.string.isRequired,
          client_thread_id: PropTypes.string.isRequired,
          received_at: PropTypes.string.isRequired,
          sender_email: PropTypes.string.isRequired,
          sender_name: PropTypes.string.isRequired,
          subject: PropTypes.string.isRequired,
        }).isRequired,
        version: PropTypes.string.isRequired,
      }).isRequired,
      classification: PropTypes.shape({
        activity_purpose: PropTypes.string.isRequired,
        communication_medium: PropTypes.string.isRequired,
        deal_stage: PropTypes.string.isRequired,
        key_action: PropTypes.string.isRequired,
        entities_involved: PropTypes.oneOfType([
          PropTypes.arrayOf(PropTypes.any),
          PropTypes.null,
        ]).isRequired,
        status: PropTypes.string.isRequired,
      }).isRequired,
      created_at: PropTypes.string.isRequired,
      updated_at: PropTypes.string.isRequired,
      is_internal: PropTypes.bool.isRequired,
    })).isRequired,
  }).isRequired,
  onAddDeal: PropTypes.func.isRequired,
  onIgnore: PropTypes.func.isRequired,
  isCreatingDeal: PropTypes.bool,
  currentPage: PropTypes.number,
  isPageLoading: PropTypes.bool,
};

export default SparkCard;