import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  useToast,
  Text,
  InputGroup,
  InputLeftElement,
  Box,
  List,
  ListItem,
  Icon,
} from '@chakra-ui/react';
import { Search } from 'lucide-react';
import { useAuthData } from 'auth-context';
import useCreateDeal from '../hooks/useCreateDeal';
import useFetchAccounts from '../hooks/useFetchAccounts';
import { useDebounce } from 'use-debounce';

const CreateDealModal = ({ isOpen, onClose, existingDeals = [] }) => {
  const toast = useToast();
  const userData = useAuthData();
  const createDealMutation = useCreateDeal();
  
  // Account search state
  const [accountSearch, setAccountSearch] = useState('');
  const [debouncedAccountSearch] = useDebounce(accountSearch, 300);
  const [showAccountSuggestions, setShowAccountSuggestions] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  
  // Form data state
  const [formData, setFormData] = useState({
    deal_name: '',
    description: '',
    lead_source: '',
    priority: '',
    pipeline_id: 4445, // Default value from the example
    stage_id: 40376, // Default value from the example
    amount: '', // Add amount field
  });

  // Fetch accounts
  const { accounts, isLoading: isLoadingAccounts } = useFetchAccounts(userData.account_ID, 'Lead');

  // Filter accounts based on search
  const filteredAccounts = useMemo(() => {
    if (!debouncedAccountSearch) return accounts;
    return accounts.filter(account => 
      account.name?.toLowerCase().includes(debouncedAccountSearch.toLowerCase())
    );
  }, [accounts, debouncedAccountSearch]);

  // Check if deal name already exists
  const isDealNameTaken = (name) => {
    return existingDeals.some(deal => 
      deal.deal_name?.toLowerCase() === name.toLowerCase()
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAccountSelect = (account) => {
    setSelectedAccount(account);
    setAccountSearch(account.name);
    setShowAccountSuggestions(false);
  };

  const handleSubmit = async () => {
    // Validate required fields
    if (!selectedAccount) {
      toast({
        title: 'Account Required',
        description: 'Please select an account for this deal',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!formData.deal_name) {
      toast({
        title: 'Deal Name Required',
        description: 'Please enter a name for this deal',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (isDealNameTaken(formData.deal_name)) {
      toast({
        title: 'Deal Name Already Exists',
        description: 'Please choose a different name for this deal',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      await createDealMutation.mutateAsync({
        accountId: userData.account_ID,
        dealData: {
          ...formData,
          crm_account_id: Number(selectedAccount.id),
          amount: formData.amount === '' ? null : formData.amount,
        },
      });

      toast({
        title: 'Deal Created',
        description: 'The deal has been successfully created.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      onClose();
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message || 'Failed to create deal',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create New Deal</ModalHeader>
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <Text fontSize="sm" color="gray.600" mb={2}>
              Enter the details for the new deal.
            </Text>

            <FormControl isRequired>
              <FormLabel fontWeight="medium">Associated Account</FormLabel>
              <Box position="relative">
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <Icon as={Search} color="gray.400" />
                  </InputLeftElement>
                  <Input
                    value={accountSearch}
                    onChange={(e) => {
                      setAccountSearch(e.target.value);
                      setShowAccountSuggestions(true);
                      setSelectedAccount(null);
                    }}
                    placeholder="Search for the account associated with this deal..."
                    onFocus={() => setShowAccountSuggestions(true)}
                  />
                </InputGroup>
                {showAccountSuggestions && accountSearch && (
                  <Box
                    position="absolute"
                    top="100%"
                    left={0}
                    right={0}
                    bg="white"
                    boxShadow="md"
                    borderRadius="md"
                    maxH="200px"
                    overflowY="auto"
                    zIndex={1000}
                    borderWidth="1px"
                  >
                    <List spacing={0}>
                      {isLoadingAccounts ? (
                        <ListItem p={2}>Loading accounts...</ListItem>
                      ) : filteredAccounts.length === 0 ? (
                        <ListItem p={2}>No accounts found</ListItem>
                      ) : (
                        filteredAccounts.map((account) => (
                          <ListItem
                            key={account.id}
                            p={2}
                            cursor="pointer"
                            _hover={{ bg: "gray.100" }}
                            onClick={() => handleAccountSelect(account)}
                          >
                            {account.name}
                          </ListItem>
                        ))
                      )}
                    </List>
                  </Box>
                )}
              </Box>
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Deal Name</FormLabel>
              <Input
                name="deal_name"
                value={formData.deal_name}
                onChange={handleInputChange}
                placeholder="Enter deal name"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Description</FormLabel>
              <Input
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                placeholder="Deal description"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Lead Source</FormLabel>
              <Select
                name="lead_source"
                value={formData.lead_source}
                onChange={handleInputChange}
                placeholder="Select lead source"
              >
                <option value="Direct Contact">Direct Contact</option>
                <option value="Email">Email</option>
                <option value="Website">Website</option>
                <option value="Referral">Referral</option>
                <option value="Other">Other</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Priority</FormLabel>
              <Select
                name="priority"
                value={formData.priority}
                onChange={handleInputChange}
                placeholder="Select priority"
              >
                <option value="PMS">PMS</option>
                <option value="PM">PM</option>
                <option value="PO">PO</option>
                <option value="Adhoc">Ad-hoc</option>
                <option value="Content">Content</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Amount</FormLabel>
              <Input
                name="amount"
                value={formData.amount}
                onChange={(e) => {
                  const value = e.target.value;
                  // Only allow empty string or valid numbers
                  if (value === '' || (!isNaN(value) && value.trim() !== '')) {
                    handleInputChange(e);
                  }
                }}
                placeholder="Enter amount"
                type="number"
                step="0.01"
                min="0"
              />
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            onClick={handleSubmit}
            isLoading={createDealMutation.isLoading}
          >
            Create Deal
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

CreateDealModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  existingDeals: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    deal_name: PropTypes.string,
  })),
};

export default CreateDealModal; 