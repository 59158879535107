// SettingsView.js
import React, { useState, useEffect, useRef } from "react";
import { Box, Spinner, Text, Button, Flex } from "@chakra-ui/react";
import IntegrationsSection from "./IntegrationsSection";
import CRMSection from "./CRMSection";
import SmartFollowupsSection from "./SmartFollowupsSection";
import SmartComposeSection from "./SmartComposeSection";
import PageHeader from "../Shared/PageHeader";
import { useAuthData } from "auth-context";
import { FaPlus } from "react-icons/fa";
import { useFilterState } from "../hooks/useFilterState";

export default function SettingsView() {
  const userData = useAuthData();
  const userID = userData?.user_ID;
  const [selectedSection, setSelectedSection] = useFilterState("settings-section", "integrations");
  const [isLoading, setIsLoading] = useState(true);
  const privilegedUserIDs = [6, 8];
  const integrationsRef = useRef();

  // Define segment options in the format required by PageHeader
  const [segmentOptions, setSegmentOptions] = useState([
    { label: "Integrations", value: "integrations" },
    { label: "CRM", value: "crm" },
    { label: "Smart Followups", value: "smart-followups" },
    { label: "Smart Compose", value: "smart-compose" }
  ]);

  useEffect(() => {
    if (userID !== undefined && userID !== null) {
      if (!privilegedUserIDs.includes(userID)) {
        // If the user is not privileged, restrict the segment options
        setSegmentOptions([
          { label: "Integrations", value: "integrations" }
        ]);
        setSelectedSection("integrations");
      }
      setIsLoading(false);
    }
  }, [userID]);

  // If user data is still loading, show a loading message or spinner
  if (isLoading) {
    return (
      <Box textAlign="center" p={6}>
        <Spinner size="lg" />
        <Text mt={4} fontSize="md" color="gray.500">
          Loading settings...
        </Text>
      </Box>
    );
  }

  // If userID is not available after loading, handle it
  if (!userID) {
    return (
      <Box textAlign="center" p={6}>
        <Text fontSize="md" color="gray.500">
          Unable to load user data.
        </Text>
      </Box>
    );
  }

  // Dummy search handler - can be implemented if needed
  const handleSearchChange = (query) => {
    // Implement search functionality if needed
    console.log("Search query:", query);
  };

  return (
    <Box px={4}>
      <PageHeader
        title="Settings"
        segmentOptions={segmentOptions.length > 1 ? segmentOptions : []}
        selectedSegment={selectedSection}
        onSegmentChange={setSelectedSection}
        showSearch={false}
        onSearchChange={handleSearchChange}
        additionalControls={
          <Flex justify="flex-end" flex="1">
            {selectedSection === "integrations" && (
              <Button
                leftIcon={<FaPlus />}
                colorScheme="blue"
                onClick={() => integrationsRef.current?.openModal()}
                borderRadius="md"
                size="md"
              >
                Create Integration
              </Button>
            )}
          </Flex>
        }
      />

      <Box mt={8}>
        {selectedSection === "integrations" && <IntegrationsSection ref={integrationsRef} />}
        {selectedSection === "crm" && privilegedUserIDs.includes(userID) && (
          <CRMSection />
        )}
        {selectedSection === "smart-followups" &&
          privilegedUserIDs.includes(userID) && <SmartFollowupsSection />}
        {selectedSection === "smart-compose" &&
          privilegedUserIDs.includes(userID) && <SmartComposeSection />}
      </Box>
    </Box>
  );
}
