import { useColorModeValue } from "@chakra-ui/react";

export const STYLE_CONSTANTS = {
  EXPANDED_WIDTH: "180px",
  COLLAPSED_WIDTH: "80px",
  ICON_SIZE: "30px",
  TRANSITION: "all 0.2s",
  BORDER_RADIUS: "lg",
  BREAKPOINT: "1200px",
  SPACING: {
    PADDING: "12px",
    MARGIN: "8px",
  },
  FONT: {
    SIZE: {
      NORMAL: "md",
      LARGE: "2xl",
    },
  },
  LINK_WIDTH: "85%"
};

export function useSidebarColors() {
  return {
    text: useColorModeValue("gray.700", "white"),
    textSecondary: useColorModeValue("gray.600", "white"),
    active: useColorModeValue("blue.500", "blue.200"),
    activeBg: useColorModeValue("blue.50", "whiteAlpha.100"),
    hoverBg: useColorModeValue("gray.100", "whiteAlpha.100"),
    sidebarBg: useColorModeValue("white", "navy.800"),
    shadow: useColorModeValue(
      "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
      "unset"
    ),
  };
}

export function activeRoute(pathname, routeName) {
  return pathname.includes(routeName);
} 