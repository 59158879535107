import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  Box,
  useColorModeValue,
  FormControl,
  FormLabel,
  Select,
  Input,
  Textarea,
  RadioGroup,
  Radio,
  Stack,
} from '@chakra-ui/react';
import {
  MdEmail,
  MdUpdate,
  MdSearch,
  MdSend,
  MdMessage,
  MdTask,
} from 'react-icons/md';

// Placeholder data for connected accounts
const CONNECTED_ACCOUNTS = [
  { id: 1, email: 'sales@company.com', type: 'Gmail' },
  { id: 2, email: 'support@company.com', type: 'Gmail' },
  { id: 3, email: 'marketing@company.com', type: 'Outlook' },
];

const actions = [
  {
    type: 'Email Send',
    icon: MdEmail,
    color: 'green',
    description: 'Send an email using a template',
  },
  {
    type: 'Lysto Entity Update',
    icon: MdUpdate,
    color: 'blue',
    description: 'Update a Lysto entity (Deal, Contact, etc.)',
  },
  {
    type: 'Lysto Entity Enrichment',
    icon: MdSearch,
    color: 'purple',
    description: 'Enrich entity data using AI',
  },
  {
    type: 'Send POST',
    icon: MdSend,
    color: 'orange',
    description: 'Send data to external service',
  },
  {
    type: 'Send Slack Message',
    icon: MdMessage,
    color: 'teal',
    description: 'Send a message to Slack',
  },
  {
    type: 'Create Linear Task',
    icon: MdTask,
    color: 'gray',
    description: 'Create a new task in Linear',
  },
];

const AddActionModal = ({ isOpen, onClose, onAdd }) => {
  const [selectedAction, setSelectedAction] = useState(null);
  const [step, setStep] = useState('select'); // 'select' or 'configure'
  const [emailConfig, setEmailConfig] = useState({
    fromAccount: 'last_sender', // defaults to last Lysto sender
    toAccounts: 'last_recipient', // defaults to last external recipient
    messageContext: 'deal_context', // defaults to Lysto Deal Context
    customPrompt: '', // defaults to empty
    sendPreference: 'inbox', // defaults to send to inbox
  });

  const cardBg = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const getHoverBg = (color) => useColorModeValue(`${color}.50`, `${color}.900`);

  const handleActionSelect = (action) => {
    setSelectedAction(action);
    setStep('configure');
  };

  const handleBack = () => {
    setStep('select');
    setSelectedAction(null);
  };

  const handleAdd = () => {
    if (selectedAction?.type === 'Email Send') {
      onAdd({
        type: selectedAction.type,
        config: emailConfig,
      });
    } else {
      onAdd({
        type: selectedAction.type,
        config: { template: 'Default Template' }, // Default config for other actions
      });
    }
    onClose();
    setStep('select');
    setSelectedAction(null);
  };

  const renderEmailConfig = () => (
    <VStack align="stretch" spacing={6}>
      <FormControl>
        <FormLabel>From Account</FormLabel>
        <Select
          value={emailConfig.fromAccount}
          onChange={(e) =>
            setEmailConfig({ ...emailConfig, fromAccount: e.target.value })
          }
        >
          <option value="last_sender">Last Lysto Sender</option>
          {CONNECTED_ACCOUNTS.map((account) => (
            <option key={account.id} value={account.id}>
              {account.email}
            </option>
          ))}
        </Select>
      </FormControl>

      <FormControl>
        <FormLabel>To Account(s)</FormLabel>
        <Select
          value={emailConfig.toAccounts}
          onChange={(e) =>
            setEmailConfig({ ...emailConfig, toAccounts: e.target.value })
          }
        >
          <option value="last_recipient">Last External Recipient</option>
          <option value="custom">Specify Recipients</option>
        </Select>
        {emailConfig.toAccounts === 'custom' && (
          <Input
            placeholder="Enter email addresses (comma-separated)"
            mt={2}
            value={emailConfig.customRecipients || ''}
            onChange={(e) =>
              setEmailConfig({
                ...emailConfig,
                customRecipients: e.target.value,
              })
            }
          />
        )}
      </FormControl>

      <FormControl>
        <FormLabel>Message Context</FormLabel>
        <Select
          value={emailConfig.messageContext}
          onChange={(e) =>
            setEmailConfig({ ...emailConfig, messageContext: e.target.value })
          }
        >
          <option value="deal_context">Lysto Deal Context</option>
          <option value="contact_context">Contact Context</option>
          <option value="custom_context">Custom Context</option>
        </Select>
      </FormControl>

      <FormControl>
        <FormLabel>Custom Prompt (Optional)</FormLabel>
        <Textarea
          placeholder="Enter a custom prompt or email example to guide the generation..."
          value={emailConfig.customPrompt}
          onChange={(e) =>
            setEmailConfig({ ...emailConfig, customPrompt: e.target.value })
          }
          rows={4}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Send Preference</FormLabel>
        <RadioGroup
          value={emailConfig.sendPreference}
          onChange={(value) =>
            setEmailConfig({ ...emailConfig, sendPreference: value })
          }
        >
          <Stack direction="column" spacing={2}>
            <Radio value="inbox">Send to Inbox (Review before sending)</Radio>
            <Radio value="direct">Send Directly</Radio>
          </Stack>
        </RadioGroup>
      </FormControl>
    </VStack>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={step === 'select' ? '4xl' : 'xl'}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {step === 'select' ? 'Select Action' : 'Configure Action'}
        </ModalHeader>
        <ModalCloseButton />
        
        <ModalBody>
          {step === 'select' ? (
            <Grid
              templateColumns="repeat(auto-fill, minmax(250px, 1fr))"
              gap={4}
              p={2}
            >
              {actions.map((action) => (
                <Box
                  key={action.type}
                  bg={cardBg}
                  borderWidth="1px"
                  borderColor={borderColor}
                  borderRadius="lg"
                  p={4}
                  cursor="pointer"
                  onClick={() => handleActionSelect(action)}
                  _hover={{ bg: getHoverBg(action.color) }}
                  transition="all 0.2s"
                >
                  <VStack align="start" spacing={3}>
                    <Icon
                      as={action.icon}
                      boxSize={6}
                      color={`${action.color}.500`}
                    />
                    <Box>
                      <Text fontWeight="600">{action.type}</Text>
                      <Text fontSize="sm" color="gray.500">
                        {action.description}
                      </Text>
                    </Box>
                  </VStack>
                </Box>
              ))}
            </Grid>
          ) : (
            <VStack align="stretch" spacing={4}>
              {selectedAction?.type === 'Email Send' ? (
                renderEmailConfig()
              ) : (
                <Text>
                  Configure {selectedAction?.type} action settings
                  {/* Add configuration form here */}
                </Text>
              )}
            </VStack>
          )}
        </ModalBody>

        <ModalFooter>
          {step === 'configure' && (
            <Button variant="ghost" mr={3} onClick={handleBack}>
              Back
            </Button>
          )}
          <Button
            variant="ghost"
            mr={3}
            onClick={() => {
              onClose();
              setStep('select');
              setSelectedAction(null);
            }}
          >
            Cancel
          </Button>
          {step === 'configure' && (
            <Button colorScheme="blue" onClick={handleAdd}>
              Add Action
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

AddActionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default AddActionModal; 