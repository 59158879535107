import { useState, useEffect } from "react";
import axios from "axios";
import { useAuthData } from "auth-context";
import { useAuth0 } from "@auth0/auth0-react";
import { useGoogleIntegrations } from "./useGoogleIntegrations";
import { useToast } from "@chakra-ui/react";

const target =
  process.env.REACT_APP_NODE_ENV === "local"
    ? "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net"
    : "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

export const useIntegrations = () => {
  const userData = useAuthData();
  const accountID = userData?.account_ID;
  const userID = userData?.user_ID;
  const { getAccessTokenSilently } = useAuth0();
  const [connectingService, setConnectingService] = useState(null);
  const toast = useToast();
  const { integrationStatus, refetch: refetchGoogleStatus } = useGoogleIntegrations();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [integrations, setIntegrations] = useState({
    gmail_integrations: [],
    calendar_integrations: [],
  });

  useEffect(() => {
    const checkConnectionStatus = async () => {
      if (!accountID || !userID) return;

      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: "read:calendar",
          },
        });

        // Only check status if there's a connecting service
        if (connectingService) {
          // Use 'google' as the service for both gmail and calendar
          const service = ['gmail', 'calendar'].includes(connectingService) ? 'google' : connectingService;
          
          const response = await axios.get(
            `${target}/accounts/${accountID}/${service}/status`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          if (!response.data.connected) {
            console.log(`${connectingService} not connected`);
          }
        }
      } catch (err) {
        console.error("Error checking connection status:", err);
      }
    };

    checkConnectionStatus();
  }, [accountID, userID, getAccessTokenSilently, integrationStatus, connectingService]);

  useEffect(() => {
    const fetchIntegrations = async () => {
      try {
        const response = await fetch(
          `${target}/accounts/${userData.account_ID}/google/status`
        );
        if (!response.ok) throw new Error('Failed to fetch integrations');
        const data = await response.json();
        setIntegrations(data);
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    if (userData?.account_ID) {
      fetchIntegrations();
    }
  }, [userData?.account_ID]);

  const handleAuthenticationComplete = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          scope: "read:calendar",
        },
      });

      // Fetch the latest integrations status
      const response = await axios.get(
        `${target}/accounts/${accountID}/google/status`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      
      const currentIntegrations = response.data;
      
      if (connectingService === "gmail" || connectingService === "calendar") {
        await new Promise(resolve => setTimeout(resolve, 2000));
        await refetchGoogleStatus();
        
        const currentEmails = currentIntegrations?.[`${connectingService}_integrations`]?.map(integration => integration.email) || [];
        const previousEmails = integrations?.[`${connectingService}_integrations`]?.map(integration => integration.email) || [];
        
        const hasNewEmail = currentEmails.some(email => !previousEmails.includes(email));
        
        if (!hasNewEmail) {
          throw new Error("No new integration was detected. Please try again and select a different Google account.");
        }
      } else {
        // Check if we have any integrations in the response
        const hasIntegrations = currentIntegrations?.gmail_integrations?.length > 0 || 
                              currentIntegrations?.calendar_integrations?.length > 0;
        
        if (!hasIntegrations) {
          throw new Error("No active integrations found. Please try connecting again.");
        }
      }
      
      // Update the integrations state with the latest data
      setIntegrations(currentIntegrations);

      toast({
        title: "Connected successfully",
        description: `New account has been connected to Lysto.`,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } catch (err) {
      console.error("Error verifying connection:", err);
      toast({
        title: "Connection failed",
        description: err.message,
        status: "error",
        duration: 7000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setConnectingService(null);
    }
  };

  const initiateConnection = async (service, permissionType) => {
    if (!accountID || !userID) return;

    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          scope: "read:calendar",
        },
      });

      const screenWidth = window.screen.width;
      const screenHeight = window.screen.height;
      const popupWidth = 500;
      const popupHeight = 600;
      const left = screenWidth / 2 - popupWidth / 2;
      const top = screenHeight / 2 - popupHeight / 2;

      const popupWindow = window.open(
        "",
        "authWindow",
        `width=${popupWidth},height=${popupHeight},top=${top},left=${left}`
      );

      if (!popupWindow) {
        throw new Error("Popup was blocked. Please allow popups for this site.");
      }

      const response = await axios.get(
        `${target}/accounts/${accountID}/users/${userID}/${service}/auth?prompt=select_account&access_type=offline&permission_type=${permissionType}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const authURL = response.data.auth_url;

      if (authURL) {
        const finalAuthURL = new URL(authURL);
        finalAuthURL.searchParams.set('prompt', 'select_account');
        finalAuthURL.searchParams.set('access_type', 'offline');
        finalAuthURL.searchParams.delete('approval_prompt');
        popupWindow.location.href = finalAuthURL.toString();
      }

      const pollTimer = setInterval(() => {
        if (popupWindow.closed) {
          clearInterval(pollTimer);
          handleAuthenticationComplete();
        }
      }, 500);

      setConnectingService(service);
    } catch (err) {
      console.error("Error Authenticating:", err);
      toast({
        title: "Connection failed",
        description: err.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setConnectingService(null);
    }
  };

  const sortedIntegrations = [
    ...(integrations.gmail_integrations || []).map(integration => ({
      type: 'gmail',
      connection: {
        email: integration.email,
        expiration: integration.expiration,
        permission_type: integration.permission_type,
        created_at: integration.created_at,
        user_id: integration.user_id,
      }
    })),
    ...(integrations.calendar_integrations || []).map(integration => ({
      type: 'calendar',
      connection: {
        email: integration.email,
        expires_at: integration.expires_at,
        permission_type: integration.permission_type,
        created_at: integration.created_at,
        user_id: integration.user_id,
      }
    }))
  ].sort((a, b) => {
    const aActive = a.type === 'gmail'
      ? new Date(a.connection.expiration * 1000) > new Date()
      : new Date(a.connection.expires_at) > new Date();
    const bActive = b.type === 'gmail'
      ? new Date(b.connection.expiration * 1000) > new Date()
      : new Date(b.connection.expires_at) > new Date();
    return bActive - aActive;
  });

  return {
    isLoading,
    error,
    sortedIntegrations,
    initiateConnection,
    connectingService
  };
}; 