// src/components/Inbox/EmailDetailsSection.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Input, VStack } from '@chakra-ui/react';
import EditableEmailRecipients from './EditableEmailRecipients';

const EmailDetailsSection = ({ emailSubject, onSubjectChange, recipients, onRecipientsChange, accountID }) => (
  <VStack spacing={4} align="stretch">
    <Input
      value={emailSubject}
      onChange={onSubjectChange}
      placeholder="Enter email subject"
      variant="outline"
      bg="white"
      borderRadius="md"
      borderWidth="1px"
      borderColor="gray.300"
      _hover={{ borderColor: 'gray.400' }}
      _focus={{ borderColor: 'gray.500', boxShadow: 'outline' }}
    />
    <Box
      borderWidth="1px"
      borderColor="gray.300"
      borderRadius="md"
      p={4}
    >
      <EditableEmailRecipients
        recipients={recipients}
        onChange={onRecipientsChange}
        accountID={accountID}
      />
    </Box>
  </VStack>
);

EmailDetailsSection.propTypes = {
  emailSubject: PropTypes.string.isRequired,
  onSubjectChange: PropTypes.func.isRequired,
  recipients: PropTypes.arrayOf(PropTypes.shape({
    email: PropTypes.string.isRequired,
    name: PropTypes.string,
    recipient_type: PropTypes.oneOf(['to', 'cc', 'bcc']).isRequired
  })).isRequired,
  onRecipientsChange: PropTypes.func.isRequired,
  accountID: PropTypes.number.isRequired,
};

export default EmailDetailsSection;