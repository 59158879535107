import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  useToast,
  FormErrorMessage,
  Select,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useAuthData } from 'auth-context';
import axios from 'axios';
import useFetchAccounts from '../hooks/useFetchAccounts';

const EditContactModal = ({ isOpen, onClose, contact }) => {
  const toast = useToast();
  const { getAccessToken } = useAuthData();
  const userData = useAuthData();
  const accountID = userData?.account_ID;
  const { accounts, isLoading: isLoadingAccounts } = useFetchAccounts(accountID, "Lead");

  const [formData, setFormData] = useState({
    name: '',
    last_name: '',
    email: '',
    phone: '',
    title: '',
    linkedin: '',
    city: '',
    state: '',
    country: '',
    priority: '',
    type: '',
    source: '',
    status: '',
    crm_account_id: '',
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (contact) {
      setFormData({
        name: contact.name || '',
        last_name: contact.last_name || '',
        email: contact.email || '',
        phone: contact.phone || '',
        title: contact.title || '',
        linkedin: contact.linkedin || '',
        city: contact.city || '',
        state: contact.state || '',
        country: contact.country || '',
        priority: contact.priority || '',
        type: contact.type || '',
        source: contact.source || '',
        status: contact.status || '',
        crm_account_id: contact.crm_account_id || '',
      });
    }
  }, [contact]);

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.name.trim()) {
      newErrors.name = 'First name is required';
    }
    if (!formData.last_name.trim()) {
      newErrors.last_name = 'Last name is required';
    }
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Invalid email format';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setIsSubmitting(true);
    try {
      const accessToken = await getAccessToken();
      const target = process.env.REACT_APP_API_BASE_URL || 'https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net';

      await axios.put(
        `${target}/accounts/${accountID}/contacts/${contact.id}`,
        {
          ...formData,
          crm_account_id: formData.crm_account_id ? Number(formData.crm_account_id) : null,
        },
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      toast({
        title: 'Contact updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      onClose();
    } catch (error) {
      console.error('Error updating contact:', error);
      toast({
        title: 'Error updating contact',
        description: error.response?.data?.message || 'An error occurred while updating the contact',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Contact</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Associated Account</FormLabel>
              <Select
                name="crm_account_id"
                value={formData.crm_account_id}
                onChange={handleInputChange}
                placeholder="Select an account (optional)"
                isDisabled={isLoadingAccounts}
              >
                {[...accounts]
                  .sort((a, b) => a.name?.localeCompare(b.name))
                  .map((account) => (
                    <option key={account.id} value={account.id}>
                      {account.name}
                    </option>
                  ))}
              </Select>
            </FormControl>

            <FormControl isRequired isInvalid={!!errors.name}>
              <FormLabel>First Name</FormLabel>
              <Input
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Enter first name"
              />
              <FormErrorMessage>{errors.name}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={!!errors.last_name}>
              <FormLabel>Last Name</FormLabel>
              <Input
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
                placeholder="Enter last name"
              />
              <FormErrorMessage>{errors.last_name}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={!!errors.email}>
              <FormLabel>Email</FormLabel>
              <Input
                name="email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Enter email"
              />
              <FormErrorMessage>{errors.email}</FormErrorMessage>
            </FormControl>

            <FormControl>
              <FormLabel>Phone</FormLabel>
              <Input
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="Enter phone number"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Title</FormLabel>
              <Input
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                placeholder="Enter job title"
              />
            </FormControl>

            <FormControl>
              <FormLabel>LinkedIn</FormLabel>
              <Input
                name="linkedin"
                value={formData.linkedin}
                onChange={handleInputChange}
                placeholder="Enter LinkedIn profile URL"
              />
            </FormControl>

            <FormControl>
              <FormLabel>City</FormLabel>
              <Input
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                placeholder="Enter city"
              />
            </FormControl>

            <FormControl>
              <FormLabel>State</FormLabel>
              <Input
                name="state"
                value={formData.state}
                onChange={handleInputChange}
                placeholder="Enter state"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Country</FormLabel>
              <Input
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                placeholder="Enter country"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Priority</FormLabel>
              <Select
                name="priority"
                value={formData.priority}
                onChange={handleInputChange}
                placeholder="Select priority"
              >
                <option value="VIP">VIP</option>
                <option value="High">High</option>
                <option value="Medium">Medium</option>
                <option value="Low">Low</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Type</FormLabel>
              <Select
                name="type"
                value={formData.type}
                onChange={handleInputChange}
                placeholder="Select type"
              >
                <option value="Lead">Lead</option>
                <option value="Customer">Customer</option>
                <option value="Partner">Partner</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Source</FormLabel>
              <Select
                name="source"
                value={formData.source}
                onChange={handleInputChange}
                placeholder="Select source"
              >
                <option value="manual">Manual</option>
                <option value="import">Import</option>
                <option value="website">Website</option>
                <option value="referral">Referral</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Status</FormLabel>
              <Select
                name="status"
                value={formData.status}
                onChange={handleInputChange}
                placeholder="Select status"
              >
                <option value="Inbound">Inbound</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </Select>
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            onClick={handleSubmit}
            isLoading={isSubmitting}
          >
            Save Changes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

EditContactModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  contact: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    title: PropTypes.string,
    linkedin: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    priority: PropTypes.string,
    type: PropTypes.string,
    source: PropTypes.string,
    status: PropTypes.string,
    crm_account_id: PropTypes.string,
  }).isRequired,
};

export default EditContactModal; 