// /Users/kylearchie/Documents/GitHub/lysto-fe/src/views/lysto/Spark/index.jsx
import React, { useState, useEffect } from 'react';
import {
  Box,
  SimpleGrid,
  Alert,
  AlertIcon,
  AlertDescription,
  useToast,
  Skeleton,
  SkeletonText,
  HStack,
  VStack,
  Flex,
} from '@chakra-ui/react';
import { useAuthData } from 'auth-context';
import PageHeader from '../Shared/PageHeader';
import useFetchSparks from '../hooks/useFetchSparks';
import useCreateDealFromSpark from '../hooks/useCreateDealFromSpark';
import SparkCard from './SparkCard';
import PaginationControls from '../Shared/PaginationControls';
import { useFilterState } from '../hooks/useFilterState';
import { useDebounce } from 'use-debounce';

const PageHeaderSkeleton = () => (
  <Box w="100%" mb={6}>
    {/* Header */}
    <Flex
      flexDirection="row"
      mb="4"
      align="center"
      justify="space-between"
      w="100%"
    >
      <Skeleton height="36px" width="120px" />
      
      {/* Profile Skeleton */}
      <HStack spacing={3} p={2}>
        <Skeleton height="32px" width="32px" borderRadius="3xl" />
        <Skeleton height="20px" width="120px" display={{ base: "none", md: "block" }} />
      </HStack>
    </Flex>

    {/* Controls Container */}
    <Flex
      direction={{ base: "column", md: "row" }}
      gap={{ base: 3, md: 6 }}
      justify="space-between"
      align={{ base: "stretch", md: "center" }}
      w="100%"
      mt={{ base: 2, md: 0 }}
    >
      {/* Segment Controls Skeleton */}
      <HStack spacing={0}>
        <Skeleton height="40px" width="120px" borderRadius="md" />
        <Skeleton height="40px" width="120px" borderRadius="md" ml="-1px" />
      </HStack>
    </Flex>
  </Box>
);

const SparkCardSkeleton = () => (
  <Box
    bg="white"
    p={6}
    borderRadius="md"
    boxShadow="sm"
    borderWidth="1px"
    borderColor="gray.200"
    height="100%"
  >
    <VStack spacing={4} align="stretch">
      {/* Header */}
      <HStack justify="space-between">
        <Skeleton height="24px" width="200px" />
        <HStack spacing={2}>
          <Skeleton height="32px" width="70px" />
          <Skeleton height="32px" width="90px" />
        </HStack>
      </HStack>
      
      {/* Subject line */}
      <Skeleton height="20px" width="80%" />
      
      {/* Tags */}
      <HStack spacing={4}>
        <Skeleton height="24px" width="120px" borderRadius="full" />
        <Skeleton height="24px" width="150px" borderRadius="full" />
        <Skeleton height="24px" width="130px" borderRadius="full" />
      </HStack>
      
      {/* AI Recommendation */}
      <Box>
        <Skeleton height="20px" width="150px" mb={2} />
        <SkeletonText noOfLines={2} spacing={2} />
      </Box>
      
      {/* Previous Messages */}
      <Box>
        <Skeleton height="20px" width="150px" mb={2} />
        <VStack spacing={3} align="stretch">
          <SkeletonText noOfLines={3} spacing={2} />
          <SkeletonText noOfLines={3} spacing={2} />
          <SkeletonText noOfLines={3} spacing={2} />
        </VStack>
      </Box>
    </VStack>
  </Box>
);

const Spark = () => {
  const userData = useAuthData();
  const accountID = userData?.account_ID;
  const userID = userData?.user_ID;
  const toast = useToast();

  const [currentFilter, setCurrentFilter] = useFilterState("spark-filter", "recommended");
  const [currentPage, setCurrentPage] = useState(1);
  const [localPage, setLocalPage] = useState(1);
  const [searchQuery, setSearchQuery] = useFilterState("spark-search-query", "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  const createDealMutation = useCreateDealFromSpark();

  const { 
    sparks, 
    loading, 
    error, 
    hasMore, 
    handleIgnoreSpark 
  } = useFetchSparks(
    accountID, 
    userID, 
    currentPage, 
    100, // Increased to get more sparks for client-side search
    currentFilter,
    debouncedSearchQuery
  );

  // Generate search suggestions based on recipients
  const searchSuggestions = React.useMemo(() => {
    if (!debouncedSearchQuery || !sparks) return [];
    
    const suggestions = new Set();
    const searchLower = debouncedSearchQuery.toLowerCase();
    
    sparks.forEach(spark => {
      spark.activities?.forEach(activity => {
        if (!activity.ActivityData) return;
        
        try {
          const activityData = typeof activity.ActivityData === 'string' 
            ? JSON.parse(activity.ActivityData) 
            : activity.ActivityData;

          const recipients = activityData.data?.recipients || [];
          recipients.forEach(recipient => {
            if (recipient.name?.toLowerCase().includes(searchLower)) {
              suggestions.add(JSON.stringify({ 
                id: `${spark.crm_account_id}-${recipient.email}`,
                label: `${recipient.name} (${recipient.email})`
              }));
            } else if (recipient.email?.toLowerCase().includes(searchLower)) {
              suggestions.add(JSON.stringify({ 
                id: `${spark.crm_account_id}-${recipient.email}`,
                label: `${recipient.email} (${recipient.name || 'No name'})`
              }));
            }
          });
        } catch (e) {
          console.error('Error parsing activity data:', e);
        }
      });

      // Add domain and reason suggestions if they match
      if (spark.crm_domain?.toLowerCase().includes(searchLower)) {
        suggestions.add(JSON.stringify({ 
          id: `domain-${spark.crm_account_id}`,
          label: `Domain: ${spark.crm_domain}`
        }));
      }
      if (spark.reason?.toLowerCase().includes(searchLower)) {
        suggestions.add(JSON.stringify({ 
          id: `reason-${spark.crm_account_id}`,
          label: `Reason: ${spark.reason}`
        }));
      }
    });

    return Array.from(suggestions).map(s => JSON.parse(s));
  }, [sparks, debouncedSearchQuery]);

  // Reset pages when filter changes
  useEffect(() => {
    setCurrentPage(1);
    setLocalPage(1);
  }, [currentFilter, debouncedSearchQuery]);

  // After the localPage state is defined, add a useEffect to scroll to top on page change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [localPage]);

  // Calculate indices for local pagination
  const startIndex = (localPage - 1) * 2;
  const endIndex = startIndex + 2;
  const visibleSparks = sparks.slice(startIndex, endIndex);

  // Calculate if we need to fetch more items
  const shouldFetchMore = endIndex >= sparks.length && hasMore;

  const totalLocalPages = Math.ceil(sparks.length / 2) || 1;

  const handleNextPage = () => {
    if (shouldFetchMore) {
      setCurrentPage(prev => prev + 1);
      setLocalPage(prev => prev + 1);
    } else if (endIndex < sparks.length) {
      setLocalPage(prev => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    setLocalPage(prev => Math.max(prev - 1, 1));
  };

  const handlePageSelect = (pageNum) => {
    const targetStartIndex = (pageNum - 1) * 2;
    const requiredFetchPage = Math.floor(targetStartIndex / 100) + 1;
    
    if (requiredFetchPage > currentPage && hasMore) {
      setCurrentPage(requiredFetchPage);
    }
    setLocalPage(pageNum);
  };

  const handleAddDeal = async (formData, sparkData) => {
    try {
      console.log('Creating deal with data:', {
        accountId: accountID,
        userId: userID,
        formData,
        sparkData
      });

      await createDealMutation.mutateAsync({
        accountId: accountID,
        userId: userID,
        formData: {
          ...formData,
          // Ensure these fields exist even if empty
          accountName: formData.accountName || '',
          accountDomain: formData.accountDomain || '',
          accountDescription: formData.accountDescription || '',
          industry: formData.industry || '',
          city: formData.city || '',
          state: formData.state || '',
          country: formData.country || '',
          address: formData.address || '',
          linkedIn: formData.linkedIn || '',
          contacts: formData.contacts || [],
          dealName: formData.dealName || '',
          dealSource: formData.dealSource || '',
          stage: formData.stage || 'new_lead',
          dealDescription: formData.dealDescription || '',
          dealPriority: formData.dealPriority || 'medium',
        },
        sparkData: {
          ...sparkData,
          // Ensure crm_account_id is included and is a number
          crm_account_id: parseInt(sparkData.crm_account_id, 10) || null,
        }
      });

    } catch (error) {
      console.error('Error creating deal:', error);
      toast({
        title: "Error creating deal",
        description: error.message || "An unexpected error occurred",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const segmentOptions = [
    { label: "Recommended", value: "recommended" },
    { label: "Recent", value: "all" },
  ];

  if (loading && sparks.length === 0) {
    return (
      <Box px={4}>
        <PageHeaderSkeleton />
        <SimpleGrid columns={{ base: 1, md: 1, lg: 2, xl: 2 }} spacing={6}>
          <SparkCardSkeleton />
          <SparkCardSkeleton />
        </SimpleGrid>
      </Box>
    );
  }

  if (error) {
    return (
      <Box px={6}>
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      </Box>
    );
  }

  return (
    <Box px={4} pb={24} transition="all 0.2s">
      <PageHeader
        title="Spark"
        segmentOptions={segmentOptions}
        selectedSegment={currentFilter}
        onSegmentChange={setCurrentFilter}
        searchPlaceholder="Search by recipient"
        onSearchChange={setSearchQuery}
        searchQuery={searchQuery}
        searchSuggestions={searchSuggestions}
        onSuggestionSelect={() => {
          // Handle suggestion selection if needed
          // For now, the search will automatically filter based on the selected suggestion
        }}
        showSearch={true}
      />

      {(!loading && sparks.length === 0) ? (
        <Alert status="info" borderRadius="md">
          <AlertIcon />
          <AlertDescription>
            No potential deals found matching your criteria.
          </AlertDescription>
        </Alert>
      ) : (
        <SimpleGrid 
          columns={{ base: 1, md: 1, lg: 2, xl: 2 }} 
          spacing={6}
        >
          {visibleSparks.map((spark, index) => (
            <SparkCard
              key={`${currentPage}-${spark.thread_id}-${index}`}
              spark={spark}
              onAddDeal={handleAddDeal}
              onIgnore={handleIgnoreSpark}
              isCreatingDeal={createDealMutation.isLoading}
              currentPage={localPage}
              isPageLoading={loading}
            />
          ))}
        </SimpleGrid>
      )}

      {sparks.length > 0 && totalLocalPages > 1 && (
        <Box mt={6} mb={8}>
          <PaginationControls
            currentPage={localPage}
            onPrevious={handlePreviousPage}
            onNext={handleNextPage}
            disableNext={!hasMore && endIndex >= sparks.length}
            disablePrevious={localPage === 1}
            totalPages={totalLocalPages}
            onPageSelect={handlePageSelect}
          />
        </Box>
      )}
    </Box>
  );
};

export default Spark;