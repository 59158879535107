import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  SimpleGrid,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { Plus } from 'lucide-react';
import GoBackHeader from '../Shared/GoBackHeader';
import TriggerCard from './TriggerCard';
import ActionCard from './ActionCard';
import AddTriggerModal from './AddTriggerModal';
import AddActionModal from './AddActionModal';
import PageHeader from '../Shared/PageHeader';

const AgentDetail = () => {
  const navigate = useNavigate();
  const { agentId } = useParams();
  const isNewAgent = agentId === 'new';
  
  // Add state for triggers and actions
  const [agentTriggers, setAgentTriggers] = useState([]);
  const [agentActions, setAgentActions] = useState([]);
  
  const {
    isOpen: isTriggerModalOpen,
    onOpen: onTriggerModalOpen,
    onClose: onTriggerModalClose,
  } = useDisclosure();
  
  const {
    isOpen: isActionModalOpen,
    onOpen: onActionModalOpen,
    onClose: onActionModalClose,
  } = useDisclosure();

  const cardBg = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const textColor = useColorModeValue('gray.600', 'gray.300');
  const headingColor = useColorModeValue('gray.800', 'white');

  const handleGoBack = () => {
    navigate('/admin/agents');
  };

  const noop = () => undefined;

  const EmptyState = ({ type }) => (
    <Box 
      py={6} 
      textAlign="center"
      color={textColor}
      borderWidth="1px"
      borderStyle="dashed"
      borderColor={borderColor}
      borderRadius="md"
    >
      <Text fontSize="sm">
        No {type} configured yet. Click the button below to add one.
      </Text>
    </Box>
  );

  const handleAddTrigger = (trigger) => {
    // Add a unique ID to the trigger
    const newTrigger = {
      ...trigger,
      id: Date.now(), // Simple way to generate unique IDs
    };
    setAgentTriggers([...agentTriggers, newTrigger]);
    onTriggerModalClose();
  };

  const handleDeleteTrigger = (triggerId) => {
    setAgentTriggers(agentTriggers.filter(trigger => trigger.id !== triggerId));
  };

  const handleAddAction = (action) => {
    const newAction = {
      ...action,
      id: Date.now(),
    };
    setAgentActions([...agentActions, newAction]);
    onActionModalClose();
  };

  const handleDeleteAction = (actionId) => {
    setAgentActions(agentActions.filter(action => action.id !== actionId));
  };

  return (
    <Box px={4}>
      {/* Main Header */}
      <PageHeader
        title="Agents"
        segmentOptions={[]}
        selectedSegment=""
        onSegmentChange={noop}
        showSearch={false}
        isDetailView
      />

      {/* Go Back Header */}
      <Box mb={2}>
        <GoBackHeader
          onGoBack={handleGoBack}
          title={isNewAgent ? 'Create New Agent' : 'Edit Agent'}
        />
      </Box>

      <SimpleGrid
        columns={{ base: 1, lg: 2 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        {/* Triggers Card */}
        <Box
          bg={cardBg}
          borderWidth="1px"
          borderColor={borderColor}
          borderRadius="lg"
          p={6}
          height="fit-content"
        >
          <VStack align="stretch" spacing={4}>
            <Text fontSize="lg" fontWeight="600" color={headingColor}>
              Triggers
            </Text>
            <Text fontSize="sm" color={textColor}>
              Define when this agent should be activated
            </Text>

            <VStack align="stretch" spacing={3} mt={2}>
              {agentTriggers.length > 0 ? (
                agentTriggers.map((trigger) => (
                  <TriggerCard
                    key={trigger.id}
                    trigger={trigger}
                    onDelete={() => handleDeleteTrigger(trigger.id)}
                  />
                ))
              ) : (
                <EmptyState type="triggers" />
              )}
            </VStack>

            <Button
              leftIcon={<Plus size={20} />}
              variant="outline"
              onClick={onTriggerModalOpen}
              size="sm"
              colorScheme="gray"
              mt={2}
            >
              Add Trigger
            </Button>
          </VStack>
        </Box>

        {/* Actions Card */}
        <Box
          bg={cardBg}
          borderWidth="1px"
          borderColor={borderColor}
          borderRadius="lg"
          p={6}
          height="fit-content"
        >
          <VStack align="stretch" spacing={4}>
            <Text fontSize="lg" fontWeight="600" color={headingColor}>
              Actions
            </Text>
            <Text fontSize="sm" color={textColor}>
              Define what actions to take when triggers are activated
            </Text>

            <VStack align="stretch" spacing={3} mt={2}>
              {agentActions.length > 0 ? (
                agentActions.map((action) => (
                  <ActionCard
                    key={action.id}
                    action={action}
                    onDelete={() => handleDeleteAction(action.id)}
                  />
                ))
              ) : (
                <EmptyState type="actions" />
              )}
            </VStack>

            <Button
              leftIcon={<Plus size={20} />}
              variant="outline"
              onClick={onActionModalOpen}
              size="sm"
              colorScheme="gray"
              mt={2}
            >
              Add Action
            </Button>
          </VStack>
        </Box>
      </SimpleGrid>

      {/* Modals */}
      <AddTriggerModal
        isOpen={isTriggerModalOpen}
        onClose={onTriggerModalClose}
        onAdd={handleAddTrigger}
      />

      <AddActionModal
        isOpen={isActionModalOpen}
        onClose={onActionModalClose}
        onAdd={handleAddAction}
      />
    </Box>
  );
};

export default AgentDetail; 