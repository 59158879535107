import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  useToast,
  FormErrorMessage,
  Select,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useAuthData } from 'auth-context';
import axios from 'axios';

const EditAccountModal = ({ isOpen, onClose, account }) => {
  const toast = useToast();
  const { getAccessToken } = useAuthData();
  const userData = useAuthData();
  const accountID = userData?.account_ID;

  const [formData, setFormData] = useState({
    name: '',
    website: '',
    email: '',
    linkedin: '',
    phone: '',
    city: '',
    state: '',
    country: '',
    address: '',
    size: null,
    type: 'Lead',
    description: '',
    industry: '',
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (account) {
      setFormData({
        name: account.name || '',
        website: account.website || '',
        email: account.email || '',
        linkedin: account.linkedin || '',
        phone: account.phone || '',
        city: account.city || '',
        state: account.state || '',
        country: account.country || '',
        address: account.address || '',
        size: account.size ?? null,
        type: account.type || 'Lead',
        description: account.description || '',
        industry: account.industry || '',
      });
    }
  }, [account]);

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.name.trim()) {
      newErrors.name = 'Account name is required';
    }
    if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Invalid email format';
    }
    if (formData.website && !/^https?:\/\/.*/.test(formData.website)) {
      newErrors.website = 'Website must start with http:// or https://';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const handleNumberInputChange = (name, value) => {
    setFormData(prev => ({
      ...prev,
      [name]: value === '' ? null : Number(value)
    }));
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setIsSubmitting(true);
    try {
      const accessToken = await getAccessToken();
      const target = process.env.REACT_APP_API_BASE_URL || 'https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net';

      await axios.put(
        `${target}/accounts/${accountID}/crm-accounts/${account.id}`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      toast({
        title: 'Account updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      onClose();
    } catch (error) {
      console.error('Error updating account:', error);
      toast({
        title: 'Error updating account',
        description: error.response?.data?.message || 'An error occurred while updating the account',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Account</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl isRequired isInvalid={!!errors.name}>
              <FormLabel>Account Name</FormLabel>
              <Input
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Enter account name"
              />
              <FormErrorMessage>{errors.name}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.website}>
              <FormLabel>Website</FormLabel>
              <Input
                name="website"
                value={formData.website}
                onChange={handleInputChange}
                placeholder="Enter website URL"
              />
              <FormErrorMessage>{errors.website}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.email}>
              <FormLabel>Email</FormLabel>
              <Input
                name="email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Enter email"
              />
              <FormErrorMessage>{errors.email}</FormErrorMessage>
            </FormControl>

            <FormControl>
              <FormLabel>LinkedIn</FormLabel>
              <Input
                name="linkedin"
                value={formData.linkedin}
                onChange={handleInputChange}
                placeholder="Enter LinkedIn URL"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Phone</FormLabel>
              <Input
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="Enter phone number"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Address</FormLabel>
              <Input
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                placeholder="Enter address"
              />
            </FormControl>

            <FormControl>
              <FormLabel>City</FormLabel>
              <Input
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                placeholder="Enter city"
              />
            </FormControl>

            <FormControl>
              <FormLabel>State</FormLabel>
              <Input
                name="state"
                value={formData.state}
                onChange={handleInputChange}
                placeholder="Enter state"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Country</FormLabel>
              <Input
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                placeholder="Enter country"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Company Size</FormLabel>
              <NumberInput
                value={formData.size ?? ''}
                onChange={(value) => handleNumberInputChange('size', value)}
                min={0}
              >
                <NumberInputField placeholder="Enter company size" />
              </NumberInput>
            </FormControl>

            <FormControl>
              <FormLabel>Type</FormLabel>
              <Select
                name="type"
                value={formData.type}
                onChange={handleInputChange}
              >
                <option value="Lead">Lead</option>
                <option value="Customer">Customer</option>
                <option value="Partner">Partner</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Industry</FormLabel>
              <Input
                name="industry"
                value={formData.industry}
                onChange={handleInputChange}
                placeholder="Enter industry"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Description</FormLabel>
              <Input
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                placeholder="Enter description"
              />
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            onClick={handleSubmit}
            isLoading={isSubmitting}
          >
            Save Changes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

EditAccountModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    website: PropTypes.string,
    email: PropTypes.string,
    linkedin: PropTypes.string,
    phone: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    address: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    type: PropTypes.string,
    description: PropTypes.string,
    industry: PropTypes.string,
  }).isRequired,
};

export default EditAccountModal; 