// /Users/kylearchie/Documents/GitHub/lysto-fe/src/views/lysto/Inbox/index.jsx
import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAuthData } from "auth-context";
import InboxTable from "./InboxTable";
import PaginationControls from "../Shared/PaginationControls";
import PageHeader from "../Shared/PageHeader";
import TableSkeleton from "../Shared/TableSkeleton";
import { useFetchInbox } from "../hooks/useFetchInbox";
import { useDebounce } from "use-debounce";
import { useFilterState } from "../hooks/useFilterState";

const ITEMS_PER_PAGE = 10;

const Inbox = () => {
  const userData = useAuthData();
  const accountID = userData?.account_ID || "";
  const userID = userData?.user_ID || "";
  const role = userData?.role || "";
  const navigate = useNavigate();

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);

  // Sorting state
  const [selectedSegment, setSelectedSegment] = useFilterState('inbox-segment-filter', 'newest');
  const [searchQuery, setSearchQuery] = useFilterState('inbox-search-query', '');
  const [sortConfig, setSortConfig] = useState({
    key: 'last_activity_date',
    direction: selectedSegment === 'newest' ? 'descending' : 'ascending'
  });

  // Debounce search query
  const [debouncedSearchQuery] = useDebounce(searchQuery, 800);

  // Fetch inbox items
  const {
    combinedItems: inboxItems,
    loading: isFetching,
    error: fetchError,
    totalCount
  } = useFetchInbox(
    accountID,
    userID,
    role,
    selectedSegment,
    currentPage,
    10,
    debouncedSearchQuery.trim(),
    sortConfig
  );

  // Effect to update sort direction when segment changes
  useEffect(() => {
    setSortConfig(() => ({
      key: 'last_activity_date',
      direction: selectedSegment === 'newest' ? 'descending' : 'ascending'
    }));
  }, [selectedSegment]);

  // Filter inbox items based on currentStatusFilter and debouncedSearchQuery
  const filteredInboxItems = inboxItems;

  // Generate search suggestions for Inbox similar to Meetings
  const searchSuggestions = useMemo(() => {
    const trimmedQuery = debouncedSearchQuery.trim();
    const filteredItems = trimmedQuery !== ""
      ? inboxItems.filter(item => 
          item.subject && item.subject.toLowerCase().includes(trimmedQuery.toLowerCase())
        )
      : inboxItems;
    const uniqueSubjectsMap = new Map();
    filteredItems.forEach(item => {
      if (item.subject && !uniqueSubjectsMap.has(item.subject)) {
        uniqueSubjectsMap.set(item.subject, item.id);
      }
    });
    return Array.from(uniqueSubjectsMap.entries()).map(([subject, id]) => ({
      id,
      label: subject
    }));
  }, [inboxItems, debouncedSearchQuery]);

  // Sort inbox items
  const sortedInboxItems = useMemo(() => {
    if (!sortConfig.key) return filteredInboxItems;

    const sorted = [...filteredInboxItems].sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      // Handle different data types
      if (typeof aValue === "string") aValue = aValue.toLowerCase();
      if (typeof bValue === "string") bValue = bValue.toLowerCase();

      if (typeof aValue === "number" && typeof bValue === "number") {
        return aValue - bValue;
      }
      if (Date.parse(aValue) && Date.parse(bValue)) {
        return new Date(aValue) - new Date(bValue);
      }
      if (typeof aValue === "string" && typeof bValue === "string") {
        return aValue.localeCompare(bValue);
      }
      return 0;
    });

    if (sortConfig.direction === "descending") {
      sorted.reverse();
    }
    return sorted;
  }, [filteredInboxItems, sortConfig]);

  // Calculate total pages based on totalCount from API
  const totalPages = useMemo(() => {
    return Math.ceil(totalCount / ITEMS_PER_PAGE) || 1;
  }, [totalCount]);

  // Handle sorting
  const handleSort = (key) => {
    setSortConfig((prevState) => {
      if (prevState.key === key) {
        // Toggle direction
        return {
          key,
          direction: prevState.direction === "ascending" ? "descending" : "ascending",
        };
      } else {
        // New sort
        return { key, direction: "ascending" };
      }
    });
  };

  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  /**
   * Handles the navigation to the inbox item details page.
   * @param {Object} item - The inbox item object.
   */
  const handleViewDetails = useCallback(
    (item) => {
      navigate(`/admin/inbox/${item.id}`);
    },
    [navigate]
  );

  /**
   * Handles suggestion selection by navigating to the selected item's detail view.
   * @param {string|number} itemId - The ID of the selected inbox item.
   */
  const handleSuggestionSelect = useCallback(
    (itemId) => {
      navigate(`/admin/inbox/${itemId}`);
    },
    [navigate]
  );

  const segmentOptions = [
    { label: "Newest", value: "newest" },
    { label: "Oldest", value: "oldest" },
  ];

  // Reset to first page if filters or search changes
  useEffect(() => {
    setCurrentPage(1);
  }, [selectedSegment, debouncedSearchQuery, sortConfig]);

  return (
    <Box px={4}>
      <PageHeader
        title="Inbox"
        segmentOptions={segmentOptions}
        selectedSegment={selectedSegment}
        onSegmentChange={setSelectedSegment}
        searchPlaceholder="Search inbox..."
        onSearchChange={setSearchQuery}
        searchSuggestions={searchSuggestions}
        searchQuery={searchQuery}
        onSuggestionSelect={handleSuggestionSelect}
      />

      {isFetching ? (
        <TableSkeleton rowCount={10} />
      ) : fetchError ? (
        <Text>Failed to load inbox items. Please try again later. {fetchError}</Text>
      ) : (
        <>
          <SimpleGrid mb="20px" columns={{ sm: 1, md: 1 }} spacing={{ base: "20px", xl: "20px" }}>
            <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4} bg="white">
              {inboxItems.length === 0 ? (
                <Text textAlign="center" py={8} color="gray.500">
                  No inbox items available.
                </Text>
              ) : (
                <InboxTable
                  inboxItems={sortedInboxItems}
                  onViewDetails={handleViewDetails}
                  onSort={handleSort}
                  sortConfig={sortConfig}
                  searchQuery={debouncedSearchQuery}
                />
              )}
            </Box>
          </SimpleGrid>
          {inboxItems.length > 0 && (
            <PaginationControls
              currentPage={currentPage}
              totalPages={totalPages}
              onPrevious={handlePreviousPage}
              onNext={handleNextPage}
              onPageSelect={(page) => setCurrentPage(page)}
              disableNext={currentPage >= totalPages}
              disablePrevious={currentPage === 1}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default Inbox;