import React from 'react';
import {
  Box,
  Button,
  SimpleGrid,
  Flex,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import PageHeader from '../Shared/PageHeader';
import AgentCard from './AgentCard';
import { useFilterState } from '../hooks/useFilterState';

const Agents = () => {
  const navigate = useNavigate();
  const [selectedFilter, setSelectedFilter] = useFilterState('agents-filter', 'all');

  const segmentOptions = [
    { label: 'All', value: 'all' },
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' },
  ];

  const handleCreateAgent = () => {
    navigate('/admin/agents/new');
  };

  // Placeholder data for demonstration
  const agents = [
    {
      id: 1,
      title: 'Update Deal Stage on Email Response',
      services: ['Email', 'CRM'],
      triggers: ['Email Response'],
      actions: ['Update Deal'],
      createdBy: 'John Doe',
      usageCount: 156,
      isAI: true,
      isActive: true,
    },
    // Add more placeholder agents as needed
  ];

  const filteredAgents = agents.filter(agent => {
    if (selectedFilter === 'all') return true;
    return selectedFilter === 'active' ? agent.isActive : !agent.isActive;
  });

  return (
    <Box px={4}>
      <PageHeader
        title="Agents"
        segmentOptions={segmentOptions}
        selectedSegment={selectedFilter}
        onSegmentChange={setSelectedFilter}
        showSearch={false}
        additionalControls={
          <Flex justify="flex-end" width="100%">
            <Button
              leftIcon={<FaPlus />}
              colorScheme="blue"
              onClick={handleCreateAgent}
              borderRadius="md"
              size="md"
            >
              Create Agent
            </Button>
          </Flex>
        }
      />

      <SimpleGrid
        mb="20px"
        columns={{ base: 1, md: 2, lg: 3 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        {filteredAgents.map((agent) => (
          <AgentCard
            key={agent.id}
            agent={agent}
            onEdit={() => navigate(`/admin/agents/${agent.id}`)}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default Agents; 