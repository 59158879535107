import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuthData } from "../../../auth-context";
import { useToast } from "@chakra-ui/react";
import { STYLE_CONSTANTS } from "../../../components/sidebar/sidebarConstants";
import { useSidebar } from "../../../contexts/SidebarContext";

const useIgnoreSpark = () => {
  const { getAccessToken } = useAuthData();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { isExpanded } = useSidebar();

  const showSuccessToast = (action) => {
    // Calculate the left offset based on sidebar state
    const sidebarWidth = isExpanded ? STYLE_CONSTANTS.EXPANDED_WIDTH : STYLE_CONSTANTS.COLLAPSED_WIDTH;
    const leftOffset = `calc(50% + ${parseInt(sidebarWidth) / 2}px)`;

    toast({
      title: `Spark ${action === 'block' ? 'Blocked' : 'Archived'}`,
      description: `The spark has been successfully ${action === 'block' ? 'blocked' : 'archived'}`,
      status: "success",
      duration: 5000,
      isClosable: true,
      position: "top",
      containerStyle: {
        width: "400px",
        maxWidth: "100%",
        top: "20px",
        left: leftOffset,
        transform: "translateX(-50%)",
      },
    });
  };

  const ignoreSpark = async ({ accountId, crmAccountId, action }) => {
    try {
      // Validate required data
      if (!crmAccountId) {
        console.error("Missing crm_account_id:", crmAccountId);
        return;
      }

      const accessToken = await getAccessToken();

      const baseUrl =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      console.log("Making API call with:", {
        accountId,
        crmAccountId,
        action,
      });

      const response = await fetch(
        `${baseUrl}/accounts/${accountId}/crm-accounts/${crmAccountId}/spark/${action}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      let responseData;
      try {
        responseData = await response.json();
      } catch (e) {
        // If JSON parsing fails, try to get the text
        const text = await response.text();
        throw new Error(text || "Failed to process spark action");
      }

      if (!response.ok) {
        throw new Error(responseData.message || `Failed to ${action} spark`);
      }

      return responseData;
    } catch (error) {
      console.error("Error in useIgnoreSpark:", error);
      throw error;
    }
  };

  const mutation = useMutation({
    mutationFn: ignoreSpark,
    onSuccess: (_, variables) => {
      // Invalidate and refetch relevant queries
      queryClient.invalidateQueries({
        queryKey: ["sparks"],
        refetchType: "all",
      });

      // Show success toast
      showSuccessToast(variables.action);
    },
  });

  return mutation;
};

export default useIgnoreSpark; 