// src/views/lysto/Meetings/MeetingAnalysis.js
import React from "react";
import {
  Box,
  Text,
  VStack,
  Flex,
  Center,
  Spinner
} from "@chakra-ui/react";
import { useAuthData } from "auth-context";
import { useParams } from "react-router-dom";
import useFetchMeetingAnalysis from "../hooks/useFetchMeetingAnalysis";
import MeetingDetail from "./MeetingDetail";


const MeetingAnalysis = () => {
  const { meetingID } = useParams();
  const userData = useAuthData();
  const accountID = userData?.account_ID;
  const { meetingAnalysis, loading, error } = useFetchMeetingAnalysis(accountID, meetingID);

   if (loading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  if (error) {
    return (
      <Center h="100vh">
        <Text color="red.500">Error: {error}</Text>
      </Center>
    );
  }

  if (!meetingAnalysis) {
      return <Text>Meeting analysis not found.</Text>;
    }

  return (
    <VStack align="stretch" spacing={8} px={4}>
        <Flex>
            <Box flex={1} mr={8}>
              <MeetingDetail meetingAnalysis={meetingAnalysis} />
            </Box>
        </Flex>
    </VStack>
  );
};

export default MeetingAnalysis;