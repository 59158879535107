import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  Box,
  useColorModeValue,
  FormControl,
  FormLabel,
  Select,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  HStack,
  Checkbox,
} from '@chakra-ui/react';
import {
  MdAccessTime,
  MdEmail,
  MdUpdate,
  MdVideoLibrary,
  MdEvent,
  MdQuestionAnswer,
  MdTask,
} from 'react-icons/md';

// Placeholder data for connected email accounts
const CONNECTED_ACCOUNTS = [
  { id: 1, email: 'sales@company.com', type: 'Gmail' },
  { id: 2, email: 'support@company.com', type: 'Gmail' },
  { id: 3, email: 'marketing@company.com', type: 'Outlook' },
];

// Add placeholder data for calendar accounts
const CALENDAR_ACCOUNTS = [
  { id: 1, email: 'sales@company.com', type: 'Google Calendar' },
  { id: 2, email: 'support@company.com', type: 'Google Calendar' },
  { id: 3, email: 'marketing@company.com', type: 'Outlook Calendar' },
];

const triggers = [
  {
    type: 'Date & Time',
    icon: MdAccessTime,
    color: 'blue',
    description: 'Trigger at a specific time of day',
  },
  {
    type: 'Duration',
    icon: MdAccessTime,
    color: 'purple',
    description: 'Trigger after a specific duration',
  },
  {
    type: 'Email',
    icon: MdEmail,
    color: 'green',
    description: 'Trigger based on email context',
  },
  {
    type: 'Lysto Entity Update',
    icon: MdUpdate,
    color: 'orange',
    description: 'Trigger on entity field updates',
  },
  {
    type: 'Lysto Entity Creation',
    icon: MdUpdate,
    color: 'cyan',
    description: 'Trigger when new entities are created',
  },
  {
    type: 'Fathom Recording',
    icon: MdVideoLibrary,
    color: 'red',
    description: 'Trigger on new recordings',
  },
  {
    type: 'Calendar Event',
    icon: MdEvent,
    color: 'teal',
    description: 'Trigger on new calendar events',
  },
  {
    type: 'Calendar Update',
    icon: MdEvent,
    color: 'pink',
    description: 'Trigger on calendar event updates',
  },
  {
    type: 'Q&A',
    icon: MdQuestionAnswer,
    color: 'yellow',
    description: 'Trigger based on Q&A responses',
  },
  {
    type: 'Linear Task Update',
    icon: MdTask,
    color: 'gray',
    description: 'Trigger on Linear task updates',
  },
];

const AddTriggerModal = ({ isOpen, onClose, onAdd }) => {
  const [selectedTrigger, setSelectedTrigger] = useState(null);
  const [step, setStep] = useState('select'); // 'select' or 'configure'
  const [emailConfig, setEmailConfig] = useState({
    direction: 'inbound',
    useAllAccounts: true,
    specificEmail: '',
    selectedAccounts: [],
  });
  const [durationConfig, setDurationConfig] = useState({
    monitoringAction: 'last_deal_activity',
    customActivity: '',
    duration: 1,
    unit: 'weeks',
  });
  const [calendarConfig, setCalendarConfig] = useState({
    meetingActivity: 'meeting_created',
    useAllAccounts: true,
    selectedAccounts: [],
  });
  const [fathomConfig, setFathomConfig] = useState({
    useAllAccounts: true,
    selectedAccounts: [],
  });

  const cardBg = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const getHoverBg = (color) => useColorModeValue(`${color}.50`, `${color}.900`);

  const handleTriggerSelect = (trigger) => {
    setSelectedTrigger(trigger);
    setStep('configure');
  };

  const handleBack = () => {
    setStep('select');
    setSelectedTrigger(null);
  };

  const handleAdd = () => {
    if (selectedTrigger?.type === 'Fathom Recording') {
      onAdd({
        type: selectedTrigger.type,
        config: fathomConfig,
      });
    } else if (selectedTrigger?.type === 'Calendar Event') {
      onAdd({
        type: selectedTrigger.type,
        config: calendarConfig,
      });
    } else if (selectedTrigger?.type === 'Duration') {
      onAdd({
        type: selectedTrigger.type,
        config: durationConfig,
      });
    } else if (selectedTrigger?.type === 'Email') {
      onAdd({
        type: selectedTrigger.type,
        config: emailConfig,
      });
    } else {
      onAdd({
        type: selectedTrigger.type,
        config: { time: '09:00 AM' },
      });
    }
    onClose();
    setStep('select');
    setSelectedTrigger(null);
  };

  const renderEmailConfig = () => (
    <VStack align="stretch" spacing={6}>
      <FormControl>
        <FormLabel>Email Direction</FormLabel>
        <Select
          value={emailConfig.direction}
          onChange={(e) => setEmailConfig({ ...emailConfig, direction: e.target.value })}
        >
          <option value="inbound">Inbound</option>
          <option value="outbound">Outbound</option>
        </Select>
      </FormControl>

      {emailConfig.direction === 'inbound' ? (
        <FormControl>
          <FormLabel>Email Configuration</FormLabel>
          <Select
            value={emailConfig.useAllAccounts ? 'all' : 'specific'}
            onChange={(e) => {
              const value = e.target.value;
              setEmailConfig({
                ...emailConfig,
                useAllAccounts: value === 'all',
                specificEmail: '',
              });
            }}
          >
            <option value="all">Monitor all email accounts</option>
            <option value="specific">Monitor specific email address</option>
          </Select>
          {!emailConfig.useAllAccounts && (
            <Input
              placeholder="Enter email address"
              value={emailConfig.specificEmail}
              onChange={(e) =>
                setEmailConfig({
                  ...emailConfig,
                  specificEmail: e.target.value,
                })
              }
              mt={4}
            />
          )}
        </FormControl>
      ) : (
        <FormControl>
          <FormLabel>Select Accounts</FormLabel>
          <Select
            value={emailConfig.useAllAccounts ? 'all' : 'specific'}
            onChange={(e) => {
              const value = e.target.value;
              setEmailConfig({
                ...emailConfig,
                useAllAccounts: value === 'all',
                selectedAccounts: [],
              });
            }}
          >
            <option value="all">Use all connected accounts</option>
            <option value="specific">Select specific accounts</option>
          </Select>
          {!emailConfig.useAllAccounts && (
            <VStack align="start" spacing={2} mt={4}>
              {CONNECTED_ACCOUNTS.map((account) => (
                <Checkbox
                  key={account.id}
                  isChecked={emailConfig.selectedAccounts.includes(account.id)}
                  onChange={(e) => {
                    const newSelectedAccounts = e.target.checked
                      ? [...emailConfig.selectedAccounts, account.id]
                      : emailConfig.selectedAccounts.filter(
                          (id) => id !== account.id
                        );
                    setEmailConfig({
                      ...emailConfig,
                      selectedAccounts: newSelectedAccounts,
                    });
                  }}
                >
                  {account.email} ({account.type})
                </Checkbox>
              ))}
            </VStack>
          )}
        </FormControl>
      )}
    </VStack>
  );

  const renderDurationConfig = () => (
    <VStack align="stretch" spacing={6}>
      <FormControl>
        <FormLabel>Monitoring Action</FormLabel>
        <Select
          value={durationConfig.monitoringAction}
          onChange={(e) =>
            setDurationConfig({
              ...durationConfig,
              monitoringAction: e.target.value,
              customActivity: e.target.value === 'custom' ? '' : undefined,
            })
          }
        >
          <option value="last_deal_activity">Last Deal Activity</option>
          <option value="last_email">Last Email</option>
          <option value="last_meeting">Last Meeting</option>
          <option value="deal_created">Deal Created</option>
          <option value="deal_stage_changed">Deal Stage Changed</option>
          <option value="custom">Custom Activity</option>
        </Select>
        {durationConfig.monitoringAction === 'custom' && (
          <Input
            placeholder="Specify activity to monitor"
            mt={2}
            value={durationConfig.customActivity}
            onChange={(e) =>
              setDurationConfig({
                ...durationConfig,
                customActivity: e.target.value,
              })
            }
          />
        )}
      </FormControl>

      <FormControl>
        <FormLabel>Duration to Wait</FormLabel>
        <HStack spacing={4}>
          <NumberInput
            value={durationConfig.duration}
            onChange={(value) =>
              setDurationConfig({
                ...durationConfig,
                duration: parseInt(value) || 1,
              })
            }
            min={1}
            max={999}
            w="120px"
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Select
            value={durationConfig.unit}
            onChange={(e) =>
              setDurationConfig({
                ...durationConfig,
                unit: e.target.value,
              })
            }
            w="150px"
          >
            <option value="hours">Hours</option>
            <option value="days">Days</option>
            <option value="weeks">Weeks</option>
            <option value="months">Months</option>
          </Select>
        </HStack>
      </FormControl>
    </VStack>
  );

  const renderCalendarConfig = () => (
    <VStack align="stretch" spacing={6}>
      <FormControl>
        <FormLabel>Meeting Activity</FormLabel>
        <Select
          value={calendarConfig.meetingActivity}
          onChange={(e) =>
            setCalendarConfig({
              ...calendarConfig,
              meetingActivity: e.target.value,
            })
          }
        >
          <option value="meeting_created">Meeting Created</option>
          <option value="meeting_accepted">Meeting Accepted</option>
          <option value="meeting_declined">Meeting Declined</option>
          <option value="meeting_start">Meeting Start</option>
          <option value="meeting_end">Meeting End</option>
        </Select>
      </FormControl>

      <FormControl>
        <FormLabel>Calendar Account</FormLabel>
        <Select
          value={calendarConfig.useAllAccounts ? 'all' : 'specific'}
          onChange={(e) => {
            const value = e.target.value;
            setCalendarConfig({
              ...calendarConfig,
              useAllAccounts: value === 'all',
              selectedAccounts: [],
            });
          }}
        >
          <option value="all">Monitor all calendar accounts</option>
          <option value="specific">Select specific accounts</option>
        </Select>
        {!calendarConfig.useAllAccounts && (
          <VStack align="start" spacing={2} mt={4}>
            {CALENDAR_ACCOUNTS.map((account) => (
              <Checkbox
                key={account.id}
                isChecked={calendarConfig.selectedAccounts.includes(account.id)}
                onChange={(e) => {
                  const newSelectedAccounts = e.target.checked
                    ? [...calendarConfig.selectedAccounts, account.id]
                    : calendarConfig.selectedAccounts.filter(
                        (id) => id !== account.id
                      );
                  setCalendarConfig({
                    ...calendarConfig,
                    selectedAccounts: newSelectedAccounts,
                  });
                }}
              >
                {account.email} ({account.type})
              </Checkbox>
            ))}
          </VStack>
        )}
      </FormControl>
    </VStack>
  );

  const renderFathomConfig = () => (
    <VStack align="stretch" spacing={6}>
      <FormControl>
        <FormLabel>Calendar Account</FormLabel>
        <Select
          value={fathomConfig.useAllAccounts ? 'all' : 'specific'}
          onChange={(e) => {
            const value = e.target.value;
            setFathomConfig({
              ...fathomConfig,
              useAllAccounts: value === 'all',
              selectedAccounts: [],
            });
          }}
        >
          <option value="all">Monitor all calendar accounts</option>
          <option value="specific">Select specific accounts</option>
        </Select>
        {!fathomConfig.useAllAccounts && (
          <VStack align="start" spacing={2} mt={4}>
            {CALENDAR_ACCOUNTS.map((account) => (
              <Checkbox
                key={account.id}
                isChecked={fathomConfig.selectedAccounts.includes(account.id)}
                onChange={(e) => {
                  const newSelectedAccounts = e.target.checked
                    ? [...fathomConfig.selectedAccounts, account.id]
                    : fathomConfig.selectedAccounts.filter(
                        (id) => id !== account.id
                      );
                  setFathomConfig({
                    ...fathomConfig,
                    selectedAccounts: newSelectedAccounts,
                  });
                }}
              >
                {account.email} ({account.type})
              </Checkbox>
            ))}
          </VStack>
        )}
      </FormControl>
    </VStack>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={step === 'select' ? '4xl' : 'xl'}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {step === 'select' ? 'Select Trigger' : 'Configure Trigger'}
        </ModalHeader>
        <ModalCloseButton />
        
        <ModalBody>
          {step === 'select' ? (
            <Grid
              templateColumns="repeat(auto-fill, minmax(250px, 1fr))"
              gap={4}
              p={2}
            >
              {triggers.map((trigger) => (
                <Box
                  key={trigger.type}
                  bg={cardBg}
                  borderWidth="1px"
                  borderColor={borderColor}
                  borderRadius="lg"
                  p={4}
                  cursor="pointer"
                  onClick={() => handleTriggerSelect(trigger)}
                  _hover={{ bg: getHoverBg(trigger.color) }}
                  transition="all 0.2s"
                >
                  <VStack align="start" spacing={3}>
                    <Icon
                      as={trigger.icon}
                      boxSize={6}
                      color={`${trigger.color}.500`}
                    />
                    <Box>
                      <Text fontWeight="600">{trigger.type}</Text>
                      <Text fontSize="sm" color="gray.500">
                        {trigger.description}
                      </Text>
                    </Box>
                  </VStack>
                </Box>
              ))}
            </Grid>
          ) : (
            <VStack align="stretch" spacing={4}>
              {selectedTrigger?.type === 'Email' ? (
                renderEmailConfig()
              ) : selectedTrigger?.type === 'Duration' ? (
                renderDurationConfig()
              ) : selectedTrigger?.type === 'Calendar Event' ? (
                renderCalendarConfig()
              ) : selectedTrigger?.type === 'Fathom Recording' ? (
                renderFathomConfig()
              ) : (
                <Text>
                  Configure {selectedTrigger?.type} trigger settings
                  {/* Add configuration form here */}
                </Text>
              )}
            </VStack>
          )}
        </ModalBody>

        <ModalFooter>
          {step === 'configure' && (
            <Button variant="ghost" mr={3} onClick={handleBack}>
              Back
            </Button>
          )}
          <Button
            variant="ghost"
            mr={3}
            onClick={() => {
              onClose();
              setStep('select');
              setSelectedTrigger(null);
            }}
          >
            Cancel
          </Button>
          {step === 'configure' && (
            <Button colorScheme="blue" onClick={handleAdd}>
              Add Trigger
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

AddTriggerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default AddTriggerModal; 