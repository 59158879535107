import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuthData } from "../../../auth-context";

const useCreateDealFromSpark = () => {
  const { getAccessToken } = useAuthData();
  const queryClient = useQueryClient();

  const createDeal = async ({ accountId, userId, formData, sparkData, existingDealId = null, existingAccountId = null }) => {
    try {
      // Validate required data
      if (!sparkData?.crm_account_id && !existingAccountId) {
        console.error("Missing crm_account_id:", sparkData);
        return;
      }

      const crmAccountId = existingAccountId || sparkData.crm_account_id;

      const accessToken = await getAccessToken();

      const baseUrl =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      console.log("Making API call with:", {
        accountId,
        userId,
        crmAccountId,
        sparkData,
        formData,
        existingDealId,
        existingAccountId,
      });

      // Determine which endpoint to use based on whether we're adding to an existing deal
      const endpoint = existingDealId ? 
        `${baseUrl}/accounts/${accountId}/users/${userId}/spark/add-to-deal` :
        `${baseUrl}/accounts/${accountId}/users/${userId}/crm-accounts/${crmAccountId}/spark/add-deal`;

      // Prepare request body based on the endpoint
      const requestBody = existingDealId ? {
        // For add-to-deal endpoint
        old_crm_account_id: sparkData.crm_account_id,
        new_crm_account_id: existingAccountId,
        deal_id: existingDealId,
        contacts: formData.contacts.map((contact) => ({
          name: contact.firstName || "",
          last_name: contact.lastName || "",
          email: contact.email || "",
          phone: contact.phone || "",
          city: contact.city || "",
          state: contact.state || "",
          country: contact.country || "",
          linkedin_url: contact.linkedIn || "",
          title: contact.title || "",
          type: contact.type || "",
          source: "",
          status: "",
          metadata: {}
        }))
      } : {
        // For add-deal endpoint
        account: {
          id: existingAccountId,
          name: formData.accountName,
          website: formData.accountDomain,
          email: formData.accountEmail,
          linkedin_url: formData.linkedIn,
          phone: formData.accountPhone,
          city: formData.city,
          state: formData.state,
          country: formData.country,
          address: formData.address,
          size: formData.accountSize ? parseInt(formData.accountSize) : null,
          type: formData.accountType,
          description: formData.accountDescription,
          industry: formData.industry
        },
        contacts: formData.contacts.map((contact) => ({
          name: contact.firstName || "",
          last_name: contact.lastName || "",
          email: contact.email || "",
          phone: contact.phone || "",
          city: contact.city || "",
          state: contact.state || "",
          country: contact.country || "",
          linkedin_url: contact.linkedIn || "",
          title: contact.title || "",
          type: contact.type || "",
          source: "",
          status: "",
          metadata: {}
        })),
        deal: {
          id: existingDealId,
          name: formData.dealName,
          pipeline_id: formData.pipeline_id,
          stage_id: formData.stage_id,
          stage_name: formData.stage || "new_lead",
          description: formData.dealDescription,
          source: formData.dealSource,
          priority: formData.dealPriority,
          extra_fields: {}
        }
      };

      const response = await fetch(
        endpoint,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      let responseData;
      try {
        responseData = await response.json();
      } catch (e) {
        // If JSON parsing fails, try to get the text
        const text = await response.text();
        throw new Error(text || "Failed to parse response");
      }

      if (!response.ok) {
        throw new Error(responseData.message || "Failed to create deal");
      }

      return responseData;
    } catch (error) {
      console.error("Error in useCreateDealFromSpark:", error);
      throw error;
    }
  };

  const mutation = useMutation({
    mutationFn: createDeal,
    onSuccess: () => {
      // Invalidate and refetch relevant queries silently
      queryClient.invalidateQueries(["deals"], { refetchType: "none" });
      queryClient.invalidateQueries(["sparks"], { refetchType: "none" });
    },
  });

  return mutation;
};

export default useCreateDealFromSpark;
