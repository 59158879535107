// src/hooks/useFetchDeals.js

import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useAuthData } from "../../../auth-context";

/**
 * Custom hook to fetch deals based on the account ID.
 * @param {string} accountID - The account ID to fetch deals for.
 * @param {number} selectedYear - The selected year to filter deals.
 * @param {number} selectedQuarter - The selected quarter to filter deals (optional).
 * @returns {Object} - Contains deals data, loading state, and error state.
 */
export function useFetchDeals(accountID, selectedYear, selectedQuarter) {
  const { getAccessToken } = useAuthData();

  const fetchDeals = async () => {
    if (!accountID) {
      console.warn("Missing required accountID for useFetchDeals");
      return [];
    }

    try {
      const accessToken = await getAccessToken();
      console.log("Fetching deals with:", {
        accountID,
        selectedYear,
        selectedQuarter,
      });

      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      // Calculate start and end dates based on year and quarter
      let startDate, endDate;
      if (selectedYear) {
        if (selectedQuarter) {
          // For specific quarter
          const quarterStartMonth = (selectedQuarter - 1) * 3;
          startDate = `${selectedYear}-${String(quarterStartMonth + 1).padStart(
            2,
            "0"
          )}-01`;
          endDate = `${selectedYear}-${String(quarterStartMonth + 3).padStart(
            2,
            "0"
          )}-${quarterStartMonth + 3 === 12 ? "31" : "30"}`;
        } else {
          // For entire year
          startDate = `${selectedYear}-01-01`;
          endDate = `${selectedYear}-12-31`;
        }
      }

      const response = await axios.get(
        `${target}/accounts/${accountID}/deals`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            start_date: startDate,
            end_date: endDate,
          },
        }
      );

      // Log the raw response data
      console.log("Raw Deals Response:", response.data);

      // Ensure we always return an array and format the deals
      const deals = Array.isArray(response.data)
        ? response.data.map((deal) => ({
            ...deal,
            // Ensure we have both name properties for compatibility
            deal_name: deal.deal_name || deal.name,
            name: deal.name || deal.deal_name,
          }))
        : [];

      // Log detailed stats about the deals
      console.log("Deals stats:", {
        total: deals.length,
        hasDealName: deals.filter((d) => d.deal_name).length,
        hasName: deals.filter((d) => d.name).length,
        hasId: deals.filter((d) => d.id).length,
        firstDeal: deals[0]
          ? {
              id: deals[0].id,
              deal_name: deals[0].deal_name,
              name: deals[0].name,
              stage_name: deals[0].stage_name,
            }
          : null,
      });

      return deals;
    } catch (error) {
      console.error("Error fetching deals:", {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
      });
      throw error;
    }
  };

  const {
    data: deals = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: ["deals", accountID, selectedYear, selectedQuarter],
    queryFn: fetchDeals,
    enabled: !!accountID,
    staleTime: 5 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
  });

  return {
    deals,
    isLoading,
    error,
  };
}

export default useFetchDeals;
