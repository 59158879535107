// /Users/kylearchie/Documents/GitHub/lysto-fe/src/views/lysto/Accounts/index.jsx
import React, { useCallback, useState, useMemo, useEffect } from "react";
import {
  Box,
  SimpleGrid,
  Text,
  Button,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverHeader,
  PopoverFooter,
  Stack,
  FormControl,
  FormLabel,
  Input,
  CheckboxGroup,
  Checkbox,
  HStack,
  VStack,
  useColorModeValue,
  Spinner,
} from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import { useAuthData } from "auth-context";
import { FiPlus, FiFilter } from "react-icons/fi";
import AccountsTable from "./AccountsTable";
import PaginationControls from "../Shared/PaginationControls";
import PageHeader from "../Shared/PageHeader";
import TableSkeleton from "../Shared/TableSkeleton";
import useFetchAccounts from "../hooks/useFetchAccounts";
import { useDebounce } from 'use-debounce';
import { useFilterState } from "../hooks/useFilterState";
import CreateAccountModal from "./CreateAccountModal";
import { ChevronDown, ChevronUp } from "lucide-react";

const ITEMS_PER_PAGE = 10;

const Accounts = () => {
  // Helper functions first
  const isActive = (dateString) => {
    if (!dateString) return false;
    const lastActivityDate = new Date(dateString);
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
    return lastActivityDate >= twoWeeksAgo;
  };

  const getColumnType = (accounts, columnName) => {
    if (!accounts || accounts.length === 0) return null;
    
    if (columnName === 'amount') return 'number';
    
    const sampleValue = accounts.find(account => account[columnName] != null)?.[columnName];
    
    if (columnName.toLowerCase().includes('date')) return 'date';
    if (typeof sampleValue === 'number' || (typeof sampleValue === 'string' && !isNaN(sampleValue))) return 'number';
    return 'option';
  };

  const getColumnValues = (accounts, columnName) => {
    const frequencyMap = accounts.reduce((acc, account) => {
      const value = account[columnName];
      if (value) {
        acc[value] = (acc[value] || 0) + 1;
      } else {
        acc["None"] = (acc["None"] || 0) + 1;
      }
      return acc;
    }, {});

    const entries = Object.entries(frequencyMap);
    const nonNoneEntries = entries.filter(([value]) => value !== "None");
    
    if (nonNoneEntries.length < 2 || !nonNoneEntries.some(([, count]) => count >= 3)) {
      return [];
    }

    return entries
      .filter(([value, count]) => value === "None" ? count >= 3 : true)
      .sort((a, b) => {
        const countDiff = b[1] - a[1];
        if (countDiff !== 0) return countDiff;
        if (a[0] === "None") return 1;
        if (b[0] === "None") return -1;
        return a[0].localeCompare(b[0]);
      })
      .map(([value, count]) => ({
        value,
        count,
        label: `${value} (${count})`
      }));
  };

  const getColumnRange = (accounts, columnName) => {
    const values = accounts.map(account => account[columnName]).filter(value => typeof value === 'number');
    if (values.length === 0) return null;

    const uniqueValues = new Set(values);
    if (uniqueValues.size < 3) return null;

    return {
      min: Math.min(...values),
      max: Math.max(...values)
    };
  };

  const titleCase = (str) => {
    return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  // Then state and other hooks
  const userData = useAuthData();
  const accountID = userData?.account_ID || "";
  const navigate = useNavigate();
  const { accounts, isLoading, isError } = useFetchAccounts(accountID, "Lead");

  // Modal state
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);

  // Sorting state - Default sort by 'last_activity_date' descending
  const [sortConfig, setSortConfig] = useState({ 
    key: 'last_activity_date', 
    direction: 'descending' 
  });

  // Filter states
  const [currentStatusFilter, setCurrentStatusFilter] = useFilterState("accounts-status-filter", "all");
  const [searchQuery, setSearchQuery] = useFilterState("accounts-search-query", "");

  // Debounce the search query
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  // Generate search suggestions based on account names mapped to account IDs
  const searchSuggestions = useMemo(() => {
    return accounts
      .filter(account => 
        account.name && 
        account.name.toLowerCase().includes(debouncedSearchQuery.toLowerCase())
      )
      .map(account => ({
        id: account.id,
        label: account.name
      }));
  }, [accounts, debouncedSearchQuery]);

  // Handle sorting
  const handleSort = (key) => {
    setSortConfig((prevState) => {
      if (prevState.key === key) {
        return {
          key,
          direction: prevState.direction === 'ascending' ? 'descending' : 'ascending',
        };
      }
      return { key, direction: 'ascending' };
    });
  };

  // Enhance accounts with isActive flag
  const enhancedAccounts = useMemo(() => {
    return accounts.map(account => ({
      ...account,
      isActive: isActive(account.last_activity_date),
    }));
  }, [accounts]);

  // Add new state for filter popover
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [dynamicFilters, setDynamicFilters] = useFilterState("accounts-dynamic-filters", {});
  const [expandedSections, setExpandedSections] = useState({});
  const [loadingSections, setLoadingSections] = useState({});

  // Add theme colors
  const selectedBg = useColorModeValue("blue.500", "blue.300");
  const selectedTextColor = useColorModeValue("white", "gray.800");
  const unselectedBg = useColorModeValue("white", "gray.600");
  const unselectedTextColor = useColorModeValue("gray.700", "gray.200");
  const buttonHoverBg = useColorModeValue("gray.200", "gray.500");

  // Add filter computation logic
  const filterableColumns = useMemo(() => {
    if (!accounts || accounts.length === 0) return { date: [], number: [], option: [] };

    const columns = { date: [], number: [], option: [] };
    const blacklist = [
      "crm account id",
      "user id",
      "user name",
      "user last name",
      "user email",
      "description",
      "company email",
      "company website"
    ];

    // Collect all unique keys from all accounts
    const allKeys = new Set();
    accounts.forEach(account => {
      Object.keys(account).forEach(key => {
        allKeys.add(key);
      });
    });

    allKeys.forEach(column => {
      if (column.toLowerCase().includes('id')) return;
      if (blacklist.includes(column.toLowerCase())) return;

      const type = getColumnType(accounts, column);

      if (type === 'date') {
        const dateFrequencyMap = accounts.reduce((acc, account) => {
          const value = account[column];
          if (value) {
            const date = new Date(value);
            const monthYear = `${date.getMonth()}-${date.getFullYear()}`;
            acc[monthYear] = (acc[monthYear] || 0) + 1;
          }
          return acc;
        }, {});

        const hasFrequentDates = Object.values(dateFrequencyMap).some(count => count >= 3);
        if (hasFrequentDates) {
          columns.date.push({
            name: column,
            label: titleCase(column.replace(/_/g, " "))
          });
        }
      } else if (type === 'number') {
        const range = getColumnRange(accounts, column);
        if (range) {
          columns.number.push({
            name: column,
            label: titleCase(column.replace(/_/g, " ")),
            range
          });
        }
      } else {
        const values = getColumnValues(accounts, column);
        if (values.length > 0) {
          columns.option.push({
            name: column,
            label: titleCase(column.replace(/_/g, " ")),
            values
          });
        }
      }
    });

    // Add Active Status filter manually
    const activeCount = accounts.filter(acc => isActive(acc.last_activity_date)).length;
    const inactiveCount = accounts.length - activeCount;
    columns.option.push({
      name: "isActive",
      label: "Active Status",
      values: [
        { value: "active", count: activeCount, label: `Active (${activeCount})` },
        { value: "inactive", count: inactiveCount, label: `Inactive (${inactiveCount})` }
      ]
    });

    return columns;
  }, [accounts]);

  // Add filter handlers
  const handleDynamicFilterChange = (column, value) => {
    setDynamicFilters(prev => ({
      ...prev,
      [column]: value
    }));
  };

  const handleResetFilters = () => {
    setDynamicFilters({});
  };

  const toggleSection = (sectionName) => {
    setExpandedSections(prev => {
      const newExpanded = { ...prev, [sectionName]: !prev[sectionName] };
      if (newExpanded[sectionName]) {
        setLoadingSections(prevLoad => ({ ...prevLoad, [sectionName]: true }));
        setTimeout(() => {
          setLoadingSections(prevLoad => ({ ...prevLoad, [sectionName]: false }));
        }, 300);
      } else {
        setLoadingSections(prevLoad => ({ ...prevLoad, [sectionName]: false }));
      }
      return newExpanded;
    });
  };

  // Update filteredAccounts to include dynamic filters
  const filteredAccounts = useMemo(() => {
    if (!enhancedAccounts) return [];
    
    let filtered = enhancedAccounts;

    // Apply status filter
    if (currentStatusFilter !== "all") {
      filtered = filtered.filter((account) => 
        currentStatusFilter === "active" ? account.isActive : !account.isActive
      );
    }

    // Apply search filter
    if (debouncedSearchQuery.trim()) {
      const searchLower = debouncedSearchQuery.toLowerCase();
      filtered = filtered.filter((account) => 
        account.name?.toLowerCase().includes(searchLower) ||
        account.company?.toLowerCase().includes(searchLower) ||
        account.email?.toLowerCase().includes(searchLower)
      );
    }

    // Apply dynamic filters
    Object.entries(dynamicFilters).forEach(([column, filter]) => {
      if (!filter) return;
      // Special handling for active status
      if (column === "isActive" && filter.values && filter.values.length > 0) {
        filtered = filtered.filter((account) => filter.values.includes(account.isActive ? "active" : "inactive"));
        return;
      }
      const type = getColumnType(accounts, column);
      if (type === 'date' && filter.startDate && filter.endDate) {
        const start = new Date(filter.startDate);
        const end = new Date(filter.endDate);
        filtered = filtered.filter((account) => {
          const value = account[column];
          return value ? (new Date(value) >= start && new Date(value) <= end) : false;
        });
      }
      if (type === 'number' && (filter.min !== undefined || filter.max !== undefined)) {
        filtered = filtered.filter((account) => {
          const value = account[column];
          if (value === undefined || value === null) return false;
          if (filter.min !== undefined && value < filter.min) return false;
          if (filter.max !== undefined && value > filter.max) return false;
          return true;
        });
      }
      if (type === 'option' && filter.values && filter.values.length > 0) {
        filtered = filtered.filter((account) => {
          const value = account[column];
          return filter.values.includes(value || "None");
        });
      }
    });

    return filtered;
  }, [enhancedAccounts, currentStatusFilter, debouncedSearchQuery, dynamicFilters, accounts]);

  // Add hasActiveFilters computation
  const hasActiveFilters = useMemo(() => {
    return Object.values(dynamicFilters).some(filter => {
      if (!filter) return false;
      if (filter.startDate || filter.endDate) return true;
      if (filter.min !== undefined || filter.max !== undefined) return true;
      if (filter.values && filter.values.length > 0) return true;
      return false;
    });
  }, [dynamicFilters]);

  // Sort accounts
  const sortedAccounts = useMemo(() => {
    if (!sortConfig.key) return filteredAccounts;

    const sorted = [...filteredAccounts].sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      // Special handling for last_activity_date
      if (sortConfig.key === 'last_activity_date') {
        // Accounts with no last_activity_date should be sorted last
        if (!aValue && !bValue) return 0;
        if (!aValue) return 1;
        if (!bValue) return -1;
        return new Date(bValue) - new Date(aValue); // Newest first by default
      }

      // Handle different data types
      if (typeof aValue === 'string') aValue = aValue.toLowerCase();
      if (typeof bValue === 'string') bValue = bValue.toLowerCase();

      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return aValue - bValue;
      }
      if (Date.parse(aValue) && Date.parse(bValue)) {
        return new Date(aValue) - new Date(bValue);
      }
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return aValue.localeCompare(bValue);
      }
      return 0;
    });

    if (sortConfig.direction === 'ascending') {
      sorted.reverse(); // Reverse only if ascending is requested
    }
    return sorted;
  }, [filteredAccounts, sortConfig]);

  // Calculate total pages
  const totalPages = useMemo(() => {
    return Math.ceil(sortedAccounts.length / ITEMS_PER_PAGE) || 1;
  }, [sortedAccounts.length]);

  // Get current page's accounts
  const currentAccounts = useMemo(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return sortedAccounts.slice(startIndex, endIndex);
  }, [currentPage, sortedAccounts]);

  // Navigation handlers
  const handleViewDetails = useCallback((account) => {
    if (account && account.id) {
      navigate(`/admin/accounts/${account.id}`);
    }
  }, [navigate]);

  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const handlePageSelect = (pageNum) => {
    setCurrentPage(pageNum);
  };

  const handleCreateAccount = () => {
    setIsCreateModalOpen(true);
  };

  // Reset to first page when filters change
  useEffect(() => {
    setCurrentPage(1);
  }, [filteredAccounts, sortConfig, debouncedSearchQuery]);

  // Move loading check before any data processing
  if (isLoading) {
    return (
      <Box px={4}>
        <TableSkeleton rowCount={10} />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box px={4}>
        <Text>Failed to load accounts. Please try again later.</Text>
      </Box>
    );
  }

  if (!accounts || accounts.length === 0) {
    return (
      <Box px={4}>
        <Text>No accounts available.</Text>
      </Box>
    );
  }

  return (
    <Box px={4}>
      <PageHeader
        title="Accounts"
        segmentOptions={[
          { label: "All", value: "all" },
          { label: "Active", value: "active" },
          { label: "Inactive", value: "inactive" }
        ]}
        selectedSegment={currentStatusFilter}
        onSegmentChange={setCurrentStatusFilter}
        searchPlaceholder="Search accounts..."
        onSearchChange={setSearchQuery}
        searchSuggestions={searchSuggestions}
        searchQuery={searchQuery}
        onSuggestionSelect={(accountId) => {
          const selectedAccount = accounts.find(account => account.id === accountId);
          if (selectedAccount) {
            handleViewDetails(selectedAccount);
          }
        }}
        additionalControls={
          <Popover 
            placement="bottom-end" 
            isOpen={isFilterOpen}
            onClose={() => setIsFilterOpen(false)}
          >
            <PopoverTrigger>
              <Button
                leftIcon={<Icon as={FiFilter} />}
                rightIcon={<Icon as={isFilterOpen ? ChevronUp : ChevronDown} />}
                variant="outline"
                bg="white"
                borderRadius="md"
                borderColor="gray.300"
                color={useColorModeValue("gray.700", "gray.200")}
                _hover={{
                  bg: "gray.50",
                }}
                onClick={() => setIsFilterOpen(true)}
              >
                Filters {hasActiveFilters && `(${Object.values(dynamicFilters).filter(Boolean).length})`}
              </Button>
            </PopoverTrigger>
            <PopoverContent 
              width="400px" 
              maxH="calc(100vh - var(--chakra-space-48))"
              position="relative"
              boxShadow="lg"
              mt={2}
              display="flex"
              flexDirection="column"
            >
              <PopoverHeader 
                fontWeight="semibold" 
                position="sticky"
                top={0}
                bg="white"
                zIndex={1}
                borderBottomWidth="1px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text>Advanced Filters</Text>
                {isLoading && <Spinner size="sm" ml={2} />}
                <Text fontSize="sm" color="gray.500">
                  {filteredAccounts.length} of {accounts.length} accounts
                </Text>
              </PopoverHeader>
              <PopoverBody 
                flex="1" 
                overflowY="auto"
                py={4}
              >
                <VStack spacing={4} align="stretch">
                  {/* Date Filters */}
                  {filterableColumns.date && filterableColumns.date.length > 0 && (
                    <Box borderWidth="1px" borderRadius="md" p={4} bg="gray.50">
                      <FormControl mb={4}>
                        <FormLabel fontWeight="medium">Filter Date By</FormLabel>
                        <HStack wrap="wrap" spacing={2}>
                          {filterableColumns.date.map((column) => (
                            <Button
                              key={column.name}
                              size="sm"
                              bg={dynamicFilters[column.name] ? selectedBg : unselectedBg}
                              color={dynamicFilters[column.name] ? selectedTextColor : unselectedTextColor}
                              _hover={{
                                bg: dynamicFilters[column.name] ? selectedBg : buttonHoverBg,
                                color: dynamicFilters[column.name] ? selectedTextColor : 'inherit',
                              }}
                              borderWidth="1px"
                              borderColor="gray.300"
                              borderRadius="md"
                              onClick={() => {
                                if (dynamicFilters[column.name]) {
                                  const newFilters = { ...dynamicFilters };
                                  delete newFilters[column.name];
                                  setDynamicFilters(newFilters);
                                } else {
                                  handleDynamicFilterChange(column.name, {});
                                }
                              }}
                            >
                              {column.label}
                            </Button>
                          ))}
                        </HStack>
                      </FormControl>

                      <VStack spacing={4} align="stretch">
                        {filterableColumns.date.map((column) => (
                          dynamicFilters[column.name] && (
                            <FormControl key={column.name}>
                              <FormLabel fontWeight="medium">Select Range for {column.label}</FormLabel>
                              <Stack direction="row">
                                <Input
                                  type="date"
                                  value={dynamicFilters[column.name]?.startDate || ''}
                                  onChange={(e) => handleDynamicFilterChange(column.name, {
                                    ...dynamicFilters[column.name],
                                    startDate: e.target.value
                                  })}
                                  bg="white"
                                />
                                <Input
                                  type="date"
                                  value={dynamicFilters[column.name]?.endDate || ''}
                                  onChange={(e) => handleDynamicFilterChange(column.name, {
                                    ...dynamicFilters[column.name],
                                    endDate: e.target.value
                                  })}
                                  bg="white"
                                />
                              </Stack>
                            </FormControl>
                          )
                        ))}
                      </VStack>
                    </Box>
                  )}

                  {/* Option Filters */}
                  {filterableColumns.option && filterableColumns.option.length > 0 && (
                    filterableColumns.option.map((column) => (
                      <FormControl key={column.name}>
                        <Button
                          variant="unstyled"
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          onClick={() => toggleSection(column.name)}
                          mb={2}
                          _hover={{ bg: "gray.50" }}
                          p={2}
                          borderRadius="md"
                        >
                          <FormLabel fontWeight="medium" mb={0}>
                            {column.label}
                            {dynamicFilters[column.name]?.values?.length > 0 && (
                              <Text as="span" ml={2} color="gray.500" fontSize="sm">
                                ({dynamicFilters[column.name].values.length} filter{dynamicFilters[column.name].values.length === 1 ? '' : 's'})
                              </Text>
                            )}
                          </FormLabel>
                          <Icon
                            as={expandedSections[column.name] ? ChevronUp : ChevronDown}
                            boxSize={5}
                          />
                        </Button>
                        {expandedSections[column.name] && (
                          loadingSections[column.name] ? (
                            <Box p={4} display="flex" justifyContent="center" alignItems="center">
                              <Spinner />
                            </Box>
                          ) : (
                            <Box 
                              maxH="150px"
                              overflowY="auto"
                              borderWidth="1px"
                              borderColor="gray.200"
                              borderRadius="md"
                              bg="gray.50"
                              _hover={{ borderColor: "gray.300" }}
                              sx={{
                                '&::-webkit-scrollbar': {
                                  width: '8px',
                                  borderRadius: '8px',
                                  backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                  borderRadius: '8px',
                                },
                              }}
                            >
                              <CheckboxGroup 
                                value={dynamicFilters[column.name]?.values || []}
                                onChange={(values) => handleDynamicFilterChange(column.name, { values })}
                              >
                                <Stack spacing={2} p={3}>
                                  {column.values.map((item) => (
                                    <Checkbox key={item.value} value={item.value}>
                                      {item.label}
                                    </Checkbox>
                                  ))}
                                </Stack>
                              </CheckboxGroup>
                            </Box>
                          )
                        )}
                      </FormControl>
                    ))
                  )}
                </VStack>
              </PopoverBody>
              <PopoverFooter
                position="sticky"
                bottom={0}
                bg="white"
                zIndex={1}
                borderTopWidth="1px"
                py={3}
              >
                <HStack justify="flex-end" spacing={2}>
                  <Button size="sm" onClick={handleResetFilters}>Reset</Button>
                  <Button size="sm" colorScheme="blue" onClick={() => setIsFilterOpen(false)}>Apply</Button>
                </HStack>
              </PopoverFooter>
            </PopoverContent>
          </Popover>
        }
        searchRightElement={
          <Box>
            <Button
              leftIcon={<Icon as={FiPlus} />}
              colorScheme="blue"
              borderRadius="md"
              onClick={handleCreateAccount}
            >
              New Account
            </Button>
          </Box>
        }
      />

      {/* Create Account Modal */}
      <CreateAccountModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
      />

      {/* Accounts Table */}
      <SimpleGrid mb="20px" columns={{ sm: 1, md: 1 }} spacing={{ base: "20px", xl: "20px" }}>
        <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
          <AccountsTable
            accounts={currentAccounts}
            onSort={handleSort}
            sortConfig={sortConfig}
            onViewDetails={handleViewDetails}
            searchQuery={debouncedSearchQuery}
          />
        </Box>
      </SimpleGrid>

      {/* Pagination Controls */}
      <PaginationControls
        currentPage={currentPage}
        totalPages={totalPages}
        onPrevious={handlePreviousPage}
        onNext={handleNextPage}
        onPageSelect={handlePageSelect}
      />
    </Box>
  );
};

export default Accounts;