import React, { useState, useEffect } from 'react';
import {
  Box,
  Select,
  Text,
  Textarea,
  VStack,
  Button,
  Flex,
  useToast,
  IconButton,
  Spinner,
} from '@chakra-ui/react';
import { useAuthData } from 'auth-context';
import { useFetchInbox } from '../hooks/useFetchInbox';
import { useFetchDealById } from '../hooks/useFetchDealById';
import { useFetchDealActivities } from '../hooks/useFetchDealActivities';
import { useFetchDeals } from '../hooks/useFetchDeals';
import { FaSave, FaCopy } from 'react-icons/fa';

export default function SmartComposeSection() {
  const toast = useToast();
  const { user_ID: userID, account_ID: accountID } = useAuthData();
  const [selectedType, setSelectedType] = useState('reply');
  const [selectedInboxItem, setSelectedInboxItem] = useState('');
  const [selectedDeal, setSelectedDeal] = useState('');
  const [selectedPriority, setSelectedPriority] = useState('P0');
  const [dealActivities, setDealActivities] = useState([]);
  const [dealSummary, setDealSummary] = useState('');
  const [recentActivities, setRecentActivities] = useState('');
  const [combinedPrompt, setCombinedPrompt] = useState('');
  const [setTopDealsActivities] = useState([]);
  const [isLoadingTopDeals, setIsLoadingTopDeals] = useState(false);

  // Get current year for deals fetch
  const currentYear = new Date().getFullYear();

  // Fetch inbox items
  const { combinedItems } = useFetchInbox(accountID, userID, 'user', ['to do'], 1, 10);

  // Fetch all deals with proper parameters
  const { deals, isLoading: dealsLoading } = useFetchDeals(accountID, currentYear);
  
  // Add debug logging with more detail
  useEffect(() => {
    console.log('SmartComposeSection Debug:', {
      accountID,
      currentYear,
      dealsLoading,
      dealsLength: deals?.length,
      deals: deals?.map(deal => ({
        id: deal.id,
        deal_name: deal.deal_name,
        name: deal.name,
        stage_name: deal.stage_name
      }))
    });
  }, [accountID, currentYear, deals, dealsLoading]);

  // // Get the selected inbox item
  // const selectedItem = combinedItems?.find(item => item.id === selectedInboxItem);

  // Fetch deal data based on selection
  const { data: dealData, loading: dealLoading } = useFetchDealById(accountID, selectedDeal);

  // Fetch activities for the selected deal
  const { activities: currentDealActivities, loading: activitiesLoading } = 
    useFetchDealActivities(accountID, selectedDeal);

  // Effect to handle deal selection
  useEffect(() => {
    if (dealData) {
      setDealSummary(dealData.summary || dealData.description || 'No deal summary available');
    } else {
      setDealSummary('');
    }
  }, [dealData]);

  // Effect to handle current deal activities
  useEffect(() => {
    if (currentDealActivities) {
      const formattedActivities = currentDealActivities
        .filter(activity => activity.activity_data?.data)
        .map(activity => ({
          type: activity.activity_type,
          date: new Date(activity.activity_date || activity.created_at).toLocaleDateString(),
          description: activity.activity_data?.data?.body || 
                      activity.activity_data?.data?.description ||
                      activity.activity_data?.data?.subject ||
                      'No description available'
        }))
        .sort((a, b) => new Date(b.date) - new Date(a.date));

      setDealActivities(formattedActivities);
    } else {
      setDealActivities([]);
    }
  }, [currentDealActivities]);

  // Effect to fetch activities from first 3 deals
  useEffect(() => {
    const fetchTopDealsActivities = async () => {
      if (!deals || deals.length === 0) return;

      setIsLoadingTopDeals(true);
      try {
        const firstThreeDeals = deals.slice(0, 3);
        let allActivities = [];

        for (const deal of firstThreeDeals) {
          const { activities } = await useFetchDealActivities(accountID, deal.id);
          if (activities) {
            const dealActivities = activities.map(activity => ({
              ...activity,
              dealName: deal.name // Add deal name for context
            }));
            allActivities = [...allActivities, ...dealActivities];
          }
        }

        // Sort all activities by date and take the 10 most recent
        const sortedActivities = allActivities
          .filter(activity => activity.activity_data?.data)
          .sort((a, b) => new Date(b.activity_date || b.created_at) - new Date(a.activity_date || a.created_at))
          .slice(0, 10)
          .map(activity => ({
            dealName: activity.dealName,
            type: activity.activity_type,
            date: new Date(activity.activity_date || activity.created_at).toLocaleDateString(),
            description: activity.activity_data?.data?.body || 
                        activity.activity_data?.data?.description ||
                        activity.activity_data?.data?.subject ||
                        'No description available'
          }));

        setTopDealsActivities(sortedActivities);
        setRecentActivities(sortedActivities.map(formatTopDealActivityText).join('\n\n'));
      } catch (error) {
        console.error('Error fetching top deals activities:', error);
        setRecentActivities('Error loading recent activities');
      } finally {
        setIsLoadingTopDeals(false);
      }
    };

    fetchTopDealsActivities();
  }, [deals, accountID]);

  // Effect to update combined prompt
  useEffect(() => {
    const prompt = `
Deal Summary:
${dealSummary}

Last 5 Activities:
${dealActivities.slice(0, 5).map(formatActivityText).join('\n')}

Recent Activities from Top Deals:
${recentActivities}
    `.trim();

    setCombinedPrompt(prompt);
  }, [dealSummary, dealActivities, recentActivities]);

  const formatActivityText = (activity) => {
    return `[${activity.date}] ${activity.type}: ${activity.description}`;
  };

  const formatTopDealActivityText = (activity) => {
    return `[${activity.date}] ${activity.dealName} - ${activity.type}: ${activity.description}`;
  };

  const handleCopyPrompt = () => {
    navigator.clipboard.writeText(combinedPrompt);
    toast({
      title: "Copied to clipboard",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  const isLoadingDealData = dealLoading || activitiesLoading;

  // Update the deal name formatting
  const formatDealName = (deal) => {
    if (!deal) return 'Untitled Deal';
    return deal.deal_name || deal.name || `Deal ${deal.id}` || 'Untitled Deal';
  };

  return (
    <Box height="100%">
      <VStack spacing={6} align="stretch" height="100%">
        {/* Card 1: Inbox Configuration */}
        <Box
          bg="white"
          p={6}
          borderRadius="md"
          boxShadow="sm"
          borderWidth="1px"
          borderColor="gray.200"
        >
          <VStack spacing={4} align="stretch">
            <Text fontSize="md" fontWeight="semibold">
              Inbox Configuration
            </Text>
            <Box>
              <VStack spacing={4} align="stretch">
                <Box>
                  <Text mb={2} fontSize="sm" color="gray.600">Response Type</Text>
                  <Select
                    value={selectedType}
                    onChange={(e) => setSelectedType(e.target.value)}
                    size="md"
                    borderRadius="md"
                  >
                    <option value="reply">Reply</option>
                    <option value="followup">Followup</option>
                  </Select>
                </Box>

                <Box>
                  <Text mb={2} fontSize="sm" color="gray.600">Inbox Item</Text>
                  <Select
                    value={selectedInboxItem}
                    onChange={(e) => setSelectedInboxItem(e.target.value)}
                    placeholder="Select inbox item"
                    size="md"
                    borderRadius="md"
                  >
                    {combinedItems?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.subject || item.title || 'Untitled'}
                      </option>
                    ))}
                  </Select>
                </Box>

                <Box>
                  <Text mb={2} fontSize="sm" color="gray.600">Deal</Text>
                  <Select
                    value={selectedDeal}
                    onChange={(e) => setSelectedDeal(e.target.value)}
                    placeholder="Select deal"
                    size="md"
                    borderRadius="md"
                    isDisabled={dealsLoading}
                  >
                    {dealsLoading ? (
                      <option value="">Loading deals...</option>
                    ) : deals && deals.length > 0 ? (
                      deals.map((deal) => (
                        <option key={deal.id} value={deal.id}>
                          {formatDealName(deal)}
                        </option>
                      ))
                    ) : (
                      <option value="">No deals available</option>
                    )}
                  </Select>
                  {dealsLoading && (
                    <Flex align="center" mt={2}>
                      <Spinner size="sm" mr={2} />
                      <Text fontSize="sm" color="gray.600">Loading deals...</Text>
                    </Flex>
                  )}
                </Box>

                <Box>
                  <Text mb={2} fontSize="sm" color="gray.600">Deal Priority</Text>
                  <Select
                    value={selectedPriority}
                    onChange={(e) => setSelectedPriority(e.target.value)}
                    size="md"
                    borderRadius="md"
                  >
                    <option value="P0">P0</option>
                    <option value="P1">P1</option>
                    <option value="P2">P2</option>
                  </Select>
                </Box>
              </VStack>
            </Box>
          </VStack>
        </Box>

        {/* Card 2: Prompt Components */}
        <Box
          bg="white"
          p={6}
          borderRadius="md"
          boxShadow="sm"
          borderWidth="1px"
          borderColor="gray.200"
          position="relative"
        >
          <Flex justify="space-between" align="center" mb={4}>
            <Text fontSize="md" fontWeight="semibold">
              Prompt Components
            </Text>
            <IconButton
              icon={<FaCopy />}
              aria-label="Copy prompt"
              size="sm"
              colorScheme="blue"
              onClick={handleCopyPrompt}
            />
          </Flex>
          
          <VStack spacing={6} align="stretch">
            <Box>
              <Text mb={2} fontSize="sm" color="gray.600">Last 5 Activities</Text>
              <Textarea
                value={isLoadingDealData ? 'Loading...' : dealActivities
                  .slice(0, 5)
                  .map(formatActivityText)
                  .join('\n')}
                isReadOnly
                height="200px"
                borderRadius="md"
                bg="gray.50"
                _hover={{ borderColor: 'gray.300' }}
                placeholder="Select a deal to view activities"
              />
            </Box>

            <Box>
              <Text mb={2} fontSize="sm" color="gray.600">Deal Summary</Text>
              <Textarea
                value={isLoadingDealData ? 'Loading...' : dealSummary}
                isReadOnly
                height="100px"
                borderRadius="md"
                bg="gray.50"
                _hover={{ borderColor: 'gray.300' }}
                placeholder="Select a deal to view summary"
              />
            </Box>

            <Box>
              <Text mb={2} fontSize="sm" color="gray.600">Last 10 Messages from Top Deals</Text>
              <Textarea
                value={isLoadingTopDeals ? 'Loading recent activities...' : recentActivities}
                isReadOnly
                height="200px"
                borderRadius="md"
                bg="gray.50"
                _hover={{ borderColor: 'gray.300' }}
                placeholder="Loading recent activities from top deals..."
              />
            </Box>

            <Box>
              <Text mb={2} fontSize="sm" color="gray.600">Generated Prompt</Text>
              <Textarea
                value={combinedPrompt}
                isReadOnly
                height="300px"
                borderRadius="md"
                bg="gray.50"
                _hover={{ borderColor: 'gray.300' }}
                placeholder="Select a deal and wait for all components to load to generate the prompt"
              />
            </Box>
          </VStack>

          {/* Action Buttons */}
          <Flex justify="flex-end" mt={4}>
            <Button
              leftIcon={<FaSave />}
              colorScheme="blue"
              size="md"
              borderRadius="md"
              isDisabled={!selectedDeal || isLoadingDealData}
            >
              Save Configuration
            </Button>
          </Flex>
        </Box>
      </VStack>
    </Box>
  );
} 