import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { Trash2 } from 'lucide-react';
import {
  MdAccessTime,
  MdEmail,
  MdUpdate,
  MdVideoLibrary,
  MdEvent,
  MdQuestionAnswer,
  MdTask,
} from 'react-icons/md';

const triggerIcons = {
  'Date & Time': MdAccessTime,
  'Duration': MdAccessTime,
  'Email': MdEmail,
  'Lysto Entity Update': MdUpdate,
  'Lysto Entity Creation': MdUpdate,
  'Fathom Recording': MdVideoLibrary,
  'Calendar Event': MdEvent,
  'Calendar Update': MdEvent,
  'Q&A': MdQuestionAnswer,
  'Linear Task Update': MdTask,
};

const triggerColors = {
  'Date & Time': 'blue',
  'Duration': 'purple',
  'Email': 'green',
  'Lysto Entity Update': 'orange',
  'Lysto Entity Creation': 'cyan',
  'Fathom Recording': 'red',
  'Calendar Event': 'teal',
  'Calendar Update': 'pink',
  'Q&A': 'yellow',
  'Linear Task Update': 'gray',
};

const TriggerCard = ({ trigger, onDelete }) => {
  const cardBg = useColorModeValue('gray.50', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const iconColor = useColorModeValue(
    `${triggerColors[trigger.type]}.500`,
    `${triggerColors[trigger.type]}.300`
  );

  const TriggerIcon = triggerIcons[trigger.type] || MdAccessTime;

  const renderConfig = (config) => {
    if (trigger.type === 'Date & Time') {
      return `at ${config.time}`;
    }
    if (trigger.type === 'Email') {
      const direction = config.direction.charAt(0).toUpperCase() + config.direction.slice(1);
      if (config.useAllAccounts) {
        return `${direction} - All accounts`;
      } else if (config.direction === 'inbound') {
        return `${direction} - Monitor ${config.specificEmail}`;
      } else {
        const accountCount = config.selectedAccounts.length;
        return `${direction} - ${accountCount} specific account${accountCount !== 1 ? 's' : ''}`;
      }
    }
    if (trigger.type === 'Duration') {
      const actionMap = {
        last_deal_activity: 'Last Deal Activity',
        last_email: 'Last Email',
        last_meeting: 'Last Meeting',
        deal_created: 'Deal Created',
        deal_stage_changed: 'Deal Stage Changed',
        custom: config.customActivity,
      };

      const action = actionMap[config.monitoringAction] || config.monitoringAction;
      const duration = `${config.duration} ${config.unit}`;

      return `${action} + ${duration}`;
    }
    if (trigger.type === 'Calendar Event') {
      const activityMap = {
        meeting_created: 'Meeting Created',
        meeting_accepted: 'Meeting Accepted',
        meeting_declined: 'Meeting Declined',
        meeting_start: 'Meeting Start',
        meeting_end: 'Meeting End',
      };

      const activity = activityMap[config.meetingActivity];
      
      if (config.useAllAccounts) {
        return `${activity} - All calendar accounts`;
      } else {
        const accountCount = config.selectedAccounts.length;
        return `${activity} - ${accountCount} specific calendar${accountCount !== 1 ? 's' : ''}`;
      }
    }
    if (trigger.type === 'Fathom Recording') {
      if (config.useAllAccounts) {
        return 'Monitor all calendar accounts';
      } else {
        const accountCount = config.selectedAccounts.length;
        return `Monitor ${accountCount} specific calendar${accountCount !== 1 ? 's' : ''}`;
      }
    }
    // Add more config renderers as needed
    return JSON.stringify(config);
  };

  return (
    <Box
      bg={cardBg}
      borderWidth="1px"
      borderColor={borderColor}
      borderRadius="md"
      p={4}
    >
      <Flex justify="space-between" align="center">
        <HStack spacing={3}>
          <Icon
            as={TriggerIcon}
            boxSize={5}
            color={iconColor}
          />
          <Box>
            <Text fontWeight="500">
              {trigger.type}
            </Text>
            <Text fontSize="sm" color="gray.500">
              {renderConfig(trigger.config)}
            </Text>
          </Box>
        </HStack>
        
        <IconButton
          icon={<Trash2 size={16} />}
          variant="ghost"
          colorScheme="red"
          size="sm"
          onClick={onDelete}
          aria-label="Delete trigger"
        />
      </Flex>
    </Box>
  );
};

TriggerCard.propTypes = {
  trigger: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    config: PropTypes.object.isRequired,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default TriggerCard; 