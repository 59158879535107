// src/components/Contacts/hooks/useFetchContacts.js

import { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";

const PREFETCH_SIZE = 50;
const DISPLAY_SIZE = 10;

/**
 * Custom hook to fetch and search contacts data.
 * @param {number} accountID - The ID of the account.
 * @param {string} searchQuery - The search query string.
 * @param {string} contactType - The type of contacts to fetch (e.g., 'Lead').
 * @param {string} segment - The segment to filter contacts.
 * @param {number} pageNumber - The current page number.
 * @returns {Object} - { contacts, isLoading, isError, hasMore, totalCount, pageSize }
 */
const useFetchContacts = (
  accountID,
  searchQuery = "",
  contactType = "Lead",
  segment = "all",
  pageNumber = 1
) => {
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  // Cache for storing prefetched data
  const contactsCache = useRef(new Map());
  const currentBatch = useRef(1);

  // Calculate which batch contains the requested page
  const getBatchNumber = useCallback((page) => {
    return Math.ceil(page / (PREFETCH_SIZE / DISPLAY_SIZE));
  }, []);

  // Get the cached contacts for the current page
  const getCurrentPageContacts = useCallback(
    (page) => {
      const startIndex =
        ((page - 1) % (PREFETCH_SIZE / DISPLAY_SIZE)) * DISPLAY_SIZE;
      const batchNumber = getBatchNumber(page);
      const batchContacts = contactsCache.current.get(batchNumber) || [];
      const pageContacts = batchContacts.slice(
        startIndex,
        startIndex + DISPLAY_SIZE
      );

      // Update hasMore based on both API response and current page data
      const isLastPage = !hasMore && pageContacts.length < DISPLAY_SIZE;
      setHasMore(() => !isLastPage);

      return pageContacts;
    },
    [getBatchNumber, hasMore]
  );

  const fetchBatch = useCallback(
    async (batchNumber) => {
      setIsLoading(true);
      setIsError(false);

      try {
        const baseUrl =
          process.env.REACT_APP_NODE_ENV === "local"
            ? "https://lysto-staging-eyf9ejcwf8h3awbw.eastus2-01.azurewebsites.net"
            : "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

        const endpoint = searchQuery
          ? `/accounts/${accountID}/contacts/search`
          : `/accounts/${accountID}/contacts`;

        // Calculate the API page number based on the batch
        const apiPageNumber = batchNumber;

        const response = await axios.get(`${baseUrl}${endpoint}`, {
          params: {
            contact_type: contactType,
            page_number: apiPageNumber,
            items_per_page: PREFETCH_SIZE,
            ...(searchQuery && { query: searchQuery.trim() }),
            ...(segment !== "all" && { active: segment === "active" }),
          },
        });

        const newContacts = response.data?.items || [];
        contactsCache.current.set(batchNumber, newContacts);

        setHasMore(response.data?.has_more || false);
        setTotalCount(response.data?.total_count || 0);

        return newContacts;
      } catch (error) {
        console.error("Error fetching contacts:", error);
        setIsError(true);
        return [];
      } finally {
        setIsLoading(false);
      }
    },
    [accountID, contactType, searchQuery, segment]
  );

  // Clear cache when query parameters change (including segment)
  useEffect(() => {
    contactsCache.current.clear();
    currentBatch.current = 1;
  }, [accountID, searchQuery, contactType, segment]);

  // Effect to handle fetching and caching
  useEffect(() => {
    const loadContacts = async () => {
      if (!accountID) return;

      const batchNumber = getBatchNumber(pageNumber);

      if (currentBatch.current !== batchNumber) {
        contactsCache.current.clear();
      }

      if (!contactsCache.current.has(batchNumber)) {
        await fetchBatch(batchNumber);
        currentBatch.current = batchNumber;
        setContacts(getCurrentPageContacts(pageNumber));
      } else {
        setContacts(getCurrentPageContacts(pageNumber));
      }
    };

    loadContacts();
  }, [
    accountID,
    pageNumber,
    fetchBatch,
    getBatchNumber,
    getCurrentPageContacts,
    segment,
  ]);

  // Prefetch next batch
  useEffect(() => {
    const prefetchNextBatch = async () => {
      if (!accountID || !hasMore) return;

      const currentBatchNumber = getBatchNumber(pageNumber);
      const nextBatchNumber = currentBatchNumber + 1;

      if (
        pageNumber % (PREFETCH_SIZE / DISPLAY_SIZE) >=
          PREFETCH_SIZE / DISPLAY_SIZE - 2 &&
        !contactsCache.current.has(nextBatchNumber)
      ) {
        await fetchBatch(nextBatchNumber);
      }
    };

    prefetchNextBatch();
  }, [accountID, pageNumber, hasMore, getBatchNumber, fetchBatch, segment]);

  return {
    contacts,
    isLoading,
    isError,
    hasMore,
    totalCount,
    pageSize: DISPLAY_SIZE,
  };
};

export default useFetchContacts;
