/* eslint-disable */
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
// chakra imports
import { Box, Flex, Text, useColorModeValue, Tooltip } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { STYLE_CONSTANTS, useSidebarColors, activeRoute } from "./sidebarConstants";

export const NavItem = ({ isActive, isExpanded, icon, name, onClick, tooltipLabel }) => {
  const colors = useSidebarColors();
  const content = (
    <Flex
      align="center"
      justify={isExpanded ? "flex-start" : "center"}
      p={STYLE_CONSTANTS.SPACING.PADDING}
      mx="auto"
      borderRadius={STYLE_CONSTANTS.BORDER_RADIUS}
      role="group"
      cursor="pointer"
      color={isActive ? colors.active : colors.textSecondary}
      bg={isActive ? colors.activeBg : "transparent"}
      _hover={{ bg: colors.hoverBg }}
      transition={STYLE_CONSTANTS.TRANSITION}
      width={isExpanded ? "90%" : "70%"}
      onClick={onClick}
    >
      <Box 
        color={isActive ? colors.active : colors.textSecondary}
        display="flex"
        alignItems="center" 
        justifyContent="center"
        w={STYLE_CONSTANTS.ICON_SIZE}
        h={STYLE_CONSTANTS.ICON_SIZE}
        flexShrink={0}
      >
        {icon}
      </Box>
      {isExpanded && (
        <Text
          ml={STYLE_CONSTANTS.SPACING.MARGIN}
          fontWeight={isActive ? "bold" : "medium"}
          fontSize={STYLE_CONSTANTS.FONT.SIZE.NORMAL}
        >
          {name}
        </Text>
      )}
    </Flex>
  );

  return tooltipLabel && !isExpanded ? (
    <Tooltip label={tooltipLabel} placement="right">
      {content}
    </Tooltip>
  ) : (
    content
  );
};

export function SidebarLinks({ routes, isExpanded }) {
  const location = useLocation();
  const colors = useSidebarColors();

  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (route.category) {
        return isExpanded ? (
          <React.Fragment key={`category-${index}`}>
            <Text
              fontSize={STYLE_CONSTANTS.FONT.SIZE.NORMAL}
              color={colors.textSecondary}
              fontWeight="bold"
              mb={2}
              mx={STYLE_CONSTANTS.SPACING.MARGIN}
            >
              {route.name}
            </Text>
            {createLinks(route.items)}
          </React.Fragment>
        ) : null;
      } else if (
        route.layout === "/admin" ||
        route.layout === "/auth" ||
        route.layout === "/rtl"
      ) {
        const isActive = activeRoute(location.pathname, route.path.toLowerCase());
        return (
          <Box key={index} mb="2px">
            <NavLink to={`${route.layout}/${route.path}`}>
              <NavItem
                isActive={isActive}
                isExpanded={isExpanded}
                icon={route.icon}
                name={route.name}
                tooltipLabel={!isExpanded ? route.name : undefined}
              />
            </NavLink>
          </Box>
        );
      }
      return null;
    });
  };
  return <>{createLinks(routes)}</>;
}

NavItem.propTypes = {
  isActive: PropTypes.bool,
  isExpanded: PropTypes.bool,
  icon: PropTypes.node,
  name: PropTypes.string,
  onClick: PropTypes.func,
  tooltipLabel: PropTypes.string
};

SidebarLinks.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  isExpanded: PropTypes.bool
};

export default SidebarLinks;
