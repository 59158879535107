// src/components/Shared/HoverBox.jsx

import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Icon,
  useColorModeValue,
  Portal
} from "@chakra-ui/react";

/**
 * HoverBox Component
 * Wraps children with a hover effect that changes the icon and provides a tooltip.
 *
 * @param {Object} props - Component props
 * @param {React.ElementType} props.icon - The default icon to display
 * @param {React.ElementType} props.hoverIcon - The icon to display on hover
 * @param {Function} props.onClick - Function to call when the HoverBox is clicked
 * @param {React.ReactNode} props.children - The content to display inside the HoverBox
 * @param {string} props.tooltipLabel - The tooltip text to display
 * @returns {JSX.Element}
 */
const HoverBox = ({
  children,
  icon = null,
  hoverIcon = null,
  onClick,
  tooltipLabel = "Hover action",
}) => {
  const iconColor = useColorModeValue("#748094", "#CBD5E0"); // Slightly lighter gray
  const hoverIconColor = useColorModeValue("#1A5BF6", "#63B3ED");
  const tooltipBg = useColorModeValue("gray.700", "white");
  const tooltipTextColor = useColorModeValue("white", "gray.700");

  // Added state to track hover and mouse position
  const [isHovered, setIsHovered] = React.useState(false);
  const [mousePos, setMousePos] = React.useState({ x: 0, y: 0 });

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  const handleMouseMove = (e) => setMousePos({ x: e.clientX, y: e.clientY });

  return (
    <>
      <Box
        display="inline-flex"
        alignItems="center"
        borderRadius="lg"
        cursor="pointer"
        position="relative"
        transition="all 0.2s"
        role="group"
        width="100%"
        _hover={{
          "& .default-icon": { opacity: 0 },
          "& .hover-icon": { opacity: 1 },
        }}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
      >
        {icon && (
          <Box
            className="default-icon"
            mr={2}
            position="absolute"
            transition="opacity 0.2s"
            opacity={1}
            flexShrink={0}
          >
            <Icon as={icon} color={iconColor} boxSize="20px" />
          </Box>
        )}
        {hoverIcon && (
          <Box
            className="hover-icon"
            mr={2}
            position="absolute"
            transition="opacity 0.2s"
            opacity={0}
            flexShrink={0}
          >
            <Icon as={hoverIcon} color={hoverIconColor} boxSize="20px" />
          </Box>
        )}
        <Box
          as="span"
          display="block"
          textAlign="left"
          width="100%"
          pl={icon ? "28px" : "0"}
          pr="4px"
        >
          {children}
        </Box>
      </Box>
      {isHovered && (
        <Portal>
          <Box
            position="fixed"
            left={mousePos.x + 10}
            top={mousePos.y + 10}
            bg={tooltipBg}
            color={tooltipTextColor}
            px="8px"
            py="4px"
            fontSize="sm"
            borderRadius="md"
            zIndex="tooltip"
            pointerEvents="none"
            boxShadow="sm"
          >
            {tooltipLabel}
          </Box>
        </Portal>
      )}
    </>
  );
};

// Define PropTypes for type checking
HoverBox.propTypes = {
  icon: PropTypes.elementType,
  hoverIcon: PropTypes.elementType,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  tooltipLabel: PropTypes.string,
};

export default HoverBox;
