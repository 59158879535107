// src/views/lysto/Spark/hooks/useFetchSparks.js

import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useAuthData } from "../../../auth-context";

export function useFetchSparks(
  accountID,
  userID,
  pageNumber = 1,
  itemsPerPage = 10,
  filter = "all",
  searchQuery = ""
) {
  const { getAccessToken } = useAuthData();

  const fetchSparks = async () => {
    if (!accountID || !userID) {
      return { items: [], has_more: false, total_count: 0 };
    }

    const accessToken = await getAccessToken();
    const target =
      process.env.REACT_APP_API_BASE_URL ||
      "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

    const params = {
      page_number: pageNumber,
      items_per_page: itemsPerPage,
      filter: filter.toLowerCase(),
      sort_by: filter === "all" ? "chronological" : "score"
    };

    const response = await axios.get(
      `${target}/accounts/${accountID}/users/${userID}/spark`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params,
      }
    );

    return response.data;
  };

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ["sparks", accountID, userID, pageNumber, itemsPerPage, filter],
    queryFn: fetchSparks,
    enabled: Boolean(accountID && userID),
    staleTime: 30000, // Consider data stale after 30 seconds
  });

  // New function to search through sparks and their recipients
  const searchSparks = (sparks, query) => {
    if (!query || !sparks) return sparks;

    const searchLower = query.toLowerCase();
    return sparks.filter(spark => {
      // Check if any of the recipients match the search query
      const hasMatchingRecipient = spark.activities?.some(activity => {
        if (!activity.ActivityData) return false;
        
        try {
          const activityData = typeof activity.ActivityData === 'string' 
            ? JSON.parse(activity.ActivityData) 
            : activity.ActivityData;

          const recipients = activityData.data?.recipients || [];
          return recipients.some(recipient => 
            recipient.name?.toLowerCase().includes(searchLower) ||
            recipient.email?.toLowerCase().includes(searchLower)
          );
        } catch (e) {
          console.error('Error parsing activity data:', e);
          return false;
        }
      });

      // Return true if any recipient matches or if other fields match
      return hasMatchingRecipient ||
        spark.crm_domain?.toLowerCase().includes(searchLower) ||
        spark.reason?.toLowerCase().includes(searchLower);
    });
  };

  // Filter sparks based on search query
  const filteredSparks = searchQuery 
    ? searchSparks(data?.items || [], searchQuery) 
    : data?.items || [];

  const handleAddDeal = async (sparkId) => {
    try {
      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      await axios.post(
        `${target}/accounts/${accountID}/users/${userID}/spark/${sparkId}/convert`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      // Remove the converted spark from the list
      refetch();

      return { success: true };
    } catch (err) {
      console.error("Error converting spark to deal:", err);
      throw new Error("Failed to convert spark to deal");
    }
  };

  const handleIgnoreSpark = async (sparkId) => {
    try {
      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      await axios.post(
        `${target}/accounts/${accountID}/users/${userID}/spark/${sparkId}/ignore`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      // Refetch sparks after ignoring
      refetch();

      return { success: true };
    } catch (err) {
      console.error("Error ignoring spark:", err);
      throw new Error("Failed to ignore spark");
    }
  };

  const markSparkAsRead = async (sparkId) => {
    try {
      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      await axios.post(
        `${target}/accounts/${accountID}/users/${userID}/spark/${sparkId}/read`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      // Update the spark's read status in the local state
      refetch();

      return { success: true };
    } catch (err) {
      console.error("Error marking spark as read:", err);
      throw new Error("Failed to mark spark as read");
    }
  };

  const markAllSparksAsRead = async () => {
    try {
      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      await axios.post(
        `${target}/accounts/${accountID}/users/${userID}/spark/read-all`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      // Update all sparks' read status in the local state
      refetch();

      return { success: true };
    } catch (err) {
      console.error("Error marking all sparks as read:", err);
      throw new Error("Failed to mark all sparks as read");
    }
  };

  return {
    sparks: filteredSparks,
    loading: isLoading,
    error: error?.message,
    hasMore: data?.has_more || false,
    totalCount: data?.total_count || 0,
    handleAddDeal,
    handleIgnoreSpark,
    markSparkAsRead,
    markAllSparksAsRead,
    refetch,
  };
}

export default useFetchSparks;
