// src/components/Inbox/InboxDetailsPage.jsx
import React, { useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { 
  Box, 
  Center, 
  Text, 
  useToast,
  Alert,
  AlertIcon,
  Skeleton,
  VStack,
} from "@chakra-ui/react";
import { useAuthData } from "auth-context";
import EmailFollowupDetail from "./EmailFollowupDetail";
import CallFollowupDetail from "./CallFollowupDetail";
import GoBackHeader from "../Shared/GoBackHeader";
import { useInboxItem } from "../hooks/useInboxItem";
import PageHeader from "../Shared/PageHeader";

// Mapping of task_subtype to corresponding detail components
const DETAIL_VIEW_COMPONENTS = {
  email: EmailFollowupDetail,
  call: CallFollowupDetail,
  // Add more mappings here as needed
};

const LoadingSkeleton = () => (
  <VStack spacing={6} align="stretch">
    <Box bg="white" p={6} borderRadius="md" boxShadow="sm" borderWidth="1px" borderColor="gray.200">
      <VStack spacing={6} align="stretch">
        <Skeleton height="24px" width="150px" />
        <Skeleton height="60px" />
        <Skeleton height="100px" />
        <Skeleton height="150px" />
      </VStack>
    </Box>
    <Box bg="white" p={6} borderRadius="md" boxShadow="sm" borderWidth="1px" borderColor="gray.200">
      <VStack spacing={6} align="stretch">
        <Skeleton height="200px" />
      </VStack>
    </Box>
  </VStack>
);

const InboxDetailsPage = () => {
  const { inboxId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const userData = useAuthData();
  const accountID = userData?.account_ID || "";
  const userID = userData?.user_ID || "";
  const target =
    process.env.REACT_APP_NODE_ENV === "local"
      ? "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net"
      : "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

  const {
    data: item,
    loading,
    error,
    handleAddAnnotation,
    handleArchiveToggle,
    handleSendMessage,
    handleMarkAsDone,
  } = useInboxItem(inboxId, accountID, userID, target);

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleSend = async (messageData) => {
    try {
      console.log("Initiating send with data:", messageData); // Debug
      await handleSendMessage(messageData);
      // Toast is handled in EmailFollowupDetail.jsx
    } catch (error) {
      console.error(
        "Error in handleSend:",
        error.response ? error.response.data : error.message
      );
      toast({
        title: "Failed to send message",
        description:
          error.response?.data?.message ||
          error.message ||
          "An error occurred while sending the message.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleAnnotation = async (annotation) => {
    try {
      await handleAddAnnotation(annotation);
      // Toast is handled in EmailFollowupDetail.jsx
    } catch (error) {
      console.error(
        "Error adding annotation:",
        error.response ? error.response.data : error.message
      );
      toast({
        title: "Failed to add note",
        description:
          error.response?.data?.message ||
          error.message ||
          "An error occurred while adding the note.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleArchive = async () => {
    try {
      await handleArchiveToggle();
      toast({
        title: `Thread ${
          item.status === "archived" ? "unarchived" : "archived"
        } successfully`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error(
        "Error toggling archive status:",
        error.response ? error.response.data : error.message
      );
      toast({
        title: "Failed to update archive status",
        description:
          error.response?.data?.message ||
          error.message ||
          "An error occurred while updating the archive status.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleTaskDone = async (id) => {
    try {
      console.log("Initiating marking as done with data:", id); // Debug
      await handleMarkAsDone(id);
      // Toast is handled in EmailFollowupDetail.jsx
    } catch (error) {
      console.error(
        "Error in handleMarkAsDone:",
        error.response ? error.response.data : error.message
      );
      toast({
        title: "Failed to mark as done",
        description:
          error.response?.data?.message ||
          error.message ||
          "An error occurred while marking as done.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const noop = () => undefined;

  // Determine the title based on task type and subject
  const getTitle = () => {
    if (!item) return "Loading...";
    if (item.task_subtype === "call") {
      return "Call Follow-up Details";
    }
    return item.emailSubject || "Email Follow-up Details";
  };

  const renderContent = () => {
    if (loading) {
      return <LoadingSkeleton />;
    }

    if (error) {
      return (
        <Center mt={8}>
          <Alert status="error" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" height="200px">
            <AlertIcon boxSize="40px" mr={0} />
            <Text mt={4} mb={1} fontSize="lg">
              Error Loading Item
            </Text>
            <Text fontSize="sm">
              {typeof error === 'string' ? error : error.message || 'Failed to load inbox item details. Please try again later.'}
            </Text>
          </Alert>
        </Center>
      );
    }

    if (!item) {
      return (
        <Center mt={8}>
          <Alert status="warning" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" height="200px">
            <AlertIcon boxSize="40px" mr={0} />
            <Text mt={4} mb={1} fontSize="lg">
              Item Not Found
            </Text>
            <Text fontSize="sm">
              The requested inbox item could not be found. It may have been deleted or you may not have permission to view it.
            </Text>
          </Alert>
        </Center>
      );
    }

    // Select the appropriate detail component
    const DetailComponent = DETAIL_VIEW_COMPONENTS[item.task_subtype];

    if (!DetailComponent) {
      return (
        <Center mt={8}>
          <Alert status="warning" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" height="200px">
            <AlertIcon boxSize="40px" mr={0} />
            <Text mt={4} mb={1} fontSize="lg">
              Unsupported Type
            </Text>
            <Text fontSize="sm">
              Unsupported task type: {item.task_subtype || "unknown"}
            </Text>
          </Alert>
        </Center>
      );
    }

    return (
      <DetailComponent
        item={item}
        onArchiveToggle={handleArchive}
        onSendMessage={handleSend}
        onAddAnnotation={handleAnnotation}
        onTaskDone={handleTaskDone}
      />
    );
  };

  return (
    <Box px={4}>
      {/* Main Header with Avatar */}
      <PageHeader
        title="Inbox"
        segmentOptions={[]}
        selectedSegment=""
        onSegmentChange={noop}
        onSearchChange={noop}
        showSearch={false}
        isDetailView
      />

      {/* Go Back Header with Dynamic Title */}
      <Box mb={2}>
        <GoBackHeader onGoBack={handleGoBack} title={getTitle()} />
      </Box>

      {/* Render the content */}
      {renderContent()}
    </Box>
  );
};

export default InboxDetailsPage;
