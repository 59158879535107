import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useAuthData } from '../../../auth-context';

export const useCreateDeal = () => {
  const { getAccessToken } = useAuthData();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ accountId, dealData }) => {
      const accessToken = await getAccessToken();
      const target = process.env.REACT_APP_API_BASE_URL || 'https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net';

      const response = await axios.post(
        `${target}/accounts/${accountId}/deals`,
        dealData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      return response.data;
    },
    onSuccess: (data, variables) => {
      // Invalidate relevant queries to trigger refetch
      queryClient.invalidateQueries(['deals', variables.accountId]);
    },
  });
};

export default useCreateDeal; 