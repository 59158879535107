// src/hooks/useFetchInbox.js

import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useAuthData } from "../../../auth-context";

// Define the default sort order
const DEFAULT_SORT_ORDER = "newest";

export function useFetchInbox(
  accountID,
  userID,
  role,
  sortOrder = DEFAULT_SORT_ORDER,
  pageNumber = 1,
  itemsPerPage = 10,
  searchQuery = "",
  sortConfig = null,
  status = "to do"
) {
  const [combinedItems, setCombinedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const { getAccessToken } = useAuthData();
  
  // Cache for preloaded next page data
  const nextPageCache = useRef(null);
  const currentRequestId = useRef(0);

  // Helper function to fetch a specific page
  const fetchPage = async (page, signal) => {
    const accessToken = await getAccessToken();
    const target =
      process.env.REACT_APP_NODE_ENV === "local"
        ? "https://lysto-staging-eyf9ejcwf8h3awbw.eastus2-01.azurewebsites.net"
        : "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

    // Convert sortConfig to backend's sort_order parameter
    let effectiveSortOrder = sortOrder;
    if (sortConfig) {
      effectiveSortOrder = sortConfig.key === 'last_activity_date'
        ? (sortConfig.direction === 'descending' ? 'newest' : 'oldest')
        : sortOrder;
    }

    const params = {
      role,
      page_number: page,
      items_per_page: itemsPerPage,
      sort_order: effectiveSortOrder,
      status
    };

    if (searchQuery && searchQuery.trim() !== "") {
      params.search = searchQuery.trim();
    }

    const response = await axios.get(
      `${target}/accounts/${accountID}/users/${userID}/inbox`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params,
        signal
      }
    );

    return response.data;
  };

  useEffect(() => {
    const controller = new AbortController();
    const requestId = ++currentRequestId.current;

    const fetchData = async () => {
      if (!accountID || !userID || !sortOrder || !pageNumber || !itemsPerPage) {
        setLoading(false);
        return;
      }

      try {
        // Check if we have cached data for the current page
        let currentPageData;
        const hasCachedData = nextPageCache.current?.pageNumber === pageNumber;
        
        if (hasCachedData) {
          currentPageData = nextPageCache.current.data;
          nextPageCache.current = null;
        } else {
          // Only show loading state if we need to fetch
          setLoading(true);
          setError(null);
          currentPageData = await fetchPage(pageNumber, controller.signal);
        }

        // Only update state if this is still the most recent request
        if (requestId === currentRequestId.current) {
          const items = currentPageData?.items || [];
          const isLastPage = !currentPageData?.has_more && items.length < itemsPerPage;
          
          setCombinedItems(items);
          setHasMore(!isLastPage);
          setTotalCount(currentPageData?.total_count || 0);
          setError(null);

          // If we used cached data, we can set loading to false immediately
          if (hasCachedData) {
            setLoading(false);
          }

          // Preload next page if there might be more data
          if (!isLastPage) {
            try {
              const nextPageData = await fetchPage(pageNumber + 1, controller.signal);
              // Only cache if this is still the most recent request
              if (requestId === currentRequestId.current) {
                nextPageCache.current = {
                  pageNumber: pageNumber + 1,
                  data: nextPageData
                };
              }
            } catch (err) {
              // Silently handle preload errors
              console.warn('Error preloading next page:', err);
            } finally {
              // Set loading to false after the main request is complete
              if (!hasCachedData) {
                setLoading(false);
              }
            }
          } else if (!hasCachedData) {
            // If there's no next page to preload, set loading to false
            setLoading(false);
          }
        }
      } catch (err) {
        if (err.name === 'AbortError') {
          return;
        }
        console.error("Error fetching inbox:", err);
        if (requestId === currentRequestId.current) {
          setError(err);
          setCombinedItems([]);
          setHasMore(false);
          setTotalCount(0);
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      controller.abort();
    };
  }, [
    accountID,
    userID,
    role,
    getAccessToken,
    pageNumber,
    itemsPerPage,
    sortOrder,
    searchQuery,
    sortConfig,
    status
  ]);

  return { combinedItems, loading, error, hasMore, totalCount };
}

export default useFetchInbox;
