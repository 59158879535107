import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { Edit2 } from 'lucide-react';
import { FaUser, FaCalendar, FaTools } from 'react-icons/fa';
import BaseTag from '../Shared/BaseTag';
import { OverviewTag } from '../Shared/Tags';

const AgentCard = ({ agent, onEdit }) => {
  const cardBg = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const textColor = useColorModeValue('gray.600', 'gray.300');
  const headingColor = useColorModeValue('gray.700', 'white');

  return (
    <Box
      bg={cardBg}
      p={6}
      borderRadius="md"
      boxShadow="sm"
      borderWidth="1px"
      borderColor={borderColor}
      _hover={{
        borderColor: "gray.300",
        boxShadow: "md",
      }}
      transition="all 0.2s"
      height="100%"
      display="flex"
      flexDirection="column"
      position="relative"
    >
      <VStack spacing={4} align="stretch" flex={1}>
        {/* Title Bar */}
        <Flex justify="space-between" align="center">
          <Flex align="center" gap={2}>
            <Text fontSize="lg" fontWeight="semibold" color={headingColor}>
              {agent.title}
            </Text>
          </Flex>
        </Flex>

        {/* Overview Tags */}
        <Flex gap={3} flexWrap="wrap">
          <OverviewTag
            icon={FaTools}
            label="Services"
            value={agent.services.length}
            colorScheme="blue"
            tooltip={agent.services.join(', ')}
          />
          <OverviewTag
            icon={FaUser}
            label="Created By"
            value={agent.createdBy}
            colorScheme="purple"
          />
          <OverviewTag
            icon={FaCalendar}
            label="Usage"
            value={`${agent.usageCount} times`}
            colorScheme="green"
          />
        </Flex>

        {/* Services, Triggers, Actions */}
        <VStack spacing={4} align="stretch">
          {/* Services */}
          <Box>
            <Text fontSize="sm" fontWeight="500" color={textColor} mb={2}>
              Services
            </Text>
            <HStack spacing={2} flexWrap="wrap">
              {agent.services.map((service) => (
                <BaseTag
                  key={service}
                  label={service}
                  colorScheme="blue"
                />
              ))}
            </HStack>
          </Box>

          {/* Triggers */}
          <Box>
            <Text fontSize="sm" fontWeight="500" color={textColor} mb={2}>
              Triggers
            </Text>
            <HStack spacing={2} flexWrap="wrap">
              {agent.triggers.map((trigger) => (
                <BaseTag
                  key={trigger}
                  label={trigger}
                  colorScheme="purple"
                />
              ))}
            </HStack>
          </Box>

          {/* Actions */}
          <Box>
            <Text fontSize="sm" fontWeight="500" color={textColor} mb={2}>
              Actions
            </Text>
            <HStack spacing={2} flexWrap="wrap">
              {agent.actions.map((action) => (
                <BaseTag
                  key={action}
                  label={action}
                  colorScheme="green"
                />
              ))}
            </HStack>
          </Box>
        </VStack>

        {/* Edit Button */}
        <ButtonGroup size="sm" width="full" mt="auto" pt={4}>
          <Button
            leftIcon={<Edit2 size={16} />}
            variant="outline"
            onClick={onEdit}
            width="full"
            colorScheme="gray"
            fontWeight="500"
          >
            Edit Agent
          </Button>
        </ButtonGroup>
      </VStack>
    </Box>
  );
};

AgentCard.propTypes = {
  agent: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    services: PropTypes.arrayOf(PropTypes.string).isRequired,
    triggers: PropTypes.arrayOf(PropTypes.string).isRequired,
    actions: PropTypes.arrayOf(PropTypes.string).isRequired,
    createdBy: PropTypes.string.isRequired,
    usageCount: PropTypes.number.isRequired,
    isAI: PropTypes.bool,
    isActive: PropTypes.bool,
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default AgentCard; 